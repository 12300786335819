import Dexie from 'dexie'


export default function(db) {

	// Ajouter systématiquement la table _sync_temp aux transactions
	db._createTransaction = Dexie.override(db._createTransaction, function(originalCreate) {
		return function(mode, storeNames, dbSchema, parent) {

			if(dbSchema._sync_temp && storeNames.indexOf('_sync_temp') === -1) {
				storeNames.push('_sync_temp')
			}

			return originalCreate.apply(this, [mode, storeNames, dbSchema, parent])
		}
	})
}
