export default function(db) {
	db.Collection.prototype.invalid = async function() {
		const primaryKeys = await this.primaryKeys()

		const table = this._ctx.table
		const keys = Object.keys(table.schema.idxByName)
		const validKeyName = keys.find(k => k.endsWith('_valide'))
		const primKeyName = table.schema.primKey.name

		const items = []
		primaryKeys.forEach((id) => {
			let item = {}
			item[primKeyName] = id
			item[validKeyName] = 0
			items.push(item)
		})

		if (items.length > 0) {
			await table.bulkPut(items)
		}
	}
}
