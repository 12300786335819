import Vue from 'vue'
import Constants from 'Constants'
import Store from '@/store'

import RootConfigMixin from 'GroomyRoot/mixins/Config.js'
import UserConfigTransformer from '@/assets/js/dexie/transformers/UserConfigTransformer.js'
import GroomyConfig from '@/assets/js/dexie/GroomyConfig'

export default Vue.util.mergeOptions(RootConfigMixin, {
    methods: {
        loadConfig: async function() {
            if (!window.navigator.onLine) {
                return true
            }

            try {
                // On continue d'envoyer la langue pour la rétrocompatibilité mais à enlever à la prochaine publi'
                const url = Constants.GENERAL_CONFIG+"?lang=" + Store.state.user.lang + "&licence_key="+Constants.USER_LICENCE_KEY + "&device_id=" + this.$sync.deviceId()
                const general_config = await this.$request.request_get_api("ConfigMixin::loadConfig", url)

                await this.setConfig('table_config', general_config.retour.table_config)
                await this.setConfig('action_filter', general_config.retour.action_filter)
                await this.setConfig('tabs_config', general_config.retour.tabs_config)

                await this.setConfig('payment_method', general_config.retour.payment_method)
                await this.setConfig('payment_bank', general_config.retour.payment_bank)
                await this.setConfig('check_deposit_status', general_config.retour.check_deposit_status)
                await this.setConfig('user_access', general_config.retour.user_access)
                await this.setConfig('valid_mail', general_config.retour.valid_mail)

                await this.setConfig('export_config', general_config.retour.export_config)
                await this.setConfig('debug_level', general_config.retour.debug_level)
                await this.setConfig('need_reset', general_config.retour.need_reset)
                await this.setConfig('need_export', general_config.retour.need_export)
                await this.setConfig('lang', general_config.retour.lang || 'fr')

                await this.setConfig('licence_validity_date', general_config.retour.licence_validity_date)
                await this.setConfig('rs_config', general_config.retour.rs_config)
                await this.setConfig('p_config', general_config.retour.p_config)

                await this.setConfig('contact_logged', general_config.retour.contact_logged)

                await this.setConfig('api_config', general_config.retour.api_config)
                await this.setConfig('cards', general_config.retour.cards)
            }
            catch(error) {
                console.error("ConfigMixin::loadConfig => An error occured:", error)
                return false
            }
            return true
        },

        loadHorsetype: async function(type) {
            if (type != "race" && type != "robe" && type != "sexe")
                return null

            let result = null
            await this.axios.get(Constants.CONFIG_HORSE + type + "?lang=" + Store.state.user.lang).then(response => (result = response))

            if (result.status == 200 && result.data.code_retour === 0) {
                await this.setConfig(type, result.data.retour)
            }
        },

        loadTableConfig: async function() {
            let table_config = null
            await this.axios.get(Constants.USER_TABLE_CONFIG+"?licence_key="+Constants.USER_LICENCE_KEY).then(response => (table_config = response))

            if(table_config)
                await this.setConfig("table_config", table_config.data.retour)
            else
                await this.setConfig("table_config", null)
        },

        /* Permet de reload une catégorie précises pour tous les types d'actes (groupe, type et type ref) */
        reloadActesType: async function() {
			return this.$sync.force(true)
        },

        loadExportConfig: async function() {
            let exportPerso = null
            let url = this.constructRoute(Constants.EXPORT_PERSO_GET, {licence_key: ""}) + "?licence_key="+Constants.USER_LICENCE_KEY
            await this.axios.get(url).then(response => (exportPerso = response))
            if(exportPerso)
				await this.setConfig("export_config", exportPerso.data.retour)
            else
                await this.setConfig("export_config", null)
            return true;
		},

        getConfig: function(type, defaultVal=null) {
            let c = localStorage.getItem(type)
            if (c && c !== undefined) {
                return JSON.parse(c)
            }
            return defaultVal
        },

		getAsyncConfig: async function(type, defaultVal=null) {
			const licence_key = this.getConfig('licence_key')
			const user_id	  = this.getConfig('user_id')

			if(!user_id || !licence_key || !this.$storage.db) return null

			return this.$storage.db.t('user_config')
            .then(table => {
                return table.where({
                    userconfig_user: user_id,
                    userconfig_licencekey: licence_key
                })
            })
            .then(col => {
                return col.transform(new UserConfigTransformer())
			})
			.then(res => {
				if(!res || res.length === 0) return defaultVal 
				return res[0][type] || defaultVal
			})
		},

        setConfig: async function(type, value, need_autowipe=true) {
            if(type) {
                localStorage.setItem(type, JSON.stringify(value))
				await GroomyConfig.setItem(type, value)
			}
			
			if(need_autowipe) {
				// Ajouter le type à la liste des configurations utilisateur
				const configList = this.getConfig('config_list', ['token', 'licence_key', 'user_id', 'valid_user'])
				if (configList.indexOf(type) === -1) {
					configList.push(type)
					await this.setConfig('config_list', configList)
				}
			}
        },

        rmConfig: async function() {
			const configList = this.getConfig('config_list', [])
			for (let i = 0; i < configList.length; i++) {
				const type = configList[i]
				localStorage.removeItem(type)
				await GroomyConfig.removeItem(type)
			}
        },

		getLocales: function() {
			return [{
				title: this.$t('global.lang_fr'),
				code: 'fr'
			}, {
				title: this.$t('global.lang_en'),
				code: 'en'
			}, {
                title: this.$t('global.lang_es'),
                code: 'es'
            }]
		},

        /* CONFIG DB */
        async reloadDbConfig() {
			const tableToRefresh = [
                'horse_race',
                'horse_robe',
				'horse_sexe',
            ]

            // Récupération et insertion des données
            await this.$sync.runRequiredWhishlist(tableToRefresh, 1)
            // Suppression des anciennes config
            await this.$storage.db.clearConfigTables(tableToRefresh)
        },

        async addTabConfig(type, tabs) {
            const url = this.constructRoute(Constants.TABS_CONFIG_URL, { type }) + "?licence_key="+Constants.USER_LICENCE_KEY

            return this.$request.request_post_api("ContractMixin::addTabConfig", url, { tabs }, false)
                .then(res => res)
                .catch(e => {
                    console.error("ContractMixin::addTabConfig => ERROR", e)
                    return null
                })
        },

        getConfigIfce() {
			const config = this.getConfig('api_config')
            const ifce = config.find(conf => conf.apiconfig_type == "IFCE")
            if(ifce) {
                if(parseInt(ifce.apiconfig_key)) {
                    return false
                }
            }

            return true
        },

        getConfigWeatherbys() {
            const config = this.getConfig('api_config')
            const ifce = config.find(conf => conf.apiconfig_type == "WTHBS")
            if(ifce) {
                if(parseInt(ifce.apiconfig_key)) {
                    return false
                }
            }

            return true
        }
	}
})