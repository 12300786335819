import Transformer from './Transformer'

/**
 * Retourne une liste de
 * @param {Dexie.Collection} season
 */
export default class VatTransformer extends Transformer {

    async transform (vat) {
        return {
            'vat_id'                    : vat.vat_id,
            'vat_label'                 : vat.vat_label,
            'vat_value'                 : vat.vat_value
        }
    }
}
