
const state = {
	licence		: 0,
	licence_key	: '',
	licence_username: '',
	permissions	: '',
	entity_limit:  1,
	entity_supplier_limit: 1,
	lang		: 'fr',
}

const mutations = {
	setPermissions(state, permissions) {
		state.permissions = permissions
	},

	setEntityLimit(state, entity_limit) {
		state.entity_limit = entity_limit
	},

	setEntitySupplierLimit(state, entity_supplier_limit) {
		state.entity_supplier_limit = entity_supplier_limit
	},

    setLang(state, lang) {
        state.lang = lang
    }
}

export default {
    namespaced: true,
    state,
    mutations
}
