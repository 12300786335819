import Transformer from './Transformer'
import LieuTransformer from './LieuTransformer'

/**
 * Retourne une liste de
 * @param {Dexie.Collection} horse_residence
 */
export default class HorseResidenceTransformer extends Transformer {

	table = 'horse_residence'

    transform(residence) {
        return {
            'horseresidence_id'   : residence.horseresidence_id,
            'horseresidence_horse': residence.horseresidence_horse,
            'horseresidence_lieu' : residence.horseresidence_lieu
        }
	}


	fetchWithLieu(col) {
		return col.with({
			'lieu': 'horseresidence_lieu'
		})
	}

	async transformWithLieu(residence) {
        return {
            'horseresidence_id'   : residence.horseresidence_id,
            'horseresidence_horse': residence.horseresidence_horse,
			'horseresidence_lieu' : residence.horseresidence_lieu,
			'lieu' : await LieuTransformer.process(residence.lieu)
        }
	}

	
}