import Constants from '../../config.local.js'


var FileMixin = {
	methods: {
		/**
		 * Convert a base64 string in a Blob according to the data and contentType.
		 *
		 * @param b64Data {String} Pure base64 string without contentType
		 * @param contentType {String} the content type of the file i.e (image/jpeg - image/png - text/plain)
		 * @param sliceSize {Int} SliceSize to process the byteCharacters
		 * @see http://stackoverflow.com/questions/16245767/creating-a-blob-from-a-base64-string-in-javascript
		 * @return Blob
		 */
		b64toBlob: function(b64Data, contentType, sliceSize) {
		        contentType = contentType || '';
		        sliceSize = sliceSize || 512;

		        var byteCharacters = atob(b64Data);
		        var byteArrays = [];

		        for (var offset = 0; offset < byteCharacters.length; offset += sliceSize) {
		            var slice = byteCharacters.slice(offset, offset + sliceSize);

		            var byteNumbers = new Array(slice.length);
		            for (let i = 0; i < slice.length; i++) {
		                byteNumbers[i] = slice.charCodeAt(i);
		            }

		            var byteArray = new Uint8Array(byteNumbers);

		            byteArrays.push(byteArray);
		        }

		      var blob = new Blob(byteArrays, {type: contentType});
		      return blob;
		},

		fileToBase64 (file) {
			return new Promise((resolve, reject) => {
				const reader = new FileReader()
				reader.readAsDataURL(file)
				reader.onload = () => resolve(reader.result)
				reader.onerror = error => reject(error)
			})
		},

		uploadFile (file, parentid, parenttype) {
		    let formData = new FormData()
		    formData.append('licence_key', Constants.USER_LICENCE_KEY)
		    formData.append('file', file)
		    formData.append('parentid', parentid)
		    formData.append('parenttype', parenttype)

		    return this.$request.request_post_api("FilesMixin::uploadFile", Constants.UPLOAD_FILES_URL, formData, false)
		},

		/* Destinées aux médias un peu plus spécifiques */
		uploadMediaFile(file, parentid, parenttype) {
			let formData = new FormData()
		    formData.append('licence_key', Constants.USER_LICENCE_KEY)
		    formData.append('file', file)
		    formData.append('parentid', parentid)
		    formData.append('parenttype', parenttype)

		    return this.$request.request_post_api("FilesMixin::uploadMediaFile", Constants.UPLOAD_FILES_MEDIA_URL, formData, false)
		},

		uploadEmbeddMedia(embedd_url, parentid, parenttype) {
			let formData = new FormData()
		    formData.append('licence_key', Constants.USER_LICENCE_KEY)
		    formData.append('embedded_url', embedd_url)
		    formData.append('parentid', parentid)
		    formData.append('parenttype', parenttype)

		    return this.$request.request_post_api("FilesMixin::uploadEmbeddMedia", Constants.UPLOAD_FILES_EMBEDD_URL, formData, false)
		}
	}
}

export default FileMixin
