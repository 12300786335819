// Ajout du Polyfill de l'IndexedDB pour Safari
// et les anciennes webview Android
import 'indexeddbshim/dist/indexeddbshim.min.js'


window.groomyPwa = {
    prompt: null,
    updateState: 'CHECKING' // CHECKING, NO_UPDATE, UPDATE_FOUND, UPDATE_READY
}
window.addEventListener('beforeinstallprompt', (evt) => {
    evt.preventDefault()
    window.groomyPwa.prompt = evt
})
