import Vue from 'vue'

export default class ValidationError extends Error {
	i18nCode = 'error.UKE'
	i18nMessage = 'Unknown error'

	constructor(code="UKE", args=[], ...params) {
		const i18nCode = `error.${code}`

		super(i18nCode, ...params)

		this.i18nCode = i18nCode
		this.i18nMessage = Vue.i18n.translate(this.i18nCode, args)
	}
}
