import Transformer from './Transformer'
import Vue from 'vue'

export default class CategorieTransformer extends Transformer {

	table = 'horse_categorie'

	transform (categorie) {
		return {
			categorie_id		: categorie.categorie_id,
			categorie_libelle	: categorie.categorie_libelle
		}
	}

	transformWithLang(categorie) {
		const lang = Vue.i18n.locale()

		return {
			categorie_id		: categorie.categorie_id,
			categorie_libelle	: lang == 'en' ? categorie.categorie_libelle_en : categorie.categorie_libelle
		}
	}
}