/* eslint-disable */
import Vue from 'vue'
import App from './App.vue'
import router from "./router"

/* PLUGINS */
import BootstrapVue from 'bootstrap-vue'
// import { BModal, BVConfigPlugin } from 'bootstrap-vue'
import AsyncComputed from 'vue-async-computed'
import axios from 'axios'
import VueAxios from 'vue-axios'
import VueMask from 'di-vue-mask'
import VueMeta from 'vue-meta'
import VueLazyLoad from 'vue-lazyload'

/* PLUGINS MAISON */
import Common from './assets/js/common.js'
import i18n, { getNavigatorLang, loadI18nPlugin } from 'GroomyRoot/assets/js/i18n.js'
import Constants from 'Constants'
import Request from './assets/js/requests.js'
import VuexStore from './store'
import Storage from './plugins/Storage'
import Sync from './plugins/Sync'
import Firebase from './plugins/Firebase'
import NativeComm from './plugins/NativeComm'
import './assets/js/offline.js'
import 'GroomyRoot/assets/js/rollbar.js'
import './assets/js/theme.js'

/* MIXINS */
import Cache from "@/mixins/Cache.js";
import Config from "@/mixins/Config.js";
import Permissions from "@/mixins/Permissions.js";
import Toasts from "@/mixins/Toasts.js";
import Tools from "@/mixins/Tools.js";

/* DIRECTIVES */
import OnlineOnly from '@/directives/OnlineOnly'

/* COMPOSANTS */
import BackButton from 'GroomyRoot/components/Buttons/BackButton'
import ECopyBox from 'GroomyRoot/components/Utils/ECopyBox'
import EDatepicker from 'GroomyRoot/components/Utils/EDatepicker'
import EEditor from 'GroomyRoot/components/Utils/EEditor'
import EEditorFroala from 'GroomyRoot/components/Utils/EEditorFroala'
import EFancyBox from 'GroomyRoot/components/Utils/EFancybox'
import ESelect from 'GroomyRoot/components/Utils/ESelect'
import GroomyLogo from 'GroomyRoot/components/Logos/LogoMenu_111'
import ResponsiveComponent from 'GroomyRoot/components/Utils/ResponsiveComponent'
import ShutterPanel from 'GroomyRoot/components/Utils/ShutterPanel'

/* ICONS */
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import 'GroomyRoot/assets/js/icons.js'

// import 'GroomyRoot/assets/scss/app.scss'
import 'GroomyRoot/registerServiceWorker.js'

/* Démarrage de la webapp */
var common = null
var connectivity = true

const init = async () => {
    Vue.use(BootstrapVue)
    // Vue.use(BootstrapVue, {
	// 	BModal: {noCloseOnBackdrop : true}})
    Vue.use(VueAxios, axios)
    Vue.use(AsyncComputed)
    Vue.use(VueMask)
    Vue.use(VueMeta)
    Vue.use(VueLazyLoad)
    Vue.use(Storage)
    Vue.use(Sync, { store: VuexStore })
    Vue.use(Firebase)
    Vue.use(NativeComm)

	// Vue.use(BVConfigPlugin, {BModal: {'noCloseOnBackdrop' : true}})

	Vue.mixin(Cache)
	Vue.mixin(Config)
	Vue.mixin(Permissions)
	Vue.mixin(Toasts)
	Vue.mixin(Tools)

	Vue.directive('e-online-only', OnlineOnly)

	Vue.component(FontAwesomeIcon.name, FontAwesomeIcon)
	Vue.component('back-button',  BackButton)
	Vue.component('e-fancybox',   EFancyBox)
	Vue.component('e-copybox',   ECopyBox)
	Vue.component('e-datepicker', EDatepicker)
	Vue.component('e-select', ESelect)
	Vue.component('responsive-component', ResponsiveComponent)
	Vue.component('shutter-panel', ShutterPanel)
	Vue.component('e-editor', EEditor)
	Vue.component('e-editor-froala', EEditorFroala)
	Vue.component('groomy-logo', GroomyLogo)
	// Vue.component('b-modal', BModal)

	common = new Common()
	initApp()
}

function initApp() {
	// On set des données accessibles via l'interface de vue
	Vue.prototype.$common = common
	Vue.prototype.$connectivity = connectivity
	Vue.prototype.$request = new Request()
	Vue.prototype.$request.set_headers()
	Vue.prototype.$cache = {}
	Vue.prototype.$connectivity = false

	initAppConfigHeader()

	// I18N
	let configLang = Config.methods.getConfig("lang")
	if (!configLang) {
		configLang = getNavigatorLang()
	}
	loadI18nPlugin(configLang)

	// On a toutes les données de base
	// On peut init notre objet vue
	// Dans le scope de vuejs (sur une vue, ou un composant par ex), on aura ces données
	new Vue({
		el: '#app',
		router,
		i18n,
		store: VuexStore,
		connectivity: false,
		cache: {}, // objet visant à garder de côté des données pouvant être mises en cache (style données statiques comme horse_race)
		common: common,
		render: h => h(App),
		data: {
			active: false,
			currentX: 0,
			currentY: 0,
			initialX: 0,
			initialY: 0,
			xOffset: 0,
			yOffset: 0,
			dragItem: null
		},
		methods: {
			initDragModal: function() {
				// this.$root.initDragModal() à l'ouverture d'une modale pour la rendre graggable
				this.active = false
				this.currentX = 0
				this.currentY = 0
				this.initialX = 0
				this.initialY = 0
				this.xOffset = 0
				this.yOffset = 0
				this.dragItem = null
		    var container = window;

		    container.addEventListener("touchstart", this.dragStart, false);
		    container.addEventListener("touchend", this.dragEnd, false);
		    container.addEventListener("touchmove", this.drag, false);

		    container.addEventListener("mousedown", this.dragStart, false);
		    container.addEventListener("mouseup", this.dragEnd, false);
		    container.addEventListener("mousemove", this.drag, false);

				let that = this
				Vue.nextTick(function () {
		      that.dragItem = document.querySelector(".modal-content");
				  that.active = false;
				  that.xOffset = 0;
				  that.yOffset = 0;
				})
			},

	    setTranslate: function(xPos, yPos, el) {
	    	el.style.transform = "translate3d(" + xPos + "px, " + yPos + "px, 0)";
	    },

			dragStart: function(e) {
				if (e.type === "touchstart") {
					this.initialX = e.touches[0].clientX - this.xOffset;
					this.initialY = e.touches[0].clientY - this.yOffset;
				} else {
					this.initialX = e.clientX - this.xOffset;
					this.initialY = e.clientY - this.yOffset;
				}

				if (e.target.classList.contains('modal-header') || e.target.classList.contains('modal-title') ) {
					this.active = true;
				}
		   },

		   dragEnd: function(e) {
				this.initialX = this.currentX;
				this.initialY = this.currentY;

				this.active = false;
		   },

		   drag: function(e) {
				if (this.active) {
					e.preventDefault();
					if (e.type === "touchmove") {
						this.currentX = e.touches[0].clientX - this.initialX;
						this.currentY = e.touches[0].clientY - this.initialY;
					} else {
						this.currentX = e.clientX - this.initialX;
						this.currentY = e.clientY - this.initialY;
					}
					this.xOffset = this.currentX;
					this.yOffset = this.currentY;
					this.setTranslate(this.currentX, this.currentY, this.dragItem);
				}
		   }
		}
	})
}

function initAppConfigHeader() {
    if(Config.methods.getConfig("licence_key") && Config.methods.getConfig("token")){
		Constants.USER_LICENCE_KEY = Config.methods.getConfig("licence_key")
		Vue.prototype.$request.set_headers(Config.methods.getConfig("token"))
		Vue.prototype.$connectivity = true
	}
}

/* Extensions */
Vue.filter('uppercase', function (value) {
  if (!value) return ''
  return value.toString().toUpperCase()
})

Vue.filter('capitalize', function (value) {
  if (!value) return ''
  value = value.toString()
  return value.charAt(0).toUpperCase() + value.slice(1)
})

init()