import { MEDIA_STATES } from '@/assets/js/sync/states'
import GroomyDB from '@/assets/js/dexie/GroomyDB'
import '@/assets/js/dexie/addons'
import GroomyConfig from '@/assets/js/dexie/GroomyConfig'
import OfflineUtils from '@/assets/js/utils/offline'
import CacheManager from '@/assets/js/cache/CacheManager'

export default {
    install (Vue) {
        const StoragePlugin = {
            /**
             * GroomyDB instance
             */
            db: null,

            /*********************
             * NAVIGATOR.STORAGE *
             *********************/
            /**
             * Regarde si la permission de stockage a déjà été accordée
             */
            async isStoragePersisted() {
                return await navigator.storage && navigator.storage.persisted &&
                    navigator.storage.persisted()
            },
            /**
             * Ouvre la demande de permissions de stockage pour ne pas que les données soient supprimées
             */
            async persist() {
                return await navigator.storage && navigator.storage.persist &&
                navigator.storage.persist()
            },
            /**
             * Récupérer le stockage restant disponible
             * @return {Object} { quota: Number, usage: Number}
             */
            async getEstimatedQuota() {
                if (navigator.storage && navigator.storage.estimate) {
                    return await navigator.storage.estimate()
                }

                return null
			},

            /**
             * Initialize la base de données Groomy
             */
            async init() {
                try {
                    this.db = await GroomyDB.getInstance(true)
					await this.cleanMedias()
				}
                finally {
                    if (this.db && await this.db.needReset()) {
                        await this.removeDatabase()
                        await Vue.prototype.$sync.ack('need_reset')
                        window.location.reload()
                    }
                }
            },

            async dbExists() {
                return GroomyDB.exists(`GROOMY-${await GroomyConfig.getItem('licence_key')}-${process.env.VUE_APP_VERSION}`)
            },

            /**
             * Supprime la base de données locale
             */
            async removeDatabase(vm) {
				// Envoyer les données avant de supprimer la DB
                try {
					const table = await this.db.t('_sync_temp')
					const hasData = await table.count()
					const isOnline = window.navigator.onLine
					let message

					// Si on est hors ligne on prévient qu'il ne pourra pas se reco
					if(!isOnline && hasData <= 0) {
						message = Vue.i18n.translate('toast.logout_message_offline')
					}
					// Si il y a des data en attente de synchro (_sync_temp)
					else if(hasData > 0) {
						// Si on peut pas sync parce qu'il est hors ligne
						if (!isOnline) {
							message = Vue.i18n.translate('toast.logout_message_sync_offline')
						}
						else {
							try {
								await Vue.prototype.$sync.runUp()
							}
							// Si il y a eu une erreur de synchro on prévient
							catch (err) {
								console.error(err)
								message = Vue.i18n.translate('toast.logout_message_sync_error')
							}
						}
					}

					if (message && vm) {
						const modalAccepted = await vm.$bvModal.msgBoxConfirm(message,{
							noCloseOnBackdrop: true,
							noCloseOnEsc: true,
							centered: true,
							okTitle: Vue.i18n.translate('global.oui'),
							cancelTitle: Vue.i18n.translate('global.non'),
						})

						if(!modalAccepted) {
							return
						}
					}
					const db = this.db

					await this.unloadDatabase()


					Vue.prototype.$sync.setLastSync('required', 0)
					Vue.prototype.$sync.setLastSync('optionnal', 0)

					// Il faut s'assurer que le delete marche et n'est pas bloqué
					await db.delete()
					await db.setLocalSchemas({})
					await db.setDbVersion(0)

					Vue.prototype.$sync.setSyncFromScratch([])
				}
				catch {
					console.error('Impossible de vérifier le contenu de sync_temp')
				}
			},

			async databaseToJson(tablesToExport) {
				let json = {}
				const tables = await this.db.tables
				for(let i = 0; i < tables.length; i++) {
					const table = tables[i]
					if (tablesToExport.includes(table.name)) {
						json[table.name] = await table.toArray()
					}
				}
				return json
			},

			async cleanMedias() {
				return this.db.t('_files')
				.then(table => {
					const now = new Date()
					const expiryDate = now.setMonth(now.getMonth() - 2)

					return table
					.filter(media => {
						// On vérifie si last_used et state sont là pour la migration des médias déjà téléchargés
						return (
							(!media.last_used || media.last_used < expiryDate)
							&& (!media.state || media.state === MEDIA_STATES.DOWNLOADED)
						)
					})
				})
				.then(col => {
					return col.delete()
				})
			},

            /**
             * Reset la base de données locale
             */
            async unloadDatabase() {
				await CacheManager.inst().reset()

				OfflineUtils.resetCachedWorkers()

				await Vue.prototype.$sync.reset()

                if (this.db && await this.db.isOpen()) {
                    await this.db.close()
				}

				GroomyDB.INSTANCE = null
                this.db = null
            },
        }

        Vue.prototype.$storage = StoragePlugin
    }
}
