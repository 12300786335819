import CacheCleaner from "./CacheCleaner"
import HorseCleaner from "./HorseCleaner"

export default class HorseResidenceCleaner extends CacheCleaner {

	async onMutation(residence_ids) {
		await this.init()

		// Vider le cache de tous les chevaux avec ces résidences
		return this.db().t('horse_residence')
		.then(table => {
			return table.where(':id').anyOf(residence_ids).toArray()
		})
		.then(residences => {
			return Array.from(new Set(residences.map(r => r.horseresidence_horse)))
		})
		.then(horse_ids => {
			return this.onMutationHorse(horse_ids)
		})
	}

	// Horse est après parce qu'on a bien update une résidence
	// mais on donne des horse_ids en paramètre
	async onMutationHorse(horse_ids) {
		await HorseCleaner.inst().onMutation(horse_ids, ['horse_residence_label'])
	}

	// Event appelé lorsque le lieu de la résidence est modifié
	async onLieuMutation(lieu_ids) {
		await this.init()

		const residences = await this.db().t('horse_residence')
		.then(table => {
			return table
				.where('horseresidence_lieu')
				.anyOf(lieu_ids)
				.toArray()
		})

		const horse_ids = residences.map((r) => (r.horseresidence_horse))

		// Récup tous les horse_residence avec ces lieux et invalider le cache des chevaux
		await HorseCleaner.inst().onMutation(horse_ids, ['horse_residence_label'])
	}
}
