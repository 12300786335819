import Vue from 'vue'
import Transformer from './Transformer'
import HorseTransformer from './HorseTransformer'
import IntraLocationTransformer from './IntraLocationTransformer'
import LieuTypeTransformer from './LieuTypeTransformer'
import ContactTransformer from './ContactTransformer'

import _orderBy from 'lodash/orderBy'

/**
 * Retourne une liste de
 * @param {Dexie.Collection} horse_mouvement
 */
export class MouvementTransformer extends Transformer {

	table = 'horse_mouvement'
	transactionTables = ['horse', 'horse_pedigree', 'lieu_type']

	async horse(item) {
		const horse = await this.fetchRelationship(item, 'mouvement_horse')
		return HorseTransformer.process(horse, 'withPedigree')
	}

	async contact(item) {
		const contact = await this.fetchRelationship(item, 'mouvement_contact')
		return ContactTransformer.process(contact)
	}

	getTrad(key) {
		return Vue.i18n.translate(key)
	}

	getTypeTrad(label) {
		return Vue.i18n.translate('types.lieu_type.'+label)
	}

	type_trad(item) {
		return item.mouvement_type == 1 ? this.getTypeTrad('entree') :
			item.mouvement_type == 5 ? this.getTypeTrad('intra_location') :
			this.getTypeTrad('sortie')
	}

	addGenericColumns(mouvement) {
		if(mouvement.mouvement_date != undefined && mouvement.mouvement_date != "0000-00-00") {
			const date = new Date(mouvement.mouvement_date)
			mouvement.isActive = false
			mouvement.mouvement_date = date
			mouvement.mouvement_date_year = date.getFullYear()
			mouvement.mouvement_date_month_without_zero = date.getMonth()+1
			mouvement.mouvement_date_month = ('0' + (date.getMonth()+1)).slice(-2)
			mouvement.mouvement_date_day = ('0' + date.getDate()).slice(-2)
			mouvement.mouvement_date_month_label = this.getTrad("mois."+mouvement.mouvement_date_month_without_zero)
			mouvement.mouvement_date_month_label_court = this.getTrad("mois_court."+mouvement.mouvement_date_month_without_zero)
		}
		else {
			mouvement.mouvement_date = ''
			mouvement.mouvement_date_year = 0
			mouvement.mouvement_date_month = 0
			mouvement.mouvement_date_day = 0
		}
		mouvement.isActive = false

		if(mouvement.mouvement_id) mouvement.id = mouvement.mouvement_id
		else mouvement.id = mouvement.lieu_id

		if(mouvement.mouvement_type == 1) mouvement.class = "entree"
		else if(mouvement.mouvement_type == 5) mouvement.class = "intra_location"
		else mouvement.class = "sortie"

		return mouvement
	}

	async stringified(mouvement) {
		const stringified = await super.stringified(mouvement)
		const horse = await this.fetchRelationship(mouvement, 'mouvement_horse')

		return `${stringified}|${Object.values(horse).join('|')}`
	}

	fetch(col) {
		this.additionalColumns({
			'type_trad': this.type_trad
		})

		return col
	}

    transform (mouvement) {
        const temp = {
            'mouvement_id'     : mouvement.mouvement_id,
            'mouvement_id_absolute': Math.abs(mouvement.mouvement_id),
            'mouvement_horse'  : mouvement.mouvement_horse,
            'mouvement_type'   : mouvement.mouvement_type,
			'mouvement_type_label': mouvement.type_trad,
            'mouvement_label'  : mouvement.mouvement_label,
            'mouvement_adresse': mouvement.mouvement_adresse,
            'mouvement_cp'   : mouvement.mouvement_cp,
            'mouvement_ville': mouvement.mouvement_ville,
            'mouvement_date' : mouvement.mouvement_date,
            'mouvement_raison': mouvement.mouvement_raison,
			'mouvement_lieu'  : mouvement.mouvement_lieu,
			'mouvement_intra_location': mouvement.mouvement_intra_location,
			'mouvement_contact': mouvement.mouvement_contact,
			'mouvement_tiers': mouvement.mouvement_tiers
		}
		
		return this.addGenericColumns(temp)
	}

	transformLite(mouvement) {
		return {
			'mouvement_id'     : mouvement.mouvement_id,
            'mouvement_id_absolute': Math.abs(mouvement.mouvement_id),
            'mouvement_horse'  : mouvement.mouvement_horse,
            'mouvement_type'   : mouvement.mouvement_type,
			'mouvement_type_label': mouvement.type_trad,
            'mouvement_label'  : mouvement.mouvement_label,
            'mouvement_adresse': mouvement.mouvement_adresse,
            'mouvement_cp'   : mouvement.mouvement_cp,
            'mouvement_ville': mouvement.mouvement_ville,
            'mouvement_date' : mouvement.mouvement_date,
            'mouvement_raison': mouvement.mouvement_raison,
			'mouvement_lieu'  : mouvement.mouvement_lieu,
			'mouvement_intra_location': mouvement.mouvement_intra_location
		}
	}

	sortLite(mouvements) {
		return _orderBy(mouvements, ['mouvement_date', 'mouvement_id_absolute'], ['desc', 'desc'])
	}

	fetchWithHorseAndType(col) {
		this.additionalColumns({
			'horse': this.horse,
			'contact': this.contact,
			'type_trad': this.type_trad
		})

		return col.with({
			'lieu_type' : 'mouvement_type'
        })
	}

	async transformWithHorseAndType(mouvement) {
		// Si pas de pedigree, on donne une valeur par défaut
		const pedigree = (mouvement.horse && mouvement.horse.horse_pedigree) ? mouvement.horse.horse_pedigree : {
			'pere': '',
			'mere': ''
		}

        const temp = {
            'mouvement_id'     : mouvement.mouvement_id,
            'mouvement_id_absolute': Math.abs(mouvement.mouvement_id),
            'mouvement_horse'  : mouvement.mouvement_horse,
            'mouvement_type'   : mouvement.mouvement_type,
			'mouvement_type_label': mouvement.type_trad,
            'mouvement_label'  : mouvement.mouvement_label,
            'mouvement_adresse': mouvement.mouvement_adresse,
            'mouvement_cp'   : mouvement.mouvement_cp,
            'mouvement_ville': mouvement.mouvement_ville,
            'mouvement_date' : mouvement.mouvement_date,
            'mouvement_raison': mouvement.mouvement_raison,
			'mouvement_lieu'  : mouvement.mouvement_lieu,
			'lieutype' : await LieuTypeTransformer.process(mouvement.lieu_type),
			'horse'    : await HorseTransformer.process(mouvement.horse, 'heavy'),
			'pedigree' : {
                'pere' : pedigree.pere,
                'mere' : pedigree.mere
            },
            'contact' : mouvement.contact ? mouvement.contact.contact_civility + " " + mouvement.contact.contact_firstname + " " + mouvement.contact.contact_lastname : ''
		}

		return this.addGenericColumns(temp)
	}

	sortWithHorseAndType(mouvements) {
		return _orderBy(mouvements, ['mouvement_date', 'mouvement_id_absolute'], ['desc', 'desc'])
	}

	fetchWithIntraLocation(col) {
		return col.with({
			'mouvement_intra_location' : 'mouvement_intra_location'
        })
	}

	async transformWithIntraLocation(mouvement) {
		const intra_location = await IntraLocationTransformer.process(mouvement.mouvement_intra_location, 'withType')

		return {
			'mouvement_id'     : mouvement.mouvement_id,
            'mouvement_id_absolute': Math.abs(mouvement.mouvement_id),
            'mouvement_horse'  : mouvement.mouvement_horse,
            'mouvement_type'   : mouvement.mouvement_type,
            'mouvement_label'  : mouvement.mouvement_label,
            'mouvement_adresse': mouvement.mouvement_adresse,
            'mouvement_cp'   : mouvement.mouvement_cp,
            'mouvement_ville': mouvement.mouvement_ville,
            'mouvement_date' : mouvement.mouvement_date,
            'mouvement_raison': mouvement.mouvement_raison,
			'mouvement_intra_location': intra_location,
		}
	}

	sortWithIntraLocation(mouvements) {
		return _orderBy(mouvements, ['mouvement_date', 'mouvement_id_absolute'], ['desc', 'desc'])
	}
}

export default MouvementTransformer
