<style src="GroomyRoot/assets/scss/editorFroala.css"></style>
<style src="GroomyRoot/assets/scss/froalaFont.css"></style>

<template>
    <div>
        <froala :tag="'textarea'" :config="config" v-model="modelContent"></froala>
    </div>
</template>

<script>
import Vue from 'vue'
import Common from '@/assets/js/common.js'
import _cloneDeep from 'lodash/cloneDeep'
import _uniq from 'lodash/uniq'

/* Froala */
import 'froala-editor/js/froala_editor.pkgd.min.js'
import 'froala-editor/js/plugins.pkgd.min.js'
import 'froala-editor/js/languages/fr.js'
import 'froala-editor/css/froala_editor.pkgd.min.css'

import VueFroala from 'vue-froala-wysiwyg'
import { DefineIcon, RegisterCommand, DefineIconTemplate } from 'froala-editor'
Vue.use(VueFroala)

/* Tribute (mention) */
import Tribute from 'tributejs'
import 'tributejs/dist/tribute.css'

export default {
    name:'e-editor-froala',
    data () {
        return {
            propsContent : '',
            modelContent: '',
            variablesGlobal: [],
            variablesGlobalDefault: [],
            blocksGlobal: [],
            blocksGlobalDefault: [],
            inputs: [],
            inputsDefault: [
                { code: 'CB', name: this.getTrad('editor.inputs.roundbox'), html: '<input class="" id="" type="checkbox" name="">' },
                { code: 'RD', name: this.getTrad('editor.inputs.squarebox'), html: '<input class="" id="" type="radio" name="">' },
                { code: 'TT', name: this.getTrad('editor.inputs.small_text'), html: '<input class="" id="" type="text" name="" value="">' },
                { code: 'TA', name: this.getTrad('editor.inputs.big_text'), html: '<textarea class="" id="" type="text" name=""></textarea>' },
                // { code: 'NB', name: 'Champ nombre', html: '<input class="" id="" type="number" name="" value="">' },
                // { code: 'RG', name: 'Champ range', html: '<input class="" id="" type="range" name="" value="">' },
                // { code: 'CO', name: 'Champ couleur', html: '<input class="" id="" type="color" name="" value="">' },
            ]
        }
    },
	model: {
		event: 'change'
	},
    props: {
        placeholder: {
			type: String,
			default: () => ('')
		},
		content: {
			type: String,
			default: () => ('')
		},
        identifier: {
			type: [String, Number],
			default: () => ('')
		},
        variables: {
            type: Array,
            default: () => []
        },
        blocks: {
            type: Array,
            default: () => []
        },
		disabled: {
			type: Boolean,
			default: () => ( false )
		},
        moreText: {
            type: Array,
            default: () => []
        }
    },
    created () {
        /* Content */
        this.propsContent = _cloneDeep(this.content)
        this.modelContent = _cloneDeep(this.content)
        
        /* Variables */
        this.variablesGlobal = _cloneDeep(this.variables)
        this.variablesGlobalDefault = _cloneDeep(this.variables)

        /* Blocks */
        this.blocksGlobal = _cloneDeep(this.blocks)
        this.blocksGlobalDefault = _cloneDeep(this.blocks)
    },
    methods: {
        setValue (val) {
            this.propsContent = _cloneDeep(val)
            this.modelContent = _cloneDeep(val)
        },
        addBlockOnText (froala, key) {
            const block = _cloneDeep(this.blocksGlobal)[key]
            const id = 'block_'+Common.getRandomInt(1000000000)
            const html = '<div id="'+id+'" class="tiptap-block" data-block-key="'+key+'" data-block-name="'+block.id+'" data-block-title="'+block.name+'"><p></p></div> <div><p></p></div>'
            froala.html.insert(html)
            froala.selection.setAtEnd(document.querySelector('#'+id+' p'))
            froala.selection.restore()
        },
        refreshBlockOnMenuBar (froala, nomMenu, dropdown) {
            let blocks = _cloneDeep(this.blocksGlobalDefault)
            const keys = this.getParentsBlock(froala)
            keys.forEach(key => {
                const childrens = _cloneDeep(this.blocksGlobal)[key].children
                childrens.forEach(children => {
                    blocks.push(children)
                })
            })

            const html = this.menuBarToHtml(nomMenu, blocks)
            this.addHtmlOnMenuBar(dropdown, html)
            this.blocksGlobal = blocks
        },
        menuBarToHtml (nomMenu, datas) {
            let html = '<ul class="fr-dropdown-list" role="presentation">'
            Object.keys(datas).map(function(k, i) {
                const data = datas[k]
                html += '<li role="presentation"><a class="fr-command" tabindex="-1" role="option" data-cmd="'+nomMenu+'" data-param1="'+k+'" title="'+data.name+'" aria-selected="false">'+data.name+'</a></li>'
            })

            html += '</ul>'
            return html
        },
        addHtmlOnMenuBar(dropdown, html) {
            const list = dropdown.find('ul.fr-dropdown-list')
            list.replaceWith(html)
        },
        getVarsBlock(froala) {
            this.variablesGlobal.splice(0, this.variablesGlobal.length)

            this.variablesGlobalDefault.forEach(v => {
                this.variablesGlobal.push(v)
            })

            const keys = this.getParentsBlock(froala)
            keys.forEach(key => {
                const variables = _cloneDeep(this.blocksGlobal)[key].variables
                variables.forEach(variable => {
                    this.variablesGlobal.push(variable)
                })
            })
        },
        getParentsBlock(froala) {
            let keys = []
            let el = froala.selection.get().focusNode.parentNode
            let i = true

            const bg = _cloneDeep(this.blocksGlobal)

            while (i) {
                if(el.nodeName == '#document') {
                    keys = []
                    i = false
                    break
                }

                if(el.classList.contains('fr-element')) {
                    i = false
                    break
                }

                const data = el.getAttribute('data-block-name')
                if(data) {
                    const k = bg.findIndex((v) => { 
                        return v.id == data
                    })

                    if(k >= 0) {
                        keys.push(k)
                    }
                }

                el = el.parentNode
            }

            keys = _uniq(keys.reverse())
            return keys
        },
        updateParentContent(val, width = 0) {
			this.$emit('change', { key: this.identifier, val: val, width: width, type: 'EEditor' })
		},
        addInputOnText (froala, key) {
            const input = _cloneDeep(this.inputs)[key]
            const separateur = ' '
            const name = 'input_'+Common.getRandomInt(1000000000)
            const html = input.html.replace('name=""', 'name="'+name+'"').replace('id=""', 'id="'+name+'"')+separateur
            froala.html.insert(html)
        },
        refreshInputOnMenuBar (froala, nomMenu, dropdown) {
            let input = _cloneDeep(this.inputsDefault)
            const html = this.menuBarToHtml(nomMenu, input)
            this.addHtmlOnMenuBar(dropdown, html)
            this.inputs = input
        },
        loadBlock() {
            if(this.modelContent) {
                //eslint-disable-next-line
                let blocks = this.modelContent.match(/data-block-name=\"[a-z_]*/g)||[]
                blocks = blocks.map(block => block.replace('data-block-name="', ''))
                let a = this.searchBlock(this.blocksGlobal, blocks, [])
                this.blocksGlobal = a
            }
        },
        searchBlock(blocksGlobal, blocks, a) {
            for(let i in blocksGlobal) {
                if(blocksGlobal[i].children.length > 0) {
                    let b = this.searchBlock(blocksGlobal[i].children, blocks, a)
                    a.concat(b)
                }
                if(blocks.includes(blocksGlobal[i].id)) {
                    a.push(blocksGlobal[i])
                }
            }
            return a
        }
    },
    watch: {
        modelContent(val) {
            this.loadBlock()
        }
    },
    computed: {
        config() {
            const vuejs = this
            DefineIcon('clear', { NAME: 'remove', SVG_KEY: 'remove' })
            RegisterCommand('clear', {
                title: this.getTrad('editor.tooltips.clear_html'),
                focus: false,
                undo: true,
                refreshAfterCallback: true,
                callback: function () {
                    this.html.set('')
                    this.events.focus()
                }
            })

            DefineIconTemplate('bracketsCurlyIconTemplate', '<svg aria-hidden="true" focusable="false" data-prefix="far" data-icon="brackets-curly" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512" class="svg-inline--fa fa-brackets-curly fa-w-18"><path fill="currentColor" d="M208 32h-88a56 56 0 0 0-56 56v77.49a40 40 0 0 1-11.72 28.29L7 239a24 24 0 0 0 0 34l45.24 45.24A40 40 0 0 1 64 346.52V424a56 56 0 0 0 56 56h88a16 16 0 0 0 16-16v-16a16 16 0 0 0-16-16h-88a8 8 0 0 1-8-8v-77.48a88.06 88.06 0 0 0-25.78-62.24L57.93 256l28.29-28.28A88.06 88.06 0 0 0 112 165.48V88a8 8 0 0 1 8-8h88a16 16 0 0 0 16-16V48a16 16 0 0 0-16-16zm361 207l-45.25-45.24A40.07 40.07 0 0 1 512 165.48V88a56 56 0 0 0-56-56h-88a16 16 0 0 0-16 16v16a16 16 0 0 0 16 16h88a8 8 0 0 1 8 8v77.48a88 88 0 0 0 25.78 62.24L518.06 256l-28.28 28.28A88 88 0 0 0 464 346.52V424a8 8 0 0 1-8 8h-88a16 16 0 0 0-16 16v16a16 16 0 0 0 16 16h88a56 56 0 0 0 56-56v-77.49a40 40 0 0 1 11.72-28.29L569 273a24 24 0 0 0 0-34z" class=""></path></svg>')
            DefineIcon('bracketsCurlyIcon', {NAME: 'far-brackets-curly', template: 'bracketsCurlyIconTemplate'});
            RegisterCommand('variable', {
                title: this.getTrad('editor.tooltips.variable'),
                icon: 'bracketsCurlyIcon',
                focus: false,
                undo: true,
                refreshAfterCallback: true,
                callback: function () {
                    this.html.insert('@')
                }
            })

            DefineIconTemplate('infinityIconTemplate', '<svg aria-hidden="true" focusable="false" data-prefix="far" data-icon="infinity" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 512" class="svg-inline--fa fa-infinity fa-w-20 fa-lg"><path fill="currentColor" d="M484.4 96C407 96 349.3 164.1 320 208.5 290.7 164.1 233 96 155.6 96 69.8 96 0 167.8 0 256s69.8 160 155.6 160c77.5 0 135.1-68.1 164.4-112.5C349.3 347.9 407 416 484.4 416c85.8 0 155.6-71.8 155.6-160S570.2 96 484.4 96zM155.6 368C96.2 368 48 317.8 48 256s48.2-112 107.6-112c67.8 0 120.5 82.3 137.2 112-16.8 29.7-69.4 112-137.2 112zm328.8 0c-67.8 0-120.5-82.3-137.2-112 16.8-29.7 69.4-112 137.2-112 59.3 0 107.6 50.2 107.6 112s-48.2 112-107.6 112z" class=""></path></svg>')
            DefineIcon('infinityIcon', {NAME: 'far-infinity', template: 'infinityIconTemplate'});
            RegisterCommand('block', {
                title: this.getTrad('editor.tooltips.blocks'),
                icon: 'infinityIcon',
                type: 'dropdown',
                focus: false,
                undo: false,
                refreshAfterCallback: true,
                options: vuejs.blocksGlobal,
                callback: function (cmd, val) {
                    vuejs.addBlockOnText(this, val)
                },
                refreshOnShow: function (btn, dropdown) {
                    vuejs.refreshBlockOnMenuBar(this, 'block', dropdown)
                }
            })

            DefineIconTemplate('inputIconTemplate', '<svg aria-hidden="true" focusable="false" data-prefix="far" data-icon="check-square" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512" class="fa-1x svg-inline--fa fa-check-square fa-w-14"><path fill="currentColor" d="M400 32H48C21.49 32 0 53.49 0 80v352c0 26.51 21.49 48 48 48h352c26.51 0 48-21.49 48-48V80c0-26.51-21.49-48-48-48zm0 400H48V80h352v352zm-35.864-241.724L191.547 361.48c-4.705 4.667-12.303 4.637-16.97-.068l-90.781-91.516c-4.667-4.705-4.637-12.303.069-16.971l22.719-22.536c4.705-4.667 12.303-4.637 16.97.069l59.792 60.277 141.352-140.216c4.705-4.667 12.303-4.637 16.97.068l22.536 22.718c4.667 4.706 4.637 12.304-.068 16.971z" class=""></path></svg>')
            DefineIcon('inputIcon', {NAME: 'far-square-check', template: 'inputIconTemplate'});
            RegisterCommand('input', {
                title: this.getTrad('editor.tooltips.case'),
                icon: 'inputIcon',
                type: 'dropdown',
                focus: true,
                undo: true,
                refreshAfterCallback: true,
                options: vuejs.inputsDefault,
                callback: function (cmd, val) {
                    vuejs.addInputOnText(this, val)
                },
                refreshOnShow: function (btn, dropdown) {
                    vuejs.refreshInputOnMenuBar(this, 'input', dropdown)
                }
            })

            DefineIconTemplate('showPdfVersionIconeTemplate', '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512"><!--! Font Awesome Pro 6.1.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. --><path d="M160 256C160 185.3 217.3 128 288 128C358.7 128 416 185.3 416 256C416 326.7 358.7 384 288 384C217.3 384 160 326.7 160 256zM288 336C332.2 336 368 300.2 368 256C368 211.8 332.2 176 288 176C287.3 176 286.7 176 285.1 176C287.3 181.1 288 186.5 288 192C288 227.3 259.3 256 224 256C218.5 256 213.1 255.3 208 253.1C208 254.7 208 255.3 208 255.1C208 300.2 243.8 336 288 336L288 336zM95.42 112.6C142.5 68.84 207.2 32 288 32C368.8 32 433.5 68.84 480.6 112.6C527.4 156 558.7 207.1 573.5 243.7C576.8 251.6 576.8 260.4 573.5 268.3C558.7 304 527.4 355.1 480.6 399.4C433.5 443.2 368.8 480 288 480C207.2 480 142.5 443.2 95.42 399.4C48.62 355.1 17.34 304 2.461 268.3C-.8205 260.4-.8205 251.6 2.461 243.7C17.34 207.1 48.62 156 95.42 112.6V112.6zM288 80C222.8 80 169.2 109.6 128.1 147.7C89.6 183.5 63.02 225.1 49.44 256C63.02 286 89.6 328.5 128.1 364.3C169.2 402.4 222.8 432 288 432C353.2 432 406.8 402.4 447.9 364.3C486.4 328.5 512.1 286 526.6 256C512.1 225.1 486.4 183.5 447.9 147.7C406.8 109.6 353.2 80 288 80V80z"/></svg>')
            DefineIcon('showPdfVersionIcone', {NAME: 'far-eye', template: 'showPdfVersionIconeTemplate'});
            RegisterCommand('showPdfVersion', {
                title: this.getTrad('editor.tooltips.variable'),
                icon: 'showPdfVersionIcone',
                refreshAfterCallback: true,
                callback: function (cmd, val) {
                    const className = 'fr-box-resize'
                    const el = this.$box[0].classList
                    if (el.contains(className)) {
                        el.remove(className)
                    } else {
                        el.add(className)
                    }
                }
            })

            let allMoreText = [ 'bold', 'italic', 'strikeThrough', 'underline', 'paragraphFormat', 'formatUL', 'formatOL', 'insertHR', 'undo', 'redo', 'insertImage', 'variable', 'block', 'fontSize', 'textColor', 'alignLeft', 'alignCenter', 'alignRight', 'alignJustify', 'insertTable', 'input', 'html', 'showPdfVersion', 'clearFormatting' ]
            let moreText = _cloneDeep(allMoreText)
            if(vuejs.moreText.length > 0) {
                moreText = _cloneDeep(vuejs.moreText)

                for(let i = 0; i < vuejs.moreText.length; i++) {
                    const index = allMoreText.indexOf(vuejs.moreText[i])
                    if(index > -1) {
                        allMoreText.splice(index, 1)
                    }
                }
            } else {
                allMoreText = []
            }

            let allMoreRich = [ 'subscript', 'superscript', 'fontFamily', 'backgroundColor', 'inlineClass', 'inlineStyle', 'formatOLSimple', 'paragraphStyle', 'lineHeight', 'outdent', 'indent', 'quote', 'markdown', 'insertLink', 'insertFiles', 'insertVideo', 'emoticons', /*'fontAwesome',*/ 'specialCharacters', /*'embedly',*/ 'insertFile' ]
            if(allMoreText.length > 0) {
                allMoreRich = allMoreText.concat(allMoreRich)
            }

            let moreRich = _cloneDeep(allMoreRich)

            return {
                key: 'vYA6mA5C5C4F4C3B10A6eMRPYf1h1REb1BGQOQIc2CDBREJImA11C8D6D5B1F4C3F2H3C9==',
                language: this.$i18n.locale(),
                tableStyles: {
                    'borderBlack': 'Bordure (noire)',
                    'borderWhite': 'Bordure (blanche)',
                    'alternateRrows': 'Lignes alternatives'
                },
                tableCellStyles: {
                    'borderBlack': 'Bordure (noire)',
                    'borderWhite': 'Bordure (blanche)',
                },
                fontFamily: {
                    'Arial,Helvetica,sans-serif': 'Arial',
                    'Georgia,serif': 'Georgia',
                    'Impact,Charcoal,sans-serif': 'Impact',
                    'Tahoma,Geneva,sans-serif': 'Tahoma',
                    'Times New Roman\',Times,serif': 'Times New Roman',
                    'Verdana,Geneva,sans-serif': 'Verdana',
                    'Roboto': 'Roboto',
                    'lato, sans-serif': 'Lato',
                    'urw-din-semi-condensed': 'Urwdin Semi Condensed'
                },
                htmlAllowedTags: ['a', 'abbr', 'address', 'area', 'article', 'aside', 'audio', 'b', 'base', 'bdi', 'bdo', 'blockquote', 'br', 'button', 'canvas', 'caption', 'cite', 'code', 'col', 'colgroup', 'datalist', 'dd', 'del', 'details', 'dfn', 'dialog', 'div', 'dl', 'dt', 'em', 'embed', 'fieldset', 'figcaption', 'figure', 'footer', 'form', 'h1', 'h2', 'h3', 'h4', 'h5', 'h6', 'header', 'hgroup', 'hr', 'i', 'iframe', 'img', 'input', 'ins', 'kbd', 'keygen', 'label', 'legend', 'li', 'link', 'main', 'map', 'mark', 'menu', 'menuitem', 'meter', 'nav', 'object', 'ol', 'optgroup', 'option', 'output', 'p', 'param', 'pre', 'progress', 'queue', 'rp', 'rt', 'ruby', 's', 'samp', 'style', 'section', 'select', 'small', 'source', 'span', 'strike', 'strong', 'sub', 'summary', 'sup', 'table', 'tbody', 'td', 'textarea', 'tfoot', 'th', 'thead', 'time', 'title', 'tr', 'track', 'u', 'ul', 'var', 'video', 'wbr', 'fmention'],
                htmlRemoveTags: [ 'script' ],
                fontSize: ['7', '8', '9', '10', '11', '12', '13', '14', '15', '16', '17', '18', '20', '22', '24', '26', '28', '30', '36', '48', '60', '72', '96'],
                lineHeights: {
                    Default: '',
                    '0.25': '0.25',
                    '0.5': '0.5',
                    '0.75': '0.75',
                    '1': '1',
                    '1.25': '1.25',
                    '1.5': '1.5',
                    '1.75': '1.75',
                    '2': '2',
                    '2.5': '2.5',
                    '3': '3'
                },
                toolbarButtons: {
                    moreText: {
                        buttons: moreText,
                        buttonsVisible: 9999999999
                    },
                    moreParagraph: { buttons: [], buttonsVisible: 1 },
                    moreRich: {
                        buttons: moreRich,
                        buttonsVisible: 0,
                    },
                    moreMisc: {
                        buttons: [ 'clear', 'fullscreen', 'print', /*'getPDF',*/ /*'spellChecker',*/ 'selectAll', 'help' ],
                        align: 'right',
                        buttonsVisible: 0
                    }
                },
                quickInsertEnabled: false,
                placeholderText: this.placeholder,
                imageDefaultAlign: 'left',
                events: {
                    initialized: function() {
                        let tribute = new Tribute({
                            values: vuejs.variablesGlobal,
                            lookup: 'name',
                            fillAttr: 'name',
                            noMatchTemplate: vuejs.getTrad('editor.no_suggestion_found'),
                            menuItemTemplate: function (item) {
                                return item.original.name
                            },
                            selectTemplate: function(item) {
                                return (
                                    '<fmention class="mention fr-deletable fr-tribute" data-mention-id="'+item.original.id +'">@'+item.original.name +"</fmention> "
                                )
                            }
                        })

                        tribute.attach(this.el)

                        this.events.on('keydown',
                            function(e) {
                                if (e.key === 'Enter' && tribute.isActive) {
                                    return false
                                }
                            },
                            true
                        )

                        this.events.on('charCounter.update', function (e) {
                                let width = 0
                                vuejs.updateParentContent(this.html.get(), width)
                            },
                            true
                        )

                        const froala = this
                        this.el.addEventListener('tribute-active-true', function(e) {
                            vuejs.getVarsBlock(froala)
                        })

                        if(vuejs.disabled) {
                            this.edit.off()
                            this.toolbar.disable()
                        }
                    },
                    'codeView.update': function() {
                        const str = this.html.get()
                        let str_temp = str
                        let m
                        let l
                        let k
                        let regex

                        // Ajoute devant tous les selecteurs dans les balises <style> un '.fr-element'
                        // Ajoute un '!important' a chaque propriété CSS (sauf ceux qui n'ont pas de ';')
                        regex = /<style>([^<]*?)<\/style>/g
                        while ((m = regex.exec(str)) !== null) {
                            let p = m[0]

                            const regex1 = /(.*?){([^}]*)}/g
                            while ((l = regex1.exec(m[1])) !== null) {
                                let o = l[0]

                                const regex2 = /(\*|#|\.|[a-zA-Z])([^}]*?)($|,)/g
                                while ((k = regex2.exec(l[1])) !== null) {
                                    const regex3 = /.fr-element/g
                                    if(regex3.exec(k[0]) === null) {
                                        o = o.replace(k[0], ' .fr-element ' + k[0])
                                    }
                                }

                                p = p.replace(l[0], o)
                            }

                            str_temp = str_temp.replace(m[0], p.replaceAll(/(!important)*;/g, ' !important;'))
                        }

                        // Ajoute un 'width=100%' a tous les <table> qui n'ont pas de width
                        regex = /<table(.*?)>/g
                        while ((m = regex.exec(str)) !== null) {
                            const regex1 = /style="(.*[^max-])?([^max-]?)width(.*?)">/
                            if(regex1.exec(m[0]) === null) {
                                const regex2 = /style="(.*?)"/g
                                if(regex2.exec(m[0]) !== null) {
                                    str_temp = str_temp.replace(m[0], m[0].replace('style="', 'style="width:100%;'))
                                } else {
                                    str_temp = str_temp.replace(m[0], m[0].replace('>', ' style="width:100%;">'))
                                }
                            }
                        }

                        regex = /style="(.*?)"/g
                        while ((m = regex.exec(str)) !== null) {
                            let p = m[0]
                            str_temp = str_temp.replace(m[0], p.replaceAll(/(!important)*;/g, ' !important;'))
                        }

                        this.html.set(str_temp)
                    },
                    'image.beforeUpload': function(files) {
                        let editor = this
                        if (files.length) {
                            let reader = new FileReader()
                            reader.onload = function(e) {
                                let result = e.target.result
                                editor.image.insert(result, null, null, editor.image.get())
                            }

                            reader.readAsDataURL(files[0])
                        }

                        editor.popups.hideAll()
                        return false
                    },
                    'input': function(e) {
                        if(e.currentTarget) {
                            switch (e.currentTarget.nodeName) {
                                case 'INPUT':
                                    e.currentTarget.setAttribute('value', e.currentTarget.value)
                                    break;
                                case 'TEXTAREA':
                                    e.currentTarget.innerHTML = e.currentTarget.value
                                    break;
                                default:
                                    break;
                            }
                        }
                    },
                    'table.inserted' : function (e) {
                        e.classList.add('borderBlack')
                        e.querySelectorAll('td').forEach(function(td) {
                            td.classList.add('borderBlack')
                        })
                    }
                }
            }
        }
    }
}
</script>