import Vue from 'vue'
import Transformer from './Transformer'
import ArticleTransformer from './ArticleTransformer'

/**
 * Retourne une liste de
 * @param {Dexie.Collection} actes
 */
export default class ActeTypeTransformer extends Transformer {

	needAccessRights = true
	table = 'horse_actes_type'

	fetch (col) {
		return col.with({
            'active'        		: 'horse_actes_type_active',
        })
	}

	transform (actestype) {
		return {
			'actestype_id'              : actestype.actestype_id,
			'actestype_code'            : actestype.actestype_code,
			'actestype_codegroupe'      : actestype.actestype_codegroupe,
			'actestype_lang'            : actestype.actestype_lang,
			'actestype_label'           : actestype.actestype_licencekey ? actestype.actestype_label : Vue.i18n.translate('actes_type.' + actestype.actestype_code),
			'actestype_prefixe'         : actestype.actestype_prefixe,
			'actestype_mutable'         : actestype.actestype_mutable,
			'actestype_licencekey'		: actestype.actestype_licencekey,
			'actestype_needordonnance'	: actestype.actestype_needordonnance,
			'actestype_maxdelay'		: actestype.actestype_maxdelay,
            'active'              		: actestype.active[0] ? actestype.active[0].actestypeactive_active : true,
		}
	}

	sort (arr) {
		return arr.sortBy('actestype_label')
	}

	async resolveWithGroups(actestypes) {
		actestypes = await actestypes.toArray()

		const actestype_ids = actestypes.map(at => (at.actestype_id))

		const actives = await this.db().t('horse_actes_type_active')
		.then(table => {
			return table.where('actestypeactive_actestype').anyOf(actestype_ids).toArray()
		})

		const actesgroupe_codes = actestypes.map(at => (at.actestype_codegroupe))
		const groupes = await this.db().t('horse_actes_groupe')
		.then(table => {
			return table.where('actesgroupe_code').anyOf(actesgroupe_codes).toArray()
		})

		const tab_maxdelays = await this.db().t('horse_actes_type_maxdelay')
		.then(table => {
			return table.where('actestypemaxdelay_actestype').anyOf(actestype_ids).toArray()
		})
		
		return actestypes.map(actestype => {
			const acte_type_maxdelay = tab_maxdelays.find(ata => (ata.actestypemaxdelay_actestype === actestype.actestype_id))
			const active = actives.find(ata => (ata.actestypeactive_actestype === actestype.actestype_id))
			const groupe = groupes.find(ag => (ag.actesgroupe_code === actestype.actestype_codegroupe))

			return {
				'actestype_id'              : actestype.actestype_id,
				'actestype_code'            : actestype.actestype_code,
				'actestype_codegroupe'      : actestype.actestype_codegroupe,
				'actestype_lang'            : actestype.actestype_lang,
				'actestype_label'           : actestype.actestype_licencekey ? actestype.actestype_label : Vue.i18n.translate('actes_type.' + actestype.actestype_code),
				'actestype_prefixe'         : actestype.actestype_prefixe,
				'actestype_mutable'         : actestype.actestype_mutable,
				'actestype_licencekey'		: actestype.actestype_licencekey,
				'actestype_needordonnance'	: actestype.actestype_needordonnance,
				'actestype_groupe'          : groupe,
				'actestype_maxdelay'		: acte_type_maxdelay ? acte_type_maxdelay.actestypemaxdelay_delay : actestype.actestype_maxdelay,
				'actesgroupe_label'			: Vue.i18n.translate('actes_groupe.' + groupe.actesgroupe_code),
				'global'					: !actestype.actestype_licencekey,
				'active'              		: active ? active.actestypeactive_active : true,
			}
		})
	}

    fetchWithGroups (col) {
        return col.with({
            'actestype_groupe'      : 'actestype_codegroupe',
            'active'        		: 'horse_actes_type_active',
        })
    }

    transformWithGroups (actestype) {
        return {
			'actestype_id'              : actestype.actestype_id,
			'actestype_code'            : actestype.actestype_code,
			'actestype_codegroupe'      : actestype.actestype_codegroupe,
			'actestype_lang'            : actestype.actestype_lang,
			'actestype_label'           : actestype.actestype_licencekey ? actestype.actestype_label : Vue.i18n.translate('actes_type.' + actestype.actestype_code),
			'actestype_prefixe'         : actestype.actestype_prefixe,
			'actestype_mutable'         : actestype.actestype_mutable,
			'actestype_licencekey'		: actestype.actestype_licencekey,
			'actestype_needordonnance'	: actestype.actestype_needordonnance,
			'actestype_groupe'          : actestype.actestype_groupe,
			'actestype_maxdelay'		: actestype.actestype_maxdelay,
			'actesgroupe_label'			: Vue.i18n.translate('actes_groupe.' + actestype.actestype_groupe.actesgroupe_code),
			'global'					: !actestype.actestype_licencekey,
            'active'              		: actestype.active[0] ? actestype.active[0].actestypeactive_active : true,
        }
	}

	async resolveWithGroupsAndArticles(actestypes) {
		actestypes = await actestypes.toArray()

		const actestype_ids = actestypes.map(at => (at.actestype_id))

		const actives = await this.db().t('horse_actes_type_active')
		.then(table => {
			return table.where('actestypeactive_actestype').anyOf(actestype_ids).toArray()
		})

		const tab_articles = await this.db().t('horse_actes_type_articles')
		.then(table => {
			return table.where('actestypearticles_actestype').anyOf(actestype_ids).toArray()
		})

		const articleIds = tab_articles.map(i => i.actestypearticles_articles)

		const actesgroupe_codes = actestypes.map(at => (at.actestype_codegroupe))
		const groupes = await this.db().t('horse_actes_groupe')
		.then(table => {
			return table.where('actesgroupe_code').anyOf(actesgroupe_codes).toArray()
		})

		const all_articles = await this.db().t('articles')
		.then(table => {
			return table.where('articles_id').anyOf(articleIds)
			.and(article => (article.articles_inactive == false))
		})
		.then(col => {
			return col.transform(new ArticleTransformer('light'))
		})

		const tab_maxdelays = await this.db().t('horse_actes_type_maxdelay')
		.then(table => {
			return table.where('actestypemaxdelay_actestype').anyOf(actestype_ids).toArray()
		})

		return actestypes.map(actestype => {
			const active = actives.find(ata => (ata.actestypeactive_actestype === actestype.actestype_id))
			const acte_type_articles = tab_articles.filter(ata => (ata.actestypearticles_actestype === actestype.actestype_id))
			const ids = acte_type_articles.map(i => i.actestypearticles_articles)
			const articles = all_articles.filter(article => ids.indexOf(article.articles_id) > -1 )
			const acte_type_maxdelay = tab_maxdelays.find(ata => (ata.actestypemaxdelay_actestype === actestype.actestype_id))
			const groupe = groupes.find(ag => (ag.actesgroupe_code === actestype.actestype_codegroupe))

			return {
				'actestype_id'              : actestype.actestype_id,
				'actestype_code'            : actestype.actestype_code,
				'actestype_codegroupe'      : actestype.actestype_codegroupe,
				'actestype_lang'            : actestype.actestype_lang,
				'actestype_label'           : actestype.actestype_licencekey ? actestype.actestype_label : Vue.i18n.translate('actes_type.' + actestype.actestype_code),
				'actestype_prefixe'         : actestype.actestype_prefixe,
				'actestype_mutable'         : actestype.actestype_mutable,
				'actestype_licencekey'		: actestype.actestype_licencekey,
				'actestype_groupe'          : groupe,
				'actestype_maxdelay'		: acte_type_maxdelay ? acte_type_maxdelay.actestypemaxdelay_delay : actestype.actestype_maxdelay,
				'actesgroupe_label'			: Vue.i18n.translate('actes_groupe.' + groupe.actesgroupe_code),
				'global'					: !actestype.actestype_licencekey,
				'active'              		: active ? active.actestypeactive_active : true,
				'articles'              	: articles,
			}
		})
	}

	transformWithGroupsAndArticles (actestype) {
        return {

        }
	}

	async articles(actestype) {

		if (!this.hasRight('FACT')) {
			return []
		}
		
		// Récupération des articleIds de l'acte type
		let defaultArticle = null
		let triggeredArticle = null
		let quantifiables = []
		const articleIds = await this.db().t('horse_actes_type_articles')
		.then(table => {
			return table.where({
				actestypearticles_actestype: actestype.actestype_id
			})
		})
		.then(col => {
			return col.toArray()
		})
		.then(arr => {
			defaultArticle = arr.filter((i) => (i.actestypearticles_default))
			triggeredArticle = arr.find((i) => (i.actestypearticles_triggered === 1))
			quantifiables = arr.map((i) => {
				if(i.actestypearticles_quantifiable)
				{
					return i
				}
			})
			return arr.map(i => i.actestypearticles_articles)
		})

		// Récupération des articles
		return this.db().t('articles')
		.then(table => {
			return table.where('articles_id').anyOf(articleIds)
			.and(article => (article.articles_inactive == false))
		})
		.then(col => {
			return col.transform(new ArticleTransformer('withAccountingAccountLite'))
		})
		.then(articles => {
			articles.forEach(article => {
				const is_quantifiable = quantifiables.find((i) => (i != undefined && i.actestypearticles_articles == article.articles_id && i.actestypearticles_quantifiable))

				if(is_quantifiable == undefined)
				{
					article.is_quantifiable = 0
				}
				else
				{
					if(Object.keys(is_quantifiable).length > 0)
					{
						article.is_quantifiable = 1
					}
					else
					{
						article.is_quantifiable = 0
					}
				}
				
				article.is_default = false
				if(defaultArticle != null){
					const is_default = defaultArticle.find(elem => elem.actestypearticles_articles === article.articles_id)
					if(is_default){
						article.is_default = true
					}
				}
				article.is_triggered = triggeredArticle ? triggeredArticle.actestypearticles_articles === article.articles_id : false
			})
			return articles
		})
	}

	async first_act(actes_type) {
		let act = await this.db().t('horse_actes')
			.then(table => {
				return table.where({
					actes_actestype: parseInt(actes_type.actestype_id),
					actes_actesstatut: 0
				}).first()
			})

		return act ? act : null
	}

	async first_act_finished(actes_type) {
		let act = await this.db().t('horse_actes')
			.then(table => {
				return table.where({
					actes_actestype: parseInt(actes_type.actestype_id),
					actes_actesstatut: 1
				}).first()
			})

		return act ? act : null
	}

	fetchWithArticles (col) {
		this.additionalColumns({
			articles: this.articles,
		})

		return col.with({
			'active'        		: 'horse_actes_type_active',
		})
	}

	transformWithArticles (actestype) {
		return {
			'actestype_id'              : actestype.actestype_id,
			'actestype_code'            : actestype.actestype_code,
			'actestype_codegroupe'      : actestype.actestype_codegroupe,
			'actestype_lang'            : actestype.actestype_lang,
			'actestype_label'           : actestype.actestype_licencekey ? actestype.actestype_label : Vue.i18n.translate('actes_type.' + actestype.actestype_code),
			'actestype_prefixe'         : actestype.actestype_prefixe,
			'actestype_mutable'         : actestype.actestype_mutable,
			'actestype_licencekey'		: actestype.actestype_licencekey,
			'articles'					: actestype.articles,
            'active'              		: actestype.active[0] ? actestype.active[0].actestypeactive_active : true,
		}
	}
	
	fetchWithFirstAct(col) {

		this.additionalColumns({
			first_act: this.first_act
		})

		return col.toArray()
	}

	transformWithFirstAct (actestype) {
		return {
			'actestype_id'              : actestype.actestype_id,
			'actestype_code'            : actestype.actestype_code,
			'actestype_codegroupe'      : actestype.actestype_codegroupe,
			'actestype_label'           : actestype.actestype_licencekey ? actestype.actestype_label : Vue.i18n.translate('actes_type.' + actestype.actestype_code),
			'first_act'					: actestype.first_act
		}
	}

	fetchWithFirstActFinished(col) {
		this.additionalColumns({
			first_act_finished: this.first_act_finished
		})

		return col.toArray()
	}

	transformWithFirstActFinished (actestype) {
		return {
			'actestype_id'              : actestype.actestype_id,
			'actestype_code'            : actestype.actestype_code,
			'actestype_codegroupe'      : actestype.actestype_codegroupe,
			'actestype_label'           : actestype.actestype_licencekey ? actestype.actestype_label : Vue.i18n.translate('actes_type.' + actestype.actestype_code),
			'first_act_finished'		: actestype.first_act_finished
		}
	}


	async articles_light(actestype) {

		if (!this.hasRight('FACT')) {
			return []
		}
		
		let defaultArticle = null
		let triggeredArticle = null
		let quantifiables = []
		// Récupération des articleIds de l'acte type
		const articleIds = await this.db().t('horse_actes_type_articles')
		.then(table => {
			return table.where({
				actestypearticles_actestype: actestype.actestype_id
			})
		})
		.then(col => {
			return col.toArray()
		})
		.then(arr => {
			return arr.map(i => i.actestypearticles_articles)
		})

		// Récupération des articles
		return this.db().t('articles')
		.then(table => {
			return table.where('articles_id').anyOf(articleIds)
			.and(article => (article.articles_inactive == false))
		})
		.then(col => {
			return col.transform(new ArticleTransformer('light'))
		})
	}

	fetchWithArticlesLight (col) {
		this.additionalColumns({
			articles: this.articles,
		})

		return col.with({
			'active'        		: 'horse_actes_type_active',
		})
	}

	transformWithArticlesLight (actestype) {
		return {
			'actestype_id'              : actestype.actestype_id,
			'actestype_code'            : actestype.actestype_code,
			'actestype_codegroupe'      : actestype.actestype_codegroupe,
			'actestype_lang'            : actestype.actestype_lang,
			'actestype_label'           : actestype.actestype_licencekey ? actestype.actestype_label : Vue.i18n.translate('actes_type.' + actestype.actestype_code),
			'actestype_prefixe'         : actestype.actestype_prefixe,
			'actestype_mutable'         : actestype.actestype_mutable,
			'actestype_licencekey'		: actestype.actestype_licencekey,
			'articles'					: actestype.articles,
            'active'              		: actestype.active[0] ? actestype.active[0].actestypeactive_active : true,
		}
	}

	fetchWithActive (col) {
		return col.with({
			'active'        		: 'horse_actes_type_active',
		})
	}

	transformWithActive (actestype) {
		return {
			'actestype_id'              : actestype.actestype_id,
			'actestype_code'            : actestype.actestype_code,
			'actestype_label'           : actestype.actestype_licencekey ? actestype.actestype_label : Vue.i18n.translate('actes_type.' + actestype.actestype_code),
			'actestype_licencekey'		: actestype.actestype_licencekey,
            'active'              		: actestype.active[0] ? actestype.active[0].actestypeactive_active : true,
		}
	}
}
