import { EventBus } from 'EventBus'
import RootPermissionMixin from 'GroomyRoot/mixins/Permissions.js'

export default {
	mixins: [RootPermissionMixin],
	methods: {
		async refreshVueExPermission() {
			let temp = await this.getAsyncConfig('permissions')

			// Si compte déjà log avec un db, la synchro required n'est pas forcée au démarrage.
			if(!temp) {
				// Droits MVP en attendant que la synchro passe avec la table user_config.
				// Se produit si une personne ne s'est pas connectée
				// à Groomy entre Septembre 2020 et Décembre 2020
				temp = 'MVP-LIMIT5'
			}
			this.userPermissions = temp
			this.entityLimit = this.getUserEntityLimit()
			this.entitySupplierLimit = this.getUserEntitySupplierLimit()

			EventBus.$emit('User::AccessRightsReloaded')
			this.initMenuRights()
		},

		// Sur groomy web, les droits sont en bdd locale
		fetchAuthorizedHorses() {
			// Dirty trick pour éviter une erreur au moment de la synchro
			// avant déconnexion ou switch de licence
			if (!this.$storage.db) {
				return []
			}

			const horse_limit = this.getUserHorseLimit()

			return this.$storage.db.t('horse')
                .then(table => {
                    return table.orderBy('horse_id')
                })
                .then(col => {
					return col.limit(horse_limit)
						.primaryKeys()
				})
		},

		getUserAuthorizedHorses() {
			return this.$storage.db.t('horse')
			.then(table => {
				const limit = this.getUserHorseLimit()
				return table.limit(limit).primaryKeys()
			})
		},

		getUserEntityLimit() {
			const matches = this.userPermissions.match(/ENTITY[0-9]+/g)
			if(!matches || matches.length === 0) return 1

			const limit_code = matches[0]
			const limit_num  = limit_code.substr('ENTITY'.length)
			return parseInt(limit_num)
		},

		getUserEntitySupplierLimit() {
			const matches = this.userPermissions.match(/ENTITYSUPPL[0-9]+/g)
			if(!matches || matches.length === 0) return 1

			const limit_code = matches[0]
			const limit_num  = limit_code.substr('ENTITYSUPPL'.length)
			return parseInt(limit_num)
		},
	},
	computed: {
		entityLimit: {
			get() {
				return this.$store.state.user.entity_limit
			},
			set(val) {
				this.$store.commit('user/setEntityLimit', val)
			}
		},
		entitySupplierLimit: {
			get() {
				return this.$store.state.user.entity_supplier_limit
			},
			set(val) {
				this.$store.commit('user/setEntitySupplierLimit', val)
			}
		}
	}
}
