import Transformer from './Transformer'


export default class SexeTransformer extends Transformer {

	table = 'horse_sexe'

    async transform (sexe) {
        return {
			sexe_code: sexe.sexe_code,
			sexe_label: sexe.sexe_label,
        }
    }
}
