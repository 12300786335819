import Constants from 'Constants'
import { EventBus } from 'EventBus'
import Common from '../assets/js/common.js'
import _deepClone from 'lodash/cloneDeep'

let callbacks = {}
let defaultHandler = 'callbackHandler'
let defaultParams = {}

export default {
    install(Vue) {
        const NativeCommPlugin = {
            init() {
				// On exécute le handler
                if(Constants.IS_IOS_RUNNING) {
                    this.triggerSwiftBootEvents()
				}
				else if(Constants.IS_AND_RUNNING) {
					this.triggerAndroidBootEvents()
				}
            },

            generateCallbakId() {
                return '_'+Common.getRandomInt(999999)+'_swiftNativeComm'
            },
        
            registerNativeRequest(callback) {
                const callbackId = this.generateCallbakId()
                callbacks[callbackId] = callback
                return callbackId
            },
        
            unregisterNativeRequest(callbackId) {
                delete callbacks[callbackId]
            },
        
            fetchNative(callback, params=null, handler=null, androidDifferredResult=false) {
				// Si on est sur android, les retours sont synchrones
				if(Constants.IS_AND_RUNNING) {
					// eslint-disable-next-line
					const andInterface = Android

					if(!androidDifferredResult) {
						if(!params) return callback(andInterface[handler]())
						return callback(andInterface[handler](params))
					}

					const handlerName = handler || defaultHandler
					const handlerParams = _deepClone(params || defaultParams)
			
					// On enregistre la callback sous un id, pour pouvoir la resolve au retour de swift
					const callbackId = this.registerNativeRequest(callback)
					let andParams = {
						handlerParams,
						resultParams: {},
						jsCallbackId: callbackId
					}

					const jsonParams = JSON.stringify(andParams)
					return andInterface[handler](jsonParams)
				}

                // Pour IOS, on doit créer notre système de callback - On évalue les paramètres soumis
                const handlerName = handler || defaultHandler
                let handlerParams = _deepClone(params || defaultParams)
        
                // On enregistre la callback sous un id, pour pouvoir la resolve au retour de swift
                const callbackId = this.registerNativeRequest(callback)
                handlerParams.jsCallbackId = callbackId
        
                // En fonction de la target
				const target = Common.getActualPlatformEvo()
				console.log("target", target)

				// On exécute le handler
				if(target.includes('wkwebview')) {
                    window.webkit.messageHandlers[handlerName].postMessage(handlerParams)
				}
            },
        
            /* Native callbacks */
            backFromSwiftNative(result) {
                const callbackId = result.jsCallbackId
                console.log("backFromSwiftNative", callbackId, callbacks, method)
                
                const method = callbacks[callbackId]
                this.unregisterNativeRequest(callbackId)
        
                return method(result.data)
            },

            backFromAndroidNative(result) {
				// Si c'est un event à dispatch dans vue
				if(result.jsCallbackId == 'event') {
					EventBus.$emit("AndroidNative::SystemEvent", result.resultParams)
					return
				}

				const callbackId = result.jsCallbackId
                const method = callbacks[callbackId]
				this.unregisterNativeRequest(callbackId)
				
                return method(result.resultParams)
            },

            /* Necessary event results for app boot */
            triggerSwiftBootEvents() {
                // To get FCM Token from swift firebase SDK
                const callbackIdFcmToken = this.registerNativeRequest((token) => {
                    Vue.prototype.$firebase.messaging.onTokenChange(token)
                })

                window.webkit.messageHandlers.getFirebaseToken.postMessage({
                    jsCallbackId: callbackIdFcmToken
				})
				
				// On récupére le code de package, et on construit le numéro
				const callbackIdPackageCode = this.registerNativeRequest((res) => {
					Constants.APP_BUILD_NUMBER = res.split('.').join('0')
                })

                window.webkit.messageHandlers.getAppBundleVersion.postMessage({
                    jsCallbackId: callbackIdPackageCode
                })
            },

            triggerAndroidBootEvents() {
				// eslint-disable-next-line
				const andInterface = Android
				const token = andInterface.getFcmToken()
				Vue.prototype.$firebase.messaging.onTokenChange(token)

				// On récupére le code de package, et on construit le numéro
				const package_version = andInterface.getAppVersion()
				Constants.APP_BUILD_NUMBER = package_version.split('.').join('0')

				// Récupération des droits accordés
				const hasWriteAccess = andInterface.checkForStoragePermission
				if(hasWriteAccess == "authorized") Vue.$store.commit('mobile/setWriteAccess')

				const hasCameraAccess = andInterface.checkForCameraPermission
				if(hasCameraAccess == "authorized") Vue.$store.commit('mobile/setCameraAccess')
            },

			/* 
				Events triggered by native code - triggers unfired by JS 
			*/
			handleSwiftEvent(nativeParams) {
				const eventName = nativeParams.jsEvent
				const params = JSON.parse(nativeParams.eventParams)
				console.log("NativComm::handleSwiftEvent => dispatching event for:", eventName, params)
				EventBus.$emit(eventName, params)
            },

            handleAndroidEvent(data) {

			}
        }

        Vue.prototype.$nativeComm = NativeCommPlugin
        window.nativeComm = NativeCommPlugin
    }
}