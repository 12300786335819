const state = {
	/**
	 * Droit d'écriture fichier
	 */
	hasWriteAccess: false,
	
	/**
	 * Droit d'accès caméra
	 */
	hasCameraAccess: false
}

const mutations = {
    setWriteAccess(state) {
        state.hasWriteAccess = true
    },
    setCameraAccess(state) {
        state.hasCameraAccess = true
    }
}

export default {
    namespaced: true,
    state,
    mutations
}
