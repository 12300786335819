import Transformer from './Transformer'
import AccountingPlanTransformer from './AccountingPlanTransformer'

/**
 * Retourne une liste de
 * @param {Dexie.Collection} season
 */
export default class AccountingAccountTransformer extends Transformer {

    table = 'accounting_account'

	async fetch (col) {
	    return col.with({
	        'accounting_plan'                     : 'accountingaccount_accountingplan'
	    })
	}

    async transform (accounting_account) {
        return {
            'accountingaccount_id'				: accounting_account.accountingaccount_id,
            'accountingaccount_valide'			: accounting_account.accountingaccount_valide,
            'accountingaccount_accountingplan'	: accounting_account.accountingaccount_accountingplan,
            'accountingaccount_vat'				: accounting_account.accountingaccount_vat,
            'accountingaccount_label'			: accounting_account.accountingaccount_label,
            'accountingaccount_number'			: accounting_account.accountingaccount_number,
            'accounting_plan'					: await AccountingPlanTransformer.process(accounting_account.accounting_plan),
        }
    }
	
	async fetchWithAccountingPlanLite (col) {
        return col.with({
			'accountingplan'			: 'accountingaccount_accountingplan'
		})
    }

    async transformWithAccountingPlanLite (at) {
        return {
            'accountingaccount_id'		: at.accountingaccount_id,
			'accountingaccount_label'   : at.accountingaccount_label,
			'accountingaccount_number'  : at.accountingaccount_number,
			'accountingplan_id'			: at.accountingplan.accountingplan_id,
			'accountingplan_label'		: at.accountingplan.accountingplan_label
        }
    }
}
