import Common from '@/assets/js/common.js'
import MouvementTransformer from '@/assets/js/dexie/transformers/MouvementTransformer.js'
import MouvementCleaner from '@/assets/js/cache/cleaners/MouvementCleaner'
import Constants from "Constants"
import _cloneDeep from 'lodash/cloneDeep';
import _orderBy from 'lodash/orderBy'


var MouvementMixin = {
	methods: {
		getMouvements: async function() {
			return this.$storage.db.t('horse_mouvement')
            .then(table => {
                return table.toCollection()
            })
            .then(col => {
                return col.transform(new MouvementTransformer('withHorseAndType'))
			})
        },

        getMouvementById: async function(mouvement_id) {
			return this.$storage.db.t('horse_mouvement')
            .then(table => {
                return table.get(parseInt(mouvement_id))
            })
            .then(item => {
                return MouvementTransformer.process(item)
			})
			.then(res => {
				if(Array.isArray(res)) return res[0]
				return res
			})
        },

        // Retourne le tout dernier mouvement affecté au cheval
        getLastMouvement: async function(horse_id, transform = true) {
			return this.$storage.db.t('horse_mouvement')
            .then(table => {
				return table.where({
					mouvement_horse: parseInt(horse_id)
                })
			})
			.then(res => {
				return res.filter(mouv => mouv.mouvement_type != 5)
			})
            .then(col => {
            	if(transform)
	                return col.transform(new MouvementTransformer())
	           	return col.toArray()
			})
			.then(res => {
				return _orderBy(res, ['mouvement_date', 'mouvement_id'], ['desc', 'desc'])
			})
			.then(res => {
				if(res.length > 0) return res[0]
				return null
			})
        },

        getLastMouvementSortie: async function(horse_id) {
			return this.$storage.db.t('horse_mouvement')
            .then(table => {
				return table.where({
					mouvement_horse: parseInt(horse_id)
                })
			})
			.then(res => {
				return res.filter(mouv => mouv.mouvement_type != 5 && mouv.mouvement_type != 1)
			})
            .then(col => {
                return col.transform(new MouvementTransformer())
			})
			.then(res => {
				return _orderBy(res, ['mouvement_date', 'mouvement_id'], ['desc', 'desc'])
			})
			.then(res => {
				if(res.length > 0) return res[0]
				return null
			})
        },

        // Retourne le mouvement précédent, par rapport à un mouvement id, pour un cheval donné
        getPreviousMouvement: async function(horse_id, mouv_id) {
			return this.$storage.db.t('horse_mouvement')
            .then(table => {
				return table.where({
					mouvement_horse: parseInt(horse_id)
				})
            })
			.then(res => {
				res = res.filter(mouv => mouv.mouvement_type != 5)
				res = res.filter(mouv => mouv.mouvement_date != '') // exlue les provenances initiales
				res = res.filter(mouv => mouv.mouvement_id < mouv_id)
				return res
			})
			.then(col => {
                return col.transform(new MouvementTransformer())
			})
			.then(res => {
				return _orderBy(res, ['mouvement_id'], ['desc'])
			})
			.then(res => {
				if(res.length > 0) return res[0]
				return null
			})
        },

        /* OPERATIONS CRUD */
        /* Ajout d'un mouvement sur un cheval */
        addMouvement: async function(params, force=false) {
			const mouvement_id = Common.getNegativeId()
			params.mouvement_id = mouvement_id

			if(params.mouvement_type == 1 && !force) {
				const last_mouv = await this.getLastMouvement(params.mouvement_horse, false)
				if((last_mouv && (last_mouv.mouvement_lieu && last_mouv.mouvement_lieu == params.mouvement_lieu)) || 
					(last_mouv && (last_mouv.mouvement_tiers && last_mouv.mouvement_tiers == params.mouvement_tiers))) {
					this.failureToast("toast.horse_already_residence")
					return params
				}
			}

			await this.$storage.db.t('horse_mouvement')
			.then(table => {
				return table.add({
					mouvement_id: mouvement_id,
					mouvement_label	 : params.mouvement_label,
					mouvement_adresse: params.mouvement_adresse,
					mouvement_cp	 : params.mouvement_cp,
					mouvement_ville	 : params.mouvement_ville,
					mouvement_date   : params.mouvement_date,
					mouvement_raison : params.mouvement_raison,
					mouvement_valide : 1,
					mouvement_type   : parseInt(params.mouvement_type),			
					mouvement_lieu   : parseInt(params.mouvement_lieu) || null,
					mouvement_intra_location: parseInt(params.mouvement_intra_location) || null,
					mouvement_horse  : parseInt(params.mouvement_horse),
					mouvement_contact: params.mouvement_contact,
					mouvement_tiers: params.mouvement_tiers
				})
			})

			MouvementCleaner.inst().onMutationHorseMouv([parseInt(params.mouvement_horse)])
			MouvementCleaner.inst().onMutationHorseIntraloc([parseInt(params.mouvement_horse)])

			// On retourne l'objet créé
			return params
        },

        updateMouvement: async function(mouvement_id, params) {
			const parsed = params
			
			if(params.mouvement_horse) parsed.mouvement_horse = parseInt(params.mouvement_horse)
			if(params.mouvement_type)  parsed.mouvement_type  = parseInt(params.mouvement_type)
			if(params.mouvement_lieu)  parsed.mouvement_lieu  = parseInt(params.mouvement_lieu)

			await this.$storage.db.t('horse_mouvement')
			.then(table => {
				return table.update(
					parseInt(mouvement_id),
					parsed
				)
			})

			MouvementCleaner.inst().onMutation([parseInt(mouvement_id)])

			// On retourne l'objet modifié
			return params
        },

        reloadMouvementsListe() { // à mon avis, ça c'est du thomas
            this.loadMouvementsListe(true)
        },

        /* Action : Suppression de mouvements */
        async applyDeleteMouvements(data) {
			const mouvements_ids = data.map(mouvement => parseInt(mouvement.mouvement_id) )
			
			await this.$storage.db.transaction(
                'rw',
                ['horse_mouvement'],
                async () => {
					await this.$storage.db.t('horse_mouvement')
					.then(table => {
						return table.where('mouvement_id')
						.anyOf(mouvements_ids)
						.invalid()
					})

					// On retourne l'objet modifié
					return mouvements_ids
				}
			)

			const mouvement_horses = data.map(mouvement => parseInt(mouvement.mouvement_horse))

			switch(data.mouvement_type) {
				case 5:
					MouvementCleaner.inst().onMutationHorseMouv(mouvement_horses)
					break
				default:
					MouvementCleaner.inst().onMutationHorseIntraloc(mouvement_horses)
					break
			}
        },

        /* Tout le mécanisme de sauvegarde d'un mouvement et de son lieu */
        processMouvOperations: async function(datas, checked_horses, mouvement_id=0, first_mouv=false) {
            // permet de lock l'update de la résidence si elle a déjà été faite plus tot
            // cas de modif d'un mouv, si le lieu a été changé via le form, on ajoute un lieu, qu'on considère comme nouvelle résidence
			let has_already_updated_residence = false
			
			// On récupére les mouvements précédents en amont pour ne pas avoir à le faire durant la transaction
			const mouvements = datas.filter(data => data.type == 'mouvement')
			const mouv_retour = datas.filter(data => data.type == 'mouvement_retour')
			let previous_mouv = []

			/* Si on est sur un ajout de mouvement(s) */
			// On regarde si on a un mouvement retour - on peut avoir une date retour pour chaque cheval
			if(mouvements.length > 0 && mouv_retour.length > 0) {
				let mouv_retour_prs = []

				checked_horses.forEach(horse_id => {
					mouv_retour_prs.push(new Promise(async (res, rej) => {
						const previous_mouv_tab = await this.getHorseLastResidence(this.deppCloneObj(horse_id))
							.catch(e => { rej(e) })

						if(Array.isArray(previous_mouv_tab)) {
							previous_mouv.push(previous_mouv_tab[0])
						}
						else {
							previous_mouv.push(previous_mouv_tab)
						}
						res()
					}))
				})

				await Promise.all(mouv_retour_prs)
					.catch(e => {
						console.error("MouvementMixin::processMouvOperations => An error occured on mouv_retour_prs promises", e)
						return false
					})
			}

			// On exécute les modifs dans une transaction commune
            try {
				return this.$storage.db.transaction(
					'rw',
					['horse_mouvement', 'horse_residence', 'lieu'],
					async () => {

						// On extrait les éventuels lieux à enregistrer
						const lieux = datas.filter(data => data.type == 'lieu')
						let lieux_saved = []

						if(lieux.length > 0) {
							let tab_promises_lieux = []
							lieux.forEach(lieu => {
								// eslint-disable-next-line no-async-promise-executor
								tab_promises_lieux.push(new Promise(async (res, rej) => {
									const lieu_tag = _cloneDeep(lieu.lieu_tag)

									let inserted = await this.saveLieu({
										lieu_label  : lieu.lieu_label,
										lieu_adresse: lieu.lieu_adresse,
										lieu_cp     : lieu.lieu_cp,
										lieu_ville  : lieu.lieu_ville,
										lieu_type   : lieu.lieu_type
									})
									.catch(e => rej(e))

									inserted.lieu_tag = lieu_tag
									res(inserted)
								}))
							})

							lieux_saved = await Promise.all(tab_promises_lieux)
						}

						// On extrait les provenances, qui doivent être inscrites avant les mouvements
						const provenances = datas.filter(data => data.type == 'provenance')
						if(provenances.length > 0) {
							let tab_promises_provenances = []

							// Si on a un mouvement_id (édition de mouvement) et une provenance, c'est qu'on édite la premiere entrée du cheval
							if(mouvement_id) {
								// On commence par récupérer l'id de la provenance initiale
								const prov_init = await this.getInitialProvenance(checked_horses[0])
								
								if(prov_init && prov_init.mouvement_id) {
									const mouv_update = provenances[0]
									await this.updateMouvement(prov_init.mouvement_id, {
										mouvement_label  : mouv_update.mouvement_label,
										mouvement_date   : mouv_update.mouvement_date,
										mouvement_raison : mouv_update.mouvement_raison,
										mouvement_type   : mouv_update.mouvement_type,
										mouvement_horse  : checked_horses[0],
										mouvement_contact: mouv_update.mouvement_contact,
										mouvement_tiers	 : mouv_update.mouvement_tiers
									})
								}
								else {
									const mouv_insert = provenances[0]
									await this.addMouvement({
										mouvement_label  : mouv_insert.mouvement_label,
										mouvement_adresse: mouv_insert.mouvement_adresse,
										mouvement_cp     : mouv_insert.mouvement_cp,
										mouvement_ville  : mouv_insert.mouvement_ville,
										mouvement_date   : mouv_insert.mouvement_date,
										mouvement_raison : mouv_insert.mouvement_raison,
										mouvement_type   : mouv_insert.mouvement_type,
										mouvement_lieu   : null,
										mouvement_horse  : checked_horses[0],
										mouvement_contact: mouv_insert.mouvement_contact,
										mouvement_tiers	 : mouv_insert.mouvement_tiers
									})
								}
							}
							// Sinon, on ajoute de manière classique
							else {
								const mouvement_lieu = lieux_saved.find(lieu => lieu.lieu_tag === 'provenance')
								const mouvement_lieu_id = mouvement_lieu ? mouvement_lieu.lieu_id : null

								checked_horses.forEach(horse => {
									provenances.forEach(mouvement => {
										// eslint-disable-next-line no-async-promise-executor
										tab_promises_provenances.push(new Promise(async (res, rej) => {
											const horse_id = JSON.stringify(horse)
											const local_mouv = this.deppCloneObj(mouvement)

											// Si provenance initiale
											if(local_mouv.mouvement_date == '0000-00-00' || local_mouv.mouvement_date == '') {
												local_mouv.mouvement_lieu = null
											}
											// Sinon, on a un lieu correspondant
											else {
												local_mouv.mouvement_lieu = mouvement_lieu_id ? mouvement_lieu_id : local_mouv.mouvement_lieu
											}

											await this.addMouvement({
												mouvement_label  : local_mouv.mouvement_label,
												mouvement_adresse: local_mouv.mouvement_adresse,
												mouvement_cp     : local_mouv.mouvement_cp,
												mouvement_ville  : local_mouv.mouvement_ville,
												mouvement_date   : local_mouv.mouvement_date,
												mouvement_raison : local_mouv.mouvement_raison,
												mouvement_type   : local_mouv.mouvement_type,
												mouvement_lieu   : local_mouv.mouvement_lieu,
												mouvement_horse  : JSON.parse(horse_id),
												mouvement_contact: local_mouv.mouvement_contact,
												mouvement_tiers	 : local_mouv.mouvement_tiers
											})
											.catch(e => rej(e))

											res()
										}))
									})
								})
								await Promise.all(tab_promises_provenances)
							}
						}

						// On extrait le(s) mouvement(s) à enregistrer
						let tab_promises_mouv = []
						if(mouvements.length > 0) {
							// Si le mouvement fait référence à un lieu tout juste inséré, on récupère le lieu_id
							const mouvement_lieu = lieux_saved ? lieux_saved.find(lieu => lieu.lieu_tag === 'mouvement') : null
							const mouvement_lieu_id = mouvement_lieu ? mouvement_lieu.lieu_id : null

							/* Si on est sur une update d'un mouvement */
							if(mouvement_id) {
								const mouv_update = mouvements[0]
								// Si on a un id de nouveau lieu, on le prend. Sinon on garde celui d'origine
								mouv_update.mouvement_lieu = mouvement_lieu_id ? mouvement_lieu_id : mouv_update.mouvement_lieu

								await this.updateMouvement(mouvement_id, {
									mouvement_label  : mouv_update.mouvement_label,
									mouvement_adresse: mouv_update.mouvement_adresse,
									mouvement_cp     : mouv_update.mouvement_cp,
									mouvement_ville  : mouv_update.mouvement_ville,
									mouvement_date   : mouv_update.mouvement_date,
									mouvement_raison : mouv_update.mouvement_raison,
									mouvement_type   : mouv_update.mouvement_type,
									mouvement_lieu   : mouv_update.mouvement_lieu,
									mouvement_intra_location: mouv_update.mouvement_intra_location,
									mouvement_horse  : checked_horses[0],
									mouvement_contact: mouv_update.mouvement_contact,
									mouvement_tiers	 : mouv_update.mouvement_tiers
								})

								// // Si à la modif du mouvement, le user a choisit une autre résidence, on update la résidence actuelle du cheval => invalide si on modifie un mouvement antérieur
								// if(!has_already_updated_residence && mouv_update.mouvement_lieu && mouv_update.mouvement_type == 1) {
								// 	has_already_updated_residence = true
								//     await this.updateHorseResidence(checked_horses[0], mouv_update.mouvement_lieu)
								// }

								return true
							}

							// Chemin classique - pour chaque cheval séléctionné, on enregistre le mouvement => à transformer de tab de promises ? question des données à figer dans la boucle
							const mouvement = mouvements[0]
							mouvement.mouvement_lieu = mouvement_lieu_id ? mouvement_lieu_id : mouvement.mouvement_lieu

							checked_horses.forEach(horse => {
								// eslint-disable-next-line no-async-promise-executor
								tab_promises_mouv.push(new Promise(async (res, rej) => {
									const horse_id = JSON.stringify(horse)
									const local_mouv = this.deppCloneObj(mouvement)

									await this.addMouvement({
										mouvement_label  : local_mouv.mouvement_label,
										mouvement_adresse: local_mouv.mouvement_adresse,
										mouvement_cp     : local_mouv.mouvement_cp,
										mouvement_ville  : local_mouv.mouvement_ville,
										mouvement_date   : local_mouv.mouvement_date,
										mouvement_raison : local_mouv.mouvement_raison,
										mouvement_type   : local_mouv.mouvement_type,
										mouvement_lieu   : local_mouv.mouvement_lieu,
										mouvement_horse  : JSON.parse(horse_id),
										mouvement_contact: local_mouv.mouvement_contact,
										mouvement_tiers	 : local_mouv.mouvement_tiers
									})
									.catch(e => rej(e))

									// Si c'est un mouvement vers une résidence (de type 1) on assigne le cheval à cette résidence
									// if(!has_already_updated_residence && local_mouv.mouvement_lieu && local_mouv.mouvement_type == 1 && first_mouv) {
									if(!has_already_updated_residence && local_mouv.mouvement_lieu && local_mouv.mouvement_type == 1) {
										await this.updateHorseResidence(JSON.parse(horse_id), local_mouv.mouvement_lieu)
										.catch(e => rej(e))
									}
									res()
								}))
							})
						}

						// Si on a une mouvement vers une localisation_interne
						const intra_mouvement = datas.find(data => data.type == 'intra_location')
						
						// Pour l'update, on considère le mouvement typé 'mouvement' et non 'intra_location'. On passe donc dans la méthode updateMouvement
						if(intra_mouvement && !mouvement_id) {
							checked_horses.forEach(horse => {
								// eslint-disable-next-line no-async-promise-executor
								tab_promises_mouv.push(new Promise(async (res, rej) => {
									const horse_id = JSON.stringify(horse)
									const local_mouv = this.deppCloneObj(intra_mouvement)

									await this.addMouvement({
										mouvement_label  : local_mouv.mouvement_label,
										mouvement_adresse: local_mouv.mouvement_adresse,
										mouvement_cp     : local_mouv.mouvement_cp,
										mouvement_ville  : local_mouv.mouvement_ville,
										mouvement_date   : local_mouv.mouvement_date,
										mouvement_raison : local_mouv.mouvement_raison,
										mouvement_type   : 5, // localisation interne
										mouvement_horse  : JSON.parse(horse_id),
										mouvement_lieu   : null,
										mouvement_intra_location: local_mouv.mouvement_intra_location,
										mouvement_contact: local_mouv.mouvement_contact,
										mouvement_tiers	 : local_mouv.mouvement_tiers
									}).catch(e => rej(e))

									res()
								}))
							})	
						}

						// Si on a récupéré un lieu de retour précédemment, on enregistre un mouvement retour à ce lieu, et à la date retour donnée
						// On aura ce cas uniquement dans un ajout de mouvement pour un seul cheval. Trop complexe à gérer si plusieurs chevaux
						if((Object.keys(previous_mouv)).length > 0) {
							const retour_raison = this.getTrad("mouvement.mouvement_raison_retour_default_value") + mouvements[0].mouvement_raison

							previous_mouv.forEach(mouv => {
								tab_promises_mouv.push(this.addMouvement({
									mouvement_label  : mouv.mouvement_label,
									mouvement_adresse: mouv.mouvement_adresse,
									mouvement_cp     : mouv.mouvement_cp,
									mouvement_ville  : mouv.mouvement_ville,
									mouvement_date   : mouv_retour[0].date_retour,
									mouvement_raison : retour_raison,
									mouvement_type   : 1,
									mouvement_horse  : mouv.mouvement_horse,
									mouvement_lieu   : mouv.mouvement_lieu,
									mouvement_contact: mouv.mouvement_contact,
									mouvement_tiers	 : mouv.mouvement_tiers
								}, true))
							})
						}

						return await Promise.all(tab_promises_mouv)
					}
				)
			}
            catch(e) {
                console.error("MouvementMixin::processMouvOperations => Error while processing", e)
                return false
            }
        },

        getPresenceDays: async function(horse_id, lieu_id, date_start, date_end) {
        	const params = '&date_start='+date_start.toDateInputValue()+'&date_end='+date_end.toDateInputValue()+'&lieu_id='+lieu_id
			const url = this.constructRoute(Constants.MOUVEMENT_PRESENCE_HORSE_URL, {horse_id}) + "?licence_key="+Constants.USER_LICENCE_KEY + params
			
			const result = await this.$request.request_get_api("AccountingMixin::getPresenceDays", url)
			.catch(error => {
				console.error("AccountingMixin::getPresenceDays => ERROR", error)
				return null
			})
			if(result) return result.retour
			return null
        },

        getPresenceDaysByTier: async function(horses_id, tier_id, date_start, date_end) {
        	const params = '&date_start='+date_start.toDateInputValue()+'&date_end='+date_end.toDateInputValue()	
			const url = this.constructRoute(Constants.MOUVEMENT_PRESENCE_HORSE_BY_TIER_URL, {tier_id,horses_id}) + "?licence_key="+Constants.USER_LICENCE_KEY + params
			
			const result = await this.$request.request_get_api("AccountingMixin::getPresenceDaysByTier", url)
			.catch(error => {
				console.error("AccountingMixin::getPresenceDaysByTier => ERROR", error)
				return null
			})
			if(result) return result.retour
			return null
        },

        createEntreSortie: async function(horse_id, residence, date) {
        	let sortie = await this.getLastMouvementSortie(horse_id)

        	await this.addMouvement({
				mouvement_label  : residence.lieu_label,
				mouvement_adresse: residence.lieu_adresse,
				mouvement_cp     : residence.lieu_cp,
				mouvement_ville  : residence.lieu_ville,
				mouvement_date   : date,
				mouvement_raison : this.getTrad('mouvement.saillie'),
				mouvement_type   : residence.lieu_type.lieutype_id,
				mouvement_horse  : JSON.parse(horse_id),
				mouvement_lieu   : residence.lieu_id,
			})

        	if(sortie) {
				await this.addMouvement({
					mouvement_label  : sortie.mouvement_label,
					mouvement_adresse: sortie.mouvement_adresse,
					mouvement_cp     : sortie.mouvement_cp,
					mouvement_ville  : sortie.mouvement_ville,
					mouvement_date   : date,
					mouvement_raison : this.getTrad('mouvement.retour_saillie'),
					mouvement_type   : sortie.mouvement_type,
					mouvement_horse  : JSON.parse(horse_id),
					mouvement_lieu   : sortie.mouvement_lieu
				})
			}
        },

        getSuggestionMouvementSaillie: async function(type, start, end) {
        	const params = '&start='+start+'&end='+end	
			const url = this.constructRoute(Constants.MOUVEMENT_SUGGESTION_SAILLIE, {type}) + "?licence_key="+Constants.USER_LICENCE_KEY + params
			
			const result = await this.$request.request_get_api("MouvementMixin::getSuggestionMouvementStallion", url)
			.catch(error => {
				console.error("MouvementMixin::getSuggestionMouvementStallion => ERROR", error)
				return null
			})
			if(result) return result.retour
			return null
        },

        addMouvementFromSuggestion: async function(params) {
			const url = this.constructRoute(Constants.MOUVEMENT_SUGGESTION_SAILLIE, {type:''}) + "?licence_key="+Constants.USER_LICENCE_KEY

			const result = await this.$request.request_post_api("MouvementMixin::addMouvementFromSuggestion", url, {params}, false)
			.catch(error => {
				console.error("MouvementMixin::addMouvementFromSuggestion => ERROR", error)
				return null
			})

			return result
        }
	}
}

export default MouvementMixin
