import Transformer from './Transformer'
import Vue from 'vue'

/**
 * Retourne une liste de
 * @param {Dexie.Collection} season
 */
export default class PhoneTransformer extends Transformer {

    async transform (phone) {
        return {
            'phone_id'                  : phone.phone_id,
            'phone_indicatif'           : phone.phone_indicatif,
			'phone_country'             : phone.phone_country,
			'phone_tiers'             	: phone.phone_tiers,
            'phone_numero'              : phone.phone_numero,
            'phone_type'                : Vue.i18n.translate('tiers.phone_type.' + phone.phone_type),
            'phone_combine'				: phone.phone_indicatif+phone.phone_numero,
            'phone_libelle'				: phone.phone_libelle
        }
    }
}
