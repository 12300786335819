import Vue from 'vue'
import Transformer from './Transformer'

export default class RaceTransformer extends Transformer {

	table = 'horse_race'

    async transform (race) {
        return {
			race_code: race.race_code,
			race_label: race.race_label,
            race_type: race.race_type,
            race_display:  Vue.i18n.translate('race.' + race.race_code) + ' / ' + race.race_label
        }
    }
}
