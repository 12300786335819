const state = {
    act_selected: false,
    date_act: false,
    questions: [],
    accounting_plan: false,
    act_statut: 0,
    act_horse_accounting: false,
    triggered_list: []
}

const mutations = {
    editAct(state, act) {
        state.act_selected = act
    },

    editDate(state, date) {
        state.date_act = date
    },

    editQuestions(state, questions) {
        state.questions = questions
    },

    editAccountingPlan(state, accounting_plan) {
        state.accounting_plan = accounting_plan
    },

    editActStatut(state, statut) {
        state.act_statut = statut
    },

    editHorseAccounting(state, horse_accounting) {
        state.act_horse_accounting = horse_accounting
    },

    addTriggered(state, { type, horses_ids, date }) {
        state.triggered_list.push({
            type: type,
            horses_ids: horses_ids,
            date: date
        })
    }
}

export default {
    namespaced: true,
    state,
    mutations
}
