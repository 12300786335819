import Transformer from './Transformer'

/**
 * Retourne une liste de
 * @param {Dexie.Collection} pension
 */
export default class PensionTransformer extends Transformer {

    async transform (pension) {
        return {
			'pension_id': pension.pension_id,
			'pension_label': pension.pension_label,
			'pension_accountingplan': pension.pension_accountingplan,
			'pension_valide': pension.pension_valide
        }
    }
}