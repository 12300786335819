import Vue from 'vue'
import Transformer from './Transformer'

/**
 * Retourne une liste de
 * @param {Dexie.Collection} intralocationtype
 */
export default class IntraLocationTypeTransformer extends Transformer {

	table = 'intra_location_type'
	
	getTypeTrad(label) {
		return Vue.i18n.translate('intra_location.type.'+label)
	}

	transform(col) {
		return {
			"id": col.intralocationtype_id,
			"label": this.getTypeTrad(col.intralocationtype_labeltrad),
			"labeltrad": col.intralocationtype_labeltrad,
			"code": col.intralocationtype_code,
		}
	}
}