import Transformer from './Transformer'


export default class RobeTransformer extends Transformer {

	table = 'horse_robe'

    async transform (robe) {
        return {
			robe_code: robe.robe_code,
			robe_label: robe.robe_label,
            robe_display: robe.robe_code + ' / ' + robe.robe_label
        }
    }
}
