const state = {
    lieu: false,
    date: false
}

const mutations = {
    editLieu(state, type) {
        state.lieu = type
    },

    editDate(state, date) {
        state.date = date
    },
}

export default {
    namespaced: true,
    state,
    mutations
}
