import Transformer from './Transformer'
import ContactTransformer from '@/assets/js/dexie/transformers/ContactTransformer.js'
import HorseContactFonctionTransformer from '@/assets/js/dexie/transformers/HorseContactFonctionTransformer.js'
import HorseTransformer from '@/assets/js/dexie/transformers/HorseTransformer.js'

/**
 * Retourne une liste de
 * @param {Dexie.Collection} season
 */
export default class HorseContactTransformer extends Transformer {

    async transform (horseContact) {
        return {
            'horsecontact_id'           : horseContact.horsecontact_id,
            'horsecontact_horse'        : horseContact.horsecontact_horse,
            'horsecontact_contact'      : horseContact.horsecontact_contact
        }
    }

    async fetchWithContactAndFonction (col) {
        return col.with({
            'contact'                   : 'horsecontact_contact',
            'fonction'                  : 'horse_contact_fonction'
        })
    }

   async transformWithContactAndFonction (horseContact) {
        return {
            'horsecontact_id'           : horseContact.horsecontact_id,
            'horsecontact_horse'        : horseContact.horsecontact_horse,
            'horsecontact_contact'      : horseContact.horsecontact_contact,
            'contact'                   : await ContactTransformer.process(horseContact.contact, 'withPhone'),
            'fonctions'                 : await HorseContactFonctionTransformer.process(horseContact.fonction)
        }
    }

     async fetchWithHorse (col) {
        return col.with({
            'contact'                   : 'horsecontact_contact',
            'fonction'                  : 'horse_contact_fonction',
            'horse'                     : 'horsecontact_horse'
        })
    }

    async transformWithHorse (horseContact) {
        return {
            'horsecontact_id'           : horseContact.horsecontact_id,
            'horsecontact_horse'        : horseContact.horsecontact_horse,
            'horsecontact_contact'      : horseContact.horsecontact_contact,
            'horse'                     : await HorseTransformer.process(horseContact.horse)
        }
    }

    async fetchWithHorseAndFonction (col) {
        return col.with({
            'contact'                   : 'horsecontact_contact',
            'fonction'                  : 'horse_contact_fonction',
            'horse'                     : 'horsecontact_horse'
        })
    }

    async transformWithHorseAndFonction (horseContact) {
        return {
            'horsecontact_id'           : horseContact.horsecontact_id,
            'horsecontact_horse'        : horseContact.horsecontact_horse,
            'horsecontact_contact'      : horseContact.horsecontact_contact,
            'horse'                     : await HorseTransformer.process(horseContact.horse),
            'fonctions'                 : await HorseContactFonctionTransformer.process(horseContact.fonction)
        }
    }
}
