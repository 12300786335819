import { SYNC_STATES, PROGRESS_STATES } from '@/assets/js/sync/states'

const state = {
	/**
	 * Etat de la synchronisation en général
	 */
    state: SYNC_STATES.STOPPED,
	/**
	 * Etat de la synchronisation en cours
	 */
	progressState: PROGRESS_STATES.COMPLETED,
	/**
	 * Modèles en cours de traitement
	 */
	progressModels: [],
}

const mutations = {
    setState(state, syncState) {
        state.state = syncState
    },
    setProgressState(state, progressState) {
        state.progressState = progressState
	},
	setProgressModels(state, progressModels) {
		state.progressModels = progressModels
	}
}

export default {
    namespaced: true,
    state,
    mutations
}
