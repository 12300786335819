import Transformer from './Transformer'

export default class TiersHorsePartTransformer extends Transformer {

	table = 'tiers_horse_part'

    transform(tiers_horse_part) {
        return {
			'tiershorsepart_id': tiers_horse_part.tiershorsepart_id,
			'tiershorsepart_tiershorse': tiers_horse_part.tiershorsepart_tiershorse,
			'tiershorsepart_pension': tiers_horse_part.tiershorsepart_pension,
			'tiershorsepart_frais': tiers_horse_part.tiershorsepart_frais,
			'tiershorsepart_gains': tiers_horse_part.tiershorsepart_gains,
			'tiershorsepart_propriete': tiers_horse_part.tiershorsepart_propriete,
			'tiershorsepart_contract': tiers_horse_part.tiershorsepart_contract,
			'tiershorsepart_entraineur': tiers_horse_part.tiershorsepart_entraineur,
			'tiershorsepart_jockey': tiers_horse_part.tiershorsepart_jockey,
			'tiershorsepart_date': tiers_horse_part.tiershorsepart_date.toISOString()
        }
	}
}