import Transformer from './Transformer'
import ContractConfigTransformer from './ContractConfigTransformer'

/**
 * Retourne une liste de
 * @param {Dexie.Collection} season
 */
export class SeasonTransformer extends Transformer {

    table = 'season'
    needAccessRights = true

	async contracts_config(item) {
        return this.db().t('contract_config')
        .then(table => {
            return table
            .where({
                contractconfig_season: item.season_id
            })
        })
        .then(col => {
            return col.toArray()
        })
    }

    async contracts_config_stallion(item) {
        if (!this.hasRight('SAILL')) {
            return null
        }

        return this.db().t('contract_config')
        .then(table => {
            return table
            .where({
                contractconfig_season: item.season_id
            })
        })
        .then(col => {
            return col.transform(new ContractConfigTransformer('withoutSeason'))
        })
    }

    async number_template(item) {
        if (!this.hasRight('FACT')) {
            return
        }

        return this.db().t('number_template')
        .then(table => {
            return table
            .where({
                'numberable_type' : 'App\\Model\\Season',
                'numberable_field' : 'seasonPrefix',
                'numberable_id': item.season_id
            })
        })
        .then(col => {
            return col.toArray()
        })
    }

    async fetch(col) {
        this.additionalColumns({
	        'number_template': this.number_template
		})
		
		return col
    }

    async transform(season) {
        return {
            'season_id'             : season.season_id,
            'season_label'          : season.season_label,
            'season_start'          : Date.parseTz(season.season_start),
            'season_end'            : Date.parseTz(season.season_end),
            'season_default'        : season.season_default,
            'season_use_global_number_template'        : season.season_use_global_number_template,
            'number_template'       : season.number_template
        }
	}

	async fetchWithContracts(col) {
		this.additionalColumns({
	        'contracts_config': this.contracts_config,
            'number_template': this.number_template
		})
		
		return col
	}
	
	async transformWithContracts(season) {
        return {
            'season_id'             : season.season_id,
            'season_label'          : season.season_label,
            'season_start'          : Date.parseTz(season.season_start),
            'season_end'            : Date.parseTz(season.season_end),
			'season_default'        : season.season_default,
			'season_use_global_number_template'        : season.season_use_global_number_template,
			'contracts_config'		: season.contracts_config,
            'number_template'       : season.number_template
        }
    }

    async fetchWithContractsConfigStallion(col) {
        this.additionalColumns({
            'contracts_config_stallion': this.contracts_config_stallion,
            'number_template': this.number_template
        })
        
        return col
    }

    async transformWithContractsConfigStallion(season) {
        return {
            'season_id'             : season.season_id,
            'season_label'          : season.season_label,
            'season_start'          : Date.parseTz(season.season_start),
            'season_end'            : Date.parseTz(season.season_end),
            'season_default'        : season.season_default,
            'season_use_global_number_template'        : season.season_use_global_number_template,
            'contracts_config'      : season.contracts_config_stallion,
            'number_template'       : season.number_template
        }
    }

    async transformWithContractsConfigStallionLight(season) {
        return {
            'season_id'             : season.season_id,
            'season_label'          : season.season_label,
            'season_start'          : Date.parseTz(season.season_start),
            'season_end'            : Date.parseTz(season.season_end),
            'season_default'        : season.season_default,
            'contracts_config'      : season.contracts_config_stallion,
        }
    }

    async transformLight(season) {
        return {
            'season_id'             : season.season_id,
            'season_label'          : season.season_label,
        }
    }
}

export default SeasonTransformer
