import Transformer from './Transformer'
import HorseTransformer from './HorseTransformer'

import _orderBy from 'lodash/orderBy'
import _defaults from 'lodash/defaults'

/**
 * Retourne une liste de
 * @param {Dexie.Collection} documents
 */
export class DocumentLienTransformer extends Transformer {

	table = 'horse_document_lien'
	transactionTables = ['horse', 'horse_document']

	async horse(lien) {
		const horse = await this.fetchRelationship(lien, 'documentlien_horse')
		return HorseTransformer.process(horse, 'withPedigree')
	}

	async document(lien) {
		const document = this.fetchRelationship(lien, 'documentlien_document')
		return _defaults(document, {
			document_id: 0,
			document_name: '',
			document_filename: '',
			document_date: new Date()
		})
	}

	async fetch(col) {
		this.additionalColumns({
			horse: this.horse,
			document: this.document
		})

		return col
	}

	async transform(lien) {
		return {
			'documentlien_id': lien.documentlien_id,
			'documentlien_horse': lien.documentlien_horse,
			'document_id': lien.document ? lien.document.document_id : null,
			'document_name': lien.document ? lien.document.document_name : null,
			'document_filename': lien.document ? lien.document.document_filename : null,
			'document_date': lien.document ? lien.document.document_date : null,
			'horse': lien.horse
		}
	}
}

export default DocumentLienTransformer
