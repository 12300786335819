import Vue from 'vue'
import GroomyDB, { ADDITIONAL_COLUMNS } from '@/assets/js/dexie/GroomyDB'
import { INVALID_WHITELIST } from '@/assets/js/dexie/GroomyDB'
import { RECORD_STATES } from '@/assets/js/sync/states'
import Common from '@/assets/js/common'

let invalidDeletions = {}

export async function afterCreateOrUpdate(tableName, request) {
	// Lors de la déconnexion on ne touche plus à cette DB
	const db = await GroomyDB.getInstance(false)
    const table = await db.t(tableName)

	let records = []

    for(let i = 0; i < request.values.length; i++) {
		let obj = request.values[i]
		let oldObj = request.oldValues[i]
		let mods = await afterMutateObject(table, oldObj, obj)

		if (Object.keys(mods).length > 0) {
			records.push({
				columns: mods,
				table_name: tableName,
				row_id: oldObj[table.schema.primKey.name] || obj[table.schema.primKey.name],
				operation: 'upsert',
				state: RECORD_STATES.WAITING
			})
		}
	}

	await onRecordsChange(records, request)
}

export async function afterMutateObject(table, oldObj, obj) {
    const primKeyName = table.schema.primKey.name
    const keys = Object.keys(table.schema.idxByName)
	const validKeyName = keys.find(k => k.endsWith('_valide'))

    if (validKeyName && !obj[validKeyName]) {
		await deleteInvalid(table, [obj[primKeyName]])
	}

	let mods = Common.difference(obj, oldObj)

	//filtre sur les colonnes indexees pour ne pas _sync suite a une indexation
	const acInfos = ADDITIONAL_COLUMNS[table.name]
	if(acInfos && acInfos.columns.length>0) {
			Object.keys(mods)
			  .filter(key => acInfos.columns.includes(key))
			  .forEach(key => delete mods[key]);
	}
	return mods
}

export function deleteInvalid(table, keys) {
	if (INVALID_WHITELIST.includes(table.name)) {
		return
	}

	// On indique que ces suppressions doivent etre ignorées
	keys.forEach(key => {
		invalidDeletions[`${table.name}_${key}`] = true
	})

	return table.bulkDelete(keys)
}

export async function afterDelete(tableName, request) {
	let records = []

	request.keys.forEach(id => {
		// On ignore les lignes supprimées depuis de deleteInvalid
		if (invalidDeletions[`${tableName}_${id}`]) {
			return
		}

		records.push({
			table_name: tableName,
			row_id: id,
			operation: 'delete',
			state: RECORD_STATES.WAITING
		})
	})

	await onRecordsChange(records, request)
}

export function afterDeleteRange(tableName, request) {
	console.warn('La suppression d\'éléments par "range" n\'est pas synchronisée. Voir DBCore.afterDeleteRange')
}

async function onRecordsChange(records, request) {
	if (records.length === 0
		|| request.trans.source === 'sync::handleData'
	) {
		return
	}

	if (typeof window === 'undefined') {
		// On arrive ici lorsqu'on met en cache les ADDITIONAL_COLUMNS des tableaux
		return
	}

	await Vue.prototype.$sync.addPendingRecords(records)
}
