import Vue from 'vue'
import * as Comlink from 'comlink'

let workers = {}

export default class OfflineUtils {
	/**
	 * Récupère une instance de transformer qui tourne dans un worker
	 * @param {String} name Chemin du transformer
	 * @param {String} transformName Argument à passer au transformer
	 * @returns {Transformer} Instance de transformer exécutée dans un worker
	 */
	static async getTransformerInstance(name, transformName) {
		if (!workers[`${name}-${transformName}`]) {
			const module = await OfflineUtils.getTransformerModule(name)
			const proxy = new module.default()

			// Pour ne pas instancier un proxy sur les transformers ou on a pas besoin de request, on file la liste ici
			let request = null
			if(['ContractTransformer'].includes(name)) {
				request = Comlink.proxy(Vue.prototype.$request)
			}

			workers[`${name}-${transformName}`] = await new proxy[name](transformName, request)
		}

		return workers[`${name}-${transformName}`]
	}

	/**
	 * Pour ne pas build inutilement des transformers, il faut ajouter les transformers utilisés
	 * dans les tableaux à cette fonction.
	 * /!\ LES TRANSFORMERS DOIVENT ETRE DANS L'ORDRE ALPHABETIQUE /!\
	 * @param {String} name Nom du transformer
	 * @returns {ES6Module} Module contenant le transformer
	 */
	static async getTransformerModule(name) {
		switch(name) {
			case 'ActeTransformer':
				return import(`comlink-loader!../dexie/transformers/ActeTransformer.js`)
			case 'ContractTransformer':
				return import(`comlink-loader!../dexie/transformers/ContractTransformer.js`)
			case 'DocumentLienTransformer':
				return import(`comlink-loader!../dexie/transformers/DocumentLienTransformer.js`)
			case 'HorseTransformer':
				return import(`comlink-loader!../dexie/transformers/HorseTransformer.js`)
			case 'IntraLocationTransformer':
				return import(`comlink-loader!../dexie/transformers/IntraLocationTransformer.js`)
			case 'LieuTransformer':
				return import(`comlink-loader!../dexie/transformers/LieuTransformer.js`)
			case 'MouvementTransformer':
				return import(`comlink-loader!../dexie/transformers/MouvementTransformer.js`)
			case 'NoteTransformer':
					return import(`comlink-loader!../dexie/transformers/NoteTransformer.js`)
			case 'SanitaireTransformer':
					return import(`comlink-loader!../dexie/transformers/SanitaireTransformer.js`)
			case 'SeasonTransformer':
				return import(`comlink-loader!../dexie/transformers/SeasonTransformer.js`)
			case 'TierTransformer':
				return import(`comlink-loader!../dexie/transformers/TierTransformer.js`)
			default:
				throw new Error('Vous devez ajouter votre transformer à la méthode getTransformerModule du fichier @/assets/js/utils/offline.js')
		}
	}

	static resetCachedWorkers() {
		Object.keys(workers).forEach(workerName => {
			const transformer = workers[workerName]
			transformer.reset()
			delete workers[workerName]
		})
	}
}
