const state = {
    userAccess: {
		hasMvpAccess: false,
		hasMultiActiveLicences: false,
        hasFacturationAccess: false,
        hasEntitiesAccess: false,
        hasHubspotAccess: false,
        hasBreedingAccess: false,
        hasSaillieAccess: false,
        hasMarketingAccess: false,
        hasActTypeAccess: false,
        hasTriggerAccess: false,
        hasConfTableAccess: false,
        hasAdminAccess: false,
        hasDevAccess: false,
        hasEmailVerified: false,
		hasInvoiceSupplier: false,
		hasInheritedlicenceFacturationAccess: false,
        hasDPSAccess: false,
        hasDevisAccess: false,
        hasSanitaireAccess: false,
        hasPaxAccess: false,
        hasMailTiersAccess: false
    },
    refreshTabs: {
        TiersFiche: {
            listPayment: false,
            coordonneesTiers: false,
            Invoice: false,
        }
    },
    horseFicheReadonly: false,
    redirectParams: {}, // params supplémentaires à utiliser lorsqu'un composant effectue une redirection via un paramètre de route 'from'
}

const mutations = {
    updateUserAccess(state, payload) {
        const typeAccess = payload.accessType
        state.userAccess[typeAccess] = payload.val
    },
    updateRefreshTabs(state, payload){
        state.refreshTabs[payload.view][payload.tab] = payload.val
    },
    updatehorseFicheReadonly(state, payload) {
        state.horseFicheReadonly = payload.val
    },
    setRedirectParams(state, payload) {
        state.redirectParams = payload.params
    },
    unsetRedirectParams(state) {
        state.redirectParams = {}
    }
}

export default {
    namespaced: false,
    state,
    mutations
}
