import Vue from 'vue'
import Common from '@/assets/js/common'
import Transformer from './Transformer'
import LieuTypeTransformer from './LieuTypeTransformer'
import ContactTransformer from './ContactTransformer'
import IntraLocationTransformer from './IntraLocationTransformer'

/**
 * Retourne une liste de
 * @param {Dexie.Collection} lieu_type
 */
export class LieuTransformer extends Transformer {

	table = 'lieu'
	transactionTables = ['lieu_type']

	
	
	getTypeTrad(label) {
		return Vue.i18n.translate('types.lieu_type.'+label)
	}
	
    transform (lieu) {
		return {
			'lieu_id'       : lieu.lieu_id,
            'lieu_label'    : lieu.lieu_label,
            'lieu_adresse'  : lieu.lieu_adresse,
            'lieu_cp'       : lieu.lieu_cp,
            'lieu_ville'    : lieu.lieu_ville,
			'lieu_type' 	: lieu.lieu_type,
			'lieu_contact'	: lieu.lieu_contact,
			'lieu_img_url'	: lieu.lieu_img_url,
			'groupe' 		: lieu.lieu_type == 1 ? this.getTypeTrad('residences') : this.getTypeTrad('autres'),
        }
	}

	async contact(lieu) {
		if(lieu && lieu.lieu_contact){
			const contact = await this.fetchRelationship(lieu, 'lieu_contact')
			return ContactTransformer.process(contact)
		}
		return {}
	}

	async fetchWithType(col) {
		this.additionalColumns({
			'contact': this.contact
		})
        return col.with({
            'lieu_type' : 'lieu_type'
        })
	}

	async transformWithType (lieu) {
		const lieu_type = await LieuTypeTransformer.process(lieu.lieu_type)

        return {
            'lieu_id'       : lieu.lieu_id,
            'lieu_label'    : lieu.lieu_label,
            'lieu_adresse'  : lieu.lieu_adresse,
            'lieu_cp'       : lieu.lieu_cp,
            'lieu_ville'    : lieu.lieu_ville,
			'lieu_type' 	: lieu_type,
			'lieu_contact'	: lieu.lieu_contact,
			'contact'		: lieu.contact,
			'lieu_default'	: lieu.lieu_default ? Vue.i18n.translate('global.oui') : Vue.i18n.translate('global.non'),
			'groupe' 		: lieu_type.lieutype_id == 1 ? this.getTypeTrad('residences') : this.getTypeTrad('autres'),
			'registry_enable': lieu.lieu_registry_enable
        }
	}

	async intra_location(lieu) {
		return this.db().t('intra_location')
			.then(table => {
				return table.where({
					intralocation_parent: lieu.lieu_id,
					intralocation_parent_type: 'lieu'
				})
			})
			.then(col => {
				return col.transform(new IntraLocationTransformer('withTypeAndChildren'))
			})
			.then(locations => {
				const format = (location => {
					location.root_lieu = { lieu_id: lieu.lieu_id, lieu_label: lieu.lieu_label },
					location.children.forEach(format)
				})

				locations.forEach(format)
				return locations
			})
	}

	async fetchWithIntraLocation(col) {
		this.additionalColumns({
			'intra_location': this.intra_location
		})

		return col
	}

	async transformWithIntraLocation(lieu) {
        return {
            'intralocation_id'      : lieu.lieu_id,
            'intralocation_label'   : lieu.lieu_label,
            'intralocation_horses'  : '',
            'intralocation_type'    : '',
            'intralocation_type_label': '',
			'intralocation_size' 	: '',
			'intralocation_parent'  : 0,
			'intralocation_order'  	: lieu.lieu_order,
			'intralocation_is_root' : true,
			'intralocation_default'	: lieu.lieu_default,
			'intralocation_img_url'	: lieu.lieu_img_url,
			'children'				: lieu.intra_location || [],
			'root_lieu'				: { lieu_id: lieu.lieu_id, lieu_label: lieu.lieu_label },
			'uuid'					: Common.getNegativeId(),
			'lieu'					: true
        }
	}
}

export default LieuTransformer
