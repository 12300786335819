import Transformer from './Transformer'
import PensionTransformer from './PensionTransformer'
import HorsePensionArticleTransformer from './HorsePensionArticleTransformer'

/**
 * Retourne une liste de
 * @param {Dexie.Collection} horse_pension
 */
export default class HorsePensionTransformer extends Transformer {

	async pension(item) {
		return this.db().t('pension')
		.then(table => {
			return table.where({
				pension_id: parseInt(item.horsepension_pension)
			})
		})
		.then(col => {
			return col.transform(new PensionTransformer())
		})
	}

	async pension_article(item) {
		return this.db().t('horse_pension_article')
		.then(table => {
			return table.where({
				horsepensionarticle_pension: parseInt(item.horsepension_id)
			})
		})
		.then(col => {
			return col.transform(new HorsePensionArticleTransformer())
		})
	}

	fetch (col) {
		this.additionalColumns({
			'pension': this.pension,
			'pension_article': this.pension_article
		})

		return col
	}

    async transform (horse_pension) {
        return {
			'horsepension_id': horse_pension.horsepension_id,
			'horsepension_horse': horse_pension.horsepension_horse,
			'horsepension_valide': horse_pension.horsepension_valide,
			'pension': horse_pension.pension[0],
			'pension_article': horse_pension.pension_article
        }
    }
}