import Transformer from './Transformer'
import ActeTransformer from './ActeTransformer'

import _defaults from 'lodash/defaults'

/**
 * Retourne une liste de
 * @param {Dexie.Collection} documents
 */
export class ActesDocumentLienTransformer extends Transformer {

	table = 'actes_document_lien'
	transactionTables = ['horse_actes']

	async actes(lien) {
		const actes = await this.fetchRelationship(lien, 'actesdocumentlien_actes')
		return ActeTransformer.process(actes, 'withActeTypeGroupe')
	}

	async document(lien) {
		const document = this.fetchRelationship(lien, 'actesdocumentlien_document')
		return _defaults(document, {
			document_id: 0,
			document_name: '',
			document_filename: '',
			document_date: new Date()
		})
	}

	async fetch(col) {
		this.additionalColumns({
			actes: this.actes,
			document: this.document
		})

		return col
	}

	async transform(lien) {
		return {
			'actesdocumentlien_id': lien.actesdocumentlien_id,
			'document_id': lien.document ? lien.document.document_id : null,
			'document_name': lien.document ? lien.document.document_name : 'nom',
			'document_filename': lien.document ? lien.document.document_filename : null,
			'document_date': lien.document ? lien.document.document_date : null,
			'actesdocumentlien_actes': lien.actes.actes_id
		}
	}
}

export default ActesDocumentLienTransformer
