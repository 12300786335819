import GroomyDB from '@/assets/js/dexie/GroomyDB'

let db = null

export default class CacheCleaner {

	static inst() {
		return new this
	}

	async init() {
		if(!db) {
			db = await GroomyDB.getInstance(false)
		}
	}

	async reset() {
		if (this.db() && await this.db().isOpen()) {
			await this.db().close()
		}

		db = null
	}

	db() {
		return db
	}

	onMutation(object_ids) {
		console.warn('Le cache n\'est pas vidé à la modification d\'items dans ' + this.constructor.name)
	}
}
