import Vue from "vue";
import Router from "vue-router"
import Routes from "./routes.js"
import UserMixin from '@/mixins/User'
import Toasts from '@/mixins/Toasts'
import _merge from "lodash/merge"
import { EventBus } from 'EventBus'

Vue.use(Router)
const router = new Router(Routes)
router.previous_route = null

router.beforeEach((to, from, next) => {
    router.previous_route = from
    return next()
})

router.beforeEach((to, from, next) => {
    const defaultMeta = {
		canDisplayNavMobile: true,
        noLoggedIn: false,
		needLoading: to.meta.noLoggedIn === true ? false : true,
		onlineOnly: false
    }
	const meta = _merge(defaultMeta, to.meta)

	// AUTHENTIFICATION
	const isLoggedIn = UserMixin.methods.checkLocal()

	// Si c'est une route qui n'a pas besoin de login alors qu'on est logged in
	if (isLoggedIn && meta.noLoggedIn && (meta.canAccessLoggedIn == false || meta.canAccessLoggedIn == undefined)) {
		return next({ name: 'cards' })
	}
	// Si c'est une route ou on doit etre log alors qu'on ne l'est pas
	else if(!isLoggedIn && !meta.noLoggedIn) {
		if(to.name != "Home")
		{
			return next({
				name: 'Home',
				query: {
					redirect: window.location.hash.replace('#/', '')
				}
			})
		}
	}

	// Rediriger lorsqu'une page n'est pas accessible hors ligne
	if (meta.onlineOnly && !window.navigator.onLine) {
		EventBus.$emit('App::failureToast', 'toast.online_only')
		if (from) {
			return next(from)
		}
		else {
			return next('horseListe')
		}
	}

	// LOADING - On a besoin du Loading avant le check des permissions car on doit charger la config
	if (!Vue.prototype.$storage.db && meta.needLoading) {
		if (to.name !== 'Loading') {
			return next({
				name: 'Loading',
				query: {
					redirect: to.fullPath
				}
			})
		}
		else {
			return next()
		}
	}

	// PERMISSIONS
	const route_needed_rights = meta.accessRights
	const has_recurAccessRights = to.matched.find(record => record.meta.recurAccessRights)
	const recur_access_rights_type = has_recurAccessRights ? has_recurAccessRights.meta.accessRights : ''

	// On regarde si les droits de cette route sont satisfaits par le niveau d'accès du user
	if(route_needed_rights && router.app.checkUserAccessRights(route_needed_rights)) {
		// continue
	}
	// Sinon, on regarde si la route a un parent avec droit d'accès définit récursif
	else if(recur_access_rights_type && router.app.checkUserAccessRights(recur_access_rights_type)) {
		// continue
	}
	// Sinon, si pas de droits spécifiques, on accepte la route
	else if(!route_needed_rights && !recur_access_rights_type) {
		// continue
	}
	// Si les droits ne sont pas satisfaits, on retourne à la route précédente
	else {
		// Si on est connecté c'est qu'on a vraiment pas les droits
		if (isLoggedIn) {
			Toasts.methods.infoToast('toast.info_page_interdite')
			return next(false)
		}
		// Si on est pas connecté on se connecte d'abord
		else {
			if(to.name != "Home") {
				return next({
					name: 'Home',
					query: {
						redirect: to.fullPath
					}
				})
			}
		}
	}

    return next()
})

export function resetRouter () {
    const newRouter = new Router(Routes)
    router.matcher = newRouter.matcher
}

export default router
