import RootCommon from 'GroomyRoot/assets/js/common.js'
import GroomyDB from '@/assets/js/dexie/GroomyDB'
import Vue from 'vue'

export default class Common extends RootCommon
{

	static log = {}
	static logRun = false

	static async getCurrency(currencyCode) {
		if (!Common.cachedCurrencies[currencyCode]) {
			const db = await GroomyDB.getInstance(false)
			Common.cachedCurrencies[currencyCode] = await db.t('currency')
			.then((table) => {
				return table.where('currency_code').equals(currencyCode)
			})
			.then((col) => {
				return col.first()
			})
		}

		return Common.cachedCurrencies[currencyCode]
	}

	/* Retourne le cache ou initialisse la valeur dans _cache_data d'une donnée */
	static async storage(key, func) {
		let value = await Vue.prototype.$storage.db.t('_cache_data')
		.then(table => {
			return table.where('key').equals(key)
		})
		.then((col) => {
			return col.first()
		})

		if(value === null || value === undefined || (value && new Date('2022-03-31 09:00') > new Date(value.date))) {
			value = await func()

			await Vue.prototype.$storage.db.t('_cache_data')
			.then(table => {
				return table.put({
					key: key,
					value: value,
					date: new Date()
				})
			})
		}
		else{
			value = value.value
		}
		
		return value
	}

	/* Delete le cache d'une donnée dans _cache_data */
	static async storageDelete(key) {
		// récupération de GroomyDB car contexte différent
		let db = await GroomyDB.getInstance(false)
		await db.t('_cache_data')
			.then(table => {
			    return table.where('key').equals(key)
			})
			.then(col => {
			    return col.delete()
			})
	}

	static logStart(){
		this.logRun = true
	}

	static logStop(){
		this.logTrace()
		this.logClear()
		this.logRun = false
	}

	static logClear(){
		this.log = {}
	}

	static logTrace(){
		console.trace()
		console.log(this.log)
	}

	static getPrimaryMenus() {
		return [
			{id: 'horseListe', name: Vue.i18n.translate('shortcuts.horseListe')},
			{id: 'planning', name: Vue.i18n.translate('shortcuts.planning')},
			{id: 'acteListe', name: Vue.i18n.translate('shortcuts.acteListe')},
			{id: 'mouvementListe', name: Vue.i18n.translate('shortcuts.mouvementListe')},
			{id: 'mouvementResidence', name: Vue.i18n.translate('shortcuts.mouvementResidence')},
			{id: 'mediatheque', name: Vue.i18n.translate('shortcuts.mediatheque')},
			{id: 'etatJumenterie', name: Vue.i18n.translate('shortcuts.etatJumenterie')},
			{id: 'ContractList', name: Vue.i18n.translate('shortcuts.ContractList')},
			{id: 'PlanningMonte', name: Vue.i18n.translate('shortcuts.PlanningMonte')},
			{id: 'tiersListe', name: Vue.i18n.translate('shortcuts.tiersListe')},
			{id: 'invoiceList', name: Vue.i18n.translate('shortcuts.invoiceList')}
		]
	}

	static getAllDateFormat() {
		return [
			{code: 'd-m-y', label: Vue.i18n.translate("format_date.dmy")},
            {code: 'm-d-y', label: Vue.i18n.translate("format_date.mdy")},
            {code: 'd/m/y', label: Vue.i18n.translate("format_date.dmyslash")},
            {code: 'm/d/y', label: Vue.i18n.translate("format_date.mdyslash")},
            {code: 'd-m-Y', label: Vue.i18n.translate("format_date.dmfully")},
            {code: 'm-d-Y', label: Vue.i18n.translate("format_date.mdfully")},
            {code: 'd/m/Y', label: Vue.i18n.translate("format_date.dmfullyslash")},
            {code: 'm/d/Y', label: Vue.i18n.translate("format_date.mdfullyslash")}
        ]
	}
}
