import Transformer from './Transformer'
import SeasonMareStallionTransformer from '@/assets/js/dexie/transformers/SeasonMareStallionTransformer.js'
import HorseTransformer from '@/assets/js/dexie/transformers/HorseTransformer.js'
import _orderBy from 'lodash/orderBy'

/**
 * Retourne une liste de
 * @param {Dexie.Collection} season
 */
export default class SeasonMareTransformer extends Transformer {

	async seasonmarestallion(seasonmare) {
		return this.db().t('season_mare_stallion')
		.then(table => {
			return table.where('seasonmarestallion_seasonmare').equals(seasonmare.seasonmare_id)
			// .and((sms) => (sms.seasonmarestallion_porteuse === null))
			.or('seasonmarestallion_porteuse').equals(seasonmare.seasonmare_id)
			.toArray()
		})
	}

    async transform (seasonMare) {
        return {
            'seasonmare_id'             : seasonMare.seasonmare_id,
            'seasonmare_season'         : seasonMare.seasonmare_season,
            'seasonmare_horse'          : seasonMare.seasonmare_horse,
            'seasonmare_commentary'     : seasonMare.seasonmare_commentary,
        }
    }

    async transformLight (seasonMare) {
        return {
            'seasonmare_id'             : seasonMare.seasonmare_id,
            'seasonmare_season'         : seasonMare.seasonmare_season,
            'seasonmare_horse'          : seasonMare.seasonmare_horse,
            'seasonmare_commentary'     : seasonMare.seasonmare_commentary,
            'seasonmare_status'         : seasonMare.seasonmare_status,
            'seasonmare_sanitary'       : seasonMare.seasonmare_sanitary
        }
    }

    async fetchWithSeasonMareStallions (col) {
		this.additionalColumns({
			seasonmarestallion: this.seasonmarestallion
		})

        return col.with({
            'season'                    : 'seasonmare_season',
            'horse'                     : 'seasonmare_horse',
        })
    }

    async transformWithSeasonMareStallions (seasonMare) {
        return {
            'seasonmare_id'             : seasonMare.seasonmare_id,
            'seasonmare_id_absolute'    : Math.abs(seasonMare.seasonmare_id),
            'seasonmare_season'         : seasonMare.seasonmare_season,
            'seasonmare_horse'          : seasonMare.seasonmare_horse,
            'seasonmare_commentary'     : seasonMare.seasonmare_commentary,
            'season'                    : seasonMare.season,
            'horse'                     : seasonMare.horse,
            'seasonmarestallion'        : await SeasonMareStallionTransformer.process(seasonMare.seasonmarestallion),
            'seasonmare_status'         : seasonMare.seasonmare_status,
            'seasonmare_sanitary'       : seasonMare.seasonmare_sanitary
        }
	}
	
	async sortWithSeasonMareStallions (lines) {
		return _orderBy(lines, ['seasonmare_id_absolute'], ['asc'])
	}

    async fetchWithSeasonMareStallionsWithoutArchive (col) {
        this.additionalColumns({
            seasonmarestallion: this.seasonmarestallion
        })

        return col.with({
            'season'                    : 'seasonmare_season',
            'horse'                     : 'seasonmare_horse',
        })
    }

    async transformWithSeasonMareStallionsWithoutArchive (seasonMare) {
        seasonMare.seasonmarestallion = seasonMare.seasonmarestallion.filter(sms => !sms.seasonmarestallion_archive)

        return {
            'seasonmare_id'             : seasonMare.seasonmare_id,
            'seasonmare_id_absolute'    : Math.abs(seasonMare.seasonmare_id),
            'seasonmare_season'         : seasonMare.seasonmare_season,
            'seasonmare_horse'          : seasonMare.seasonmare_horse,
            'seasonmare_commentary'     : seasonMare.seasonmare_commentary,
            'season'                    : seasonMare.season,
            'horse'                     : seasonMare.horse,
            'seasonmarestallion'        : await SeasonMareStallionTransformer.process(seasonMare.seasonmarestallion),
            'seasonmare_status'         : seasonMare.seasonmare_status,
            'seasonmare_sanitary'       : seasonMare.seasonmare_sanitary
        }
    }
    
    async sortWithSeasonMareStallionsWithoutArchive (lines) {
        return _orderBy(lines, ['seasonmare_id_absolute'], ['asc'])
    }

    async fetchSeasonMareOnlyHorse (col) {
        return col.with({
            'horse'                     : 'seasonmare_horse',
        })
    }

    async transformSeasonMareOnlyHorse (seasonMare) {
        return {
            'seasonmare_id'             : seasonMare.seasonmare_id,
            'seasonmare_season'         : seasonMare.seasonmare_season,
            'seasonmare_horse'          : seasonMare.seasonmare_horse,
            'seasonmare_commentary'     : seasonMare.seasonmare_commentary,
            'horse'                     : await HorseTransformer.process(seasonMare.horse),
        }
    }


    async fetchSeasonMareOnlyHorseSeason (col) {
        return col.with({
            'horse'                     : 'seasonmare_horse',
            'season'                    : 'seasonmare_season',
        })
    }

    async transformSeasonMareOnlyHorseSeason (seasonMare) {
        return {
            'seasonmare_id'             : seasonMare.seasonmare_id,
            'seasonmare_season'         : seasonMare.seasonmare_season,
            'seasonmare_horse'          : seasonMare.seasonmare_horse,
            'seasonmare_commentary'     : seasonMare.seasonmare_commentary,
            'horse'                     : await HorseTransformer.process(seasonMare.horse, 'heavy'),
            'season'                    : seasonMare.season,
        }
    }

    async fetchSeasonMareWithStatus (col) {
		this.additionalColumns({
			seasonmarestallion: this.seasonmarestallion
		})

        return col.with({
            'horse'                     : 'seasonmare_horse',
        })
    }
    
    async transformSeasonMareWithStatus (seasonMare) {
        return {
            'seasonmare_id'             : seasonMare.seasonmare_id, 
            'seasonmarestallion'        : await SeasonMareStallionTransformer.process(seasonMare.seasonmarestallion),
            'horse'                     : await HorseTransformer.process(seasonMare.horse, 'withResidLieu'),
        }
    }

    async fetchSeasonMareWithTranslatedStatus (col) {
        this.additionalColumns({
            seasonmarestallion: this.seasonmarestallion
        })

        return col.with({
            'horse'                     : 'seasonmare_horse',
        })
    }
    
    async transformSeasonMareWithTranslatedStatus (seasonMare) {
        return {
            'seasonmare_id'             : seasonMare.seasonmare_id, 
            'seasonmarestallion'        : await SeasonMareStallionTransformer.process(seasonMare.seasonmarestallion, 'withTranslatedStatus'),
            'horse'                     : await HorseTransformer.process(seasonMare.horse, 'withResidLieu'),
        }
    }
}
