import Transformer from './Transformer'

/**
 * Retourne une liste de
 * @param {Dexie.Collection} season
 */
export default class FonctionTransformer extends Transformer {

    async transform (fonction) {
        return {
            'fonction_id'               : fonction.fonction_id,
            'fonction_label'            : fonction.fonction_label,
            'fonction_code'             : fonction.fonction_code
        }
    }
}
