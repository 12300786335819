const state = {
	/**
	 * Filtres de dates actifs sur les tableaux
	 * {
	 * 	tableId: {
	 * 		begin: Date,
	 * 		end: Date
	 * 	}
	 * }
	 */
	dateFilters: {} 
}

const mutations = {
    setTableDate(state, params) {
		const tableId = params.tableId

		state.dateFilters[tableId] = {
			begin: params.beginDate,
			end: params.endDate
		}
	},
	
    deleteTableDate(state, tableId) {
		delete state.dateFilters[tableId]
    }
}

const getters = {
	getTableDate: (state) => (tableId) => {
		return state.dateFilters[tableId]
	}
}

export default {
    namespaced: true,
    state,
	mutations,
	getters
}
