import Transformer from './Transformer'
import TiersHorsePartTransformer from './TiersHorsePartTransformer'
import HorseTransformer from './HorseTransformer'
import TierTransformer from './TierTransformer'
import FonctionTransformer from './FonctionTransformer'
import TiersContactTransformer from './TiersContactTransformer'
import _orderBy from 'lodash/orderBy'

export default class TiersHorseTransformer extends Transformer {

	table = 'tiers_horse'

	fetch(col) {
		this.additionalColumns({
			'horse': this.horse
		})

		return col
	}

    transform(tiers_horse) {
        return {
			'tiershorse_id': tiers_horse.tiershorse_id,
			'tiershorse_horse': tiers_horse.tiershorse_horse,
			'tiershorse_tiers': tiers_horse.tiershorse_tiers,
			'tiershorse_valide': tiers_horse.tiershorse_valide,
			"horse": tiers_horse.horse
        }
	}

	tiers_horse_part(item) {
		return this.fetchRelationship(item, 'tiers_horse_part')
		.then(tiers_horse_parts => {
			return TiersHorsePartTransformer.process(tiers_horse_parts)
		})
	}

	horse(item) {
		return this.fetchRelationship(item, 'tiershorse_horse')
		.then(horses => {
			return HorseTransformer.process(horses, 'light')
		})
	}

	fetchWithTiersHorsePartAndHorse(col) {
		this.additionalColumns({
			'tiers_horse_part': this.tiers_horse_part,
			'horse': this.horse
		})

		return col
	}

	async transformWithTiersHorsePartAndHorse(tiers_horse) {
        return {
			'tiershorse_part': tiers_horse.tiers_horse_part,
			'tiershorse_id': tiers_horse.tiershorse_id,
			'horse': tiers_horse.horse,
			'tiershorse_tiers': tiers_horse.tiershorse_tiers,
			'tiershorse_valide': tiers_horse.tiershorse_valide
        }
	}

	tiers_horse_current_part_all(item) {
		return this.fetchRelationship(item, 'tiers_horse_part')
		.then(tiers_horse_parts => {
			return TiersHorsePartTransformer.process(tiers_horse_parts)
		})
	}

	tiers_horse_current_part(item) {
		return this.db().t('tiers_horse_part')
		.then(table => {
			const now = new Date().toISOString()
			return table.where({
				tiershorsepart_tiershorse: parseInt(item.tiershorse_id)
			})
			// .filter((part) => {
			// 	return part.tiershorsepart_date.toISOString() <= now
			// })
			.toArray()
		})
		.then(tiershorse_parts => {
			tiershorse_parts.forEach((part) => {
				part.id_absolute = Math.abs(part.tiershorsepart_id)
			})
			return tiershorse_parts
		})
		.then(tiershorse_parts => {
			return _orderBy(tiershorse_parts, ['tiershorsepart_date', 'id_absolute'], ['desc', 'desc'])
		})
		.then(tiershorse_parts => {
			if (tiershorse_parts.length > 0) {
				return tiershorse_parts[0]
			}
			else {
				return null
			}
		})
		.then(tiershorse_part => {
			return TiersHorsePartTransformer.process(tiershorse_part)
		})
	}

	fetchWithTiersHorseCurrentPartAndHorse(col) {
		this.additionalColumns({
			'tiers_horse_current_part': this.tiers_horse_current_part,
			'horse': this.horse
		})

		return col
	}

	async transformWithTiersHorseCurrentPartAndHorse(tiers_horse) {
        return {
			'tiers_horse_part': tiers_horse.tiers_horse_current_part,
			'tiershorse_id': tiers_horse.tiershorse_id,
			'horse': await HorseTransformer.process(tiers_horse.horse, 'withResidenceAndLastMouvement'),
			'tiershorse_horse': tiers_horse.tiershorse_horse,
			'tiershorse_tiers': tiers_horse.tiershorse_tiers,
			'tiershorse_valide': tiers_horse.tiershorse_valide
        }
	}

	tiers(item) {
		return this.fetchRelationship(item, 'tiershorse_tiers')
		.then(tiers => {
			return TierTransformer.process(tiers, 'withPhone')
		})
	}

	fetchWithTiersHorseCurrentPartAndTiers(col) {
		this.additionalColumns({
			'tiers_horse_current_part': this.tiers_horse_current_part,
			'tiers': this.tiers
		})

		return col
	}

	async transformWithTiersHorseCurrentPartAndTiers(tiers_horse) {
        return {
			'tiers_horse_part': tiers_horse.tiers_horse_current_part,
			'tiershorse_id': tiers_horse.tiershorse_id,
			'tiershorse_dps': tiers_horse.tiershorse_dps,
			'tiers': tiers_horse.tiers,
        }
	}

	tiers_with_phone(item) {
		return this.fetchRelationship(item, 'tiershorse_tiers')
		.then(tiers => {
			return TierTransformer.process(tiers, 'withPhone')
		})
	}

	fetchWithTiersHorseCurrentPartAndTiersHeavy(col) {
		this.additionalColumns({
			'tiers_horse_current_part': this.tiers_horse_current_part,
			'tiers_with_phone': this.tiers_with_phone
		})

		return col
	}

	async transformWithTiersHorseCurrentPartAndTiersHeavy(tiers_horse) {
        return {
			'tiers_horse_part': tiers_horse.tiers_horse_current_part,
			'tiershorse_id': tiers_horse.tiershorse_id,
			'tiershorse_dps': tiers_horse.tiershorse_dps,
			'tiers': tiers_horse.tiers_with_phone,
        }
	}

	fetchWithTiersHorsePartAndTiers(col) {
		this.additionalColumns({
			'tiers_horse_current_part_all': this.tiers_horse_current_part_all,
			'tiers': this.tiers
		})

		return col
	}

	async transformWithTiersHorsePartAndTiers(tiers_horse) {
        return {
			'tiers_horse_part': tiers_horse.tiers_horse_current_part_all,
			'tiershorse_id': tiers_horse.tiershorse_id,
			'tiershorse_dps': tiers_horse.tiershorse_dps,
			'tiershorse_fonction': tiers_horse.tiershorse_fonction,
			'tiers': tiers_horse.tiers,
        }
	}

	tiers_withphone(item) {
		return this.fetchRelationship(item, 'tiershorse_tiers')
		.then(tiers => {
			return TierTransformer.process(tiers, 'withPhone')
		})
	}

	fonction(item) {
		return this.fetchRelationship(item, 'tiershorse_fonction')
		.then(fonction => {
			return FonctionTransformer.process(fonction)
		})
	}

	tierscontact(item) {
		return this.fetchRelationship(item, 'tiershorse_tierscontact')
		.then(tierscontact => {
			return TiersContactTransformer.process(tierscontact, 'withContactAndFonction')
		})
	}

	fetchWithFonctionAndTiersContact(col) {
		this.additionalColumns({
			'tiers_withphone': this.tiers_withphone,
			'fonction': this.fonction,
			'tierscontact': this.tierscontact
		})

		return col
	}

	async transformWithFonctionAndTiersContact(tiers_horse) {
        return {
			'tiershorse_id': tiers_horse.tiershorse_id,
			'tiers': tiers_horse.tiers_withphone,
			'fonction': tiers_horse.fonction,
			'tierscontact': tiers_horse.tierscontact ? tiers_horse.tierscontact : null
        }
	}

	fetchWithHorseAndFonctionAndTiersContact(col) {
		this.additionalColumns({
			'tiers_withphone': this.tiers_withphone,
			'fonction': this.fonction,
			'tierscontact': this.tierscontact,
			'horse': this.horse
		})

		return col
	}

	async transformWithHorseAndFonctionAndTiersContact(tiers_horse) {
        return {
			'tiershorse_id': tiers_horse.tiershorse_id,
			'tiers': tiers_horse.tiers_withphone,
			'fonction': tiers_horse.fonction,
			'tierscontact': tiers_horse.tierscontact ? tiers_horse.tierscontact : null,
			'horse': await HorseTransformer.process(tiers_horse.horse, 'withResidLieu')
        }
	}
}