import Vue from 'vue'
import { beforeCreateOrUpdate, beforeDelete, beforeDeleteRange } from './beforeMutate'
import { afterCreateOrUpdate, afterDelete, afterDeleteRange } from './afterMutate'
import Common from '@/assets/js/common'

export default {
    stack: "dbcore",
    name: "DBCoreMiddleware",
    level: 100,
    create (downlevelDatabase) {
        return {
            ...downlevelDatabase,

            table (tableName) {
                // Call default table method
                const downlevelTable = downlevelDatabase.table(tableName)

				var logTime
                // Derive your own table from it:
                return {
                    // Copy default table implementation:
                    ...downlevelTable,
					
                    // Override the mutate method:
                    mutate: async req => {
                        // Copy the request object
						let request = {...req}

						if (tableName.startsWith('_')) {
							return downlevelTable.mutate(request)
						}

                        // Update primary and foreign keys with replicated before saving
						switch(request.type) {
							case 'add':
							case 'put':
								request = await beforeCreateOrUpdate(tableName, request)
								break
							case 'delete':
								request = beforeDelete(tableName, request)
								break
							case 'deleteRange':
								request = beforeDeleteRange(tableName, request)
								break
						}

                        // call downlevel mutate
                        const response = await downlevelTable.mutate(request)

						switch(request.type) {
							case 'add':
							case 'put':
								await afterCreateOrUpdate(tableName, request)
								break
							case 'delete':
								await afterDelete(tableName, request)
								break
							case 'deleteRange':
								afterDeleteRange(tableName, request)
								break
						}

                        return response
                    },

                    async get(req) {
                        let request = {...req}

						if (Vue.prototype.$sync) {
							request.key = Vue.prototype.$sync.replaceWithReplicated(tableName, request.key)
						}

                        const response = await downlevelTable.get(request)

                        return response
                    },

                    async getMany(req) {
						let request = {...req}

						
						if (Vue.prototype.$sync) {
							request.keys = Vue.prototype.$sync.replaceWithReplicated(tableName, request.keys)
						}

                        const response = await downlevelTable.getMany(request)

                        return response
                    },


					async isLog(){
						if(!Common.logRun){
							return false
						}				
					
						if (tableName.startsWith('_')){
							return false
						}

						return true
					},

					async logBegin(request){
						if(! await this.isLog()){
							return
						}

						if(!Common.log.nb){
							Common.log.nb = 0
							Common.log.time = 0
						}


						if(!Common.log.table){
							Common.log.table = {}
							Common.log.tableTime = {}
							Common.log.tableDetail = {}
						}

						if(!Common.log.table[tableName]){
							Common.log.table[tableName] = 0
							Common.log.tableTime[tableName] = 0
							Common.log.tableDetail[tableName] = []
						}

						this.logTime = Date.now()
					},

					stackFiltre(stack){
						var newStack = []

						stack.forEach( str => {
							
							if(str.indexOf('dexie') !== -1){
								return 
							}

							if(str.indexOf('runtime') !== -1){
								return 
							}

							if(str.indexOf('asyncToGenerator') !== -1){
								return 
							}

							if(str.indexOf('vue-loader') !== -1){
								return 
							}

							if(str.indexOf('_callee') !== -1){
								return 
							}
							if(str == ""){
								return 
							}
							

							newStack.push(str)
						})

						return newStack
					},

					async logEnd(request){
						
						if(!await this.isLog()){
							return
						}		
						
						const requestTime = Date.now() - this.logTime
						
						Common.log.nb++
						Common.log.time += requestTime
					
						Common.log.table[tableName]++
						Common.log.tableTime[tableName]+=requestTime

						let log = request.query
						log.time = requestTime						
						log.stack = this.stackFiltre(new Error().stack.split("\n"))

						Common.log.tableDetail[tableName].push(log)

					},

                    async query(req) {
						let request = {...req}

						this.logBegin(request)

						if (!tableName.startsWith('_')
							&& request.query.range.type === 1 // DBCoreRangeType.Equal
							&& Vue.prototype.$sync
						) {
							// Que ce soit un compound index ou pas on transforme
							// les valeurs en Array pour tout traiter de la meme façon
							const isCompoundIndex = Array.isArray(request.query.index.keyPath)
							const keyPaths = isCompoundIndex ? request.query.index.keyPath : [request.query.index.keyPath]
							const rangeValues = isCompoundIndex ? request.query.range.lower : [request.query.range.lower]
							const table = await Vue.prototype.$storage.db.t(tableName)

							// Pour chaque clé on regarde si c'est une PK ou une FK
							keyPaths.forEach((keyPath, idx) => {
								const isPrimaryKey = request.query.index.isPrimaryKey
								const foreignKey = table.schema.foreignKeys.find(fk => (keyPath.indexOf(fk.index) !== -1))
								const isForeignKey = !!foreignKey

								if (isPrimaryKey) {
									rangeValues[idx] = Vue.prototype.$sync.replaceWithReplicated(tableName, rangeValues[idx])
								}
								else if (isForeignKey) {
									rangeValues[idx] = Vue.prototype.$sync.replaceWithReplicated(foreignKey.targetTable, rangeValues[idx])
								}
							})

							// On utilise nos valeurs pour modifier la requete initiale
							// Comme c'est un Equals, le lower et le upper ont la meme valeur
							request.query.range.lower = isCompoundIndex ? rangeValues : rangeValues[0]
							request.query.range.upper = isCompoundIndex ? rangeValues : rangeValues[0]
						}

                        const response = await downlevelTable.query(request)

						this.logEnd(request)

                        return response
                    }
                }
            }
        }
    }
}
