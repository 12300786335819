import Vue from 'vue'
import Transformer from './Transformer'

/**
 * Retourne une liste de
 * @param {Dexie.Collection} lieu_type
 */
export default class LieuTypeTransformer extends Transformer {

	table = 'lieu_type'

	getTypeTrad(label) {
		return Vue.i18n.translate('lieu.lieu_type.'+label)
	}

    transform (lieutype) {
        return {
            'lieutype_id'          : lieutype.lieutype_id,
            'lieutype_label'       : this.getTypeTrad(lieutype.lieutype_label),
            'lieutype_key_trad'    : lieutype.lieutype_label,
            'lieutype_visible'     : lieutype.lieutype_visible,
            'lieutype_code'        : lieutype.lieutype_code,
        }
	}
}