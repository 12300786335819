import Vue from 'vue'
import Vuex from 'vuex'
import root from './root'
import sync from './modules/sync'
import nav from './modules/nav'
import act_trigger from './modules/act_trigger'
import mvt_trigger from './modules/mvt_trigger'
import user from './modules/user'
import mobile from './modules/mobile'
import table from './modules/table'

Vue.use(Vuex)

export default new Vuex.Store({
    modules: {
        sync,
        nav,
        act_trigger,
        mvt_trigger,
		user,
		mobile,
		table
    },
    ...root
})
