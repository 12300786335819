import Transformer from './Transformer'
import HorseTransformer from './HorseTransformer'
import ActeTransformer from './ActeTransformer'

import _forEach from 'lodash/forEach'
import _groupBy from 'lodash/groupBy'

/**
 * Retourne une liste de
 * @param {Dexie.Collection} actes
 */
export class NoteTransformer extends Transformer {
	table = 'horse_note'
	needAccessRights = true

	files(item) {
		return this.db().t('files')
		.then(table => {
			return table.where({
				files_parentid: parseInt(item.note_id),
				files_parenttype: 'App\\Model\\Note'
			})
		})
		.then(col => {
			return col.toArray()
		})
	}

	async note_files_types(item) {
		const files = (item.files ? item.files : (await this.files(item))) || []
		const types = files.map(f => (f.files_mimetype))
		const grouped = _groupBy(types)
		
		const fonts = {
			'image': ['fal', 'image'],
			'video': ['fal', 'film'],
			'audio': ['fal', 'volume-up'],
			'application': ['fal', 'file-alt'],
			'embedded': ['fal', 'link']
		}
		
		let quantified_types = {}
		let quantified_fonts = {}

		_forEach(grouped, (occurences, key) => {
			quantified_types[key] = occurences.length

			const font = fonts[key]
			quantified_fonts[key] = { font, occurences: occurences.length }
		})
		
		return {
			types: quantified_types,
			type_fonts: quantified_fonts
		}
	}

	static synthetize(items) {
		const types = items.map(n => (n.note_files_types ? n.note_files_types.types : ""))
		const type_fonts = items.map(n => (n.note_files_types ? n.note_files_types.type_fonts : ""))
		
		let quantified_types = {}
		let quantified_fonts = {}

		// Tous les types de chaque note de l'acte en cours
		types.forEach(note_types => {
			// On parcourt les types de la note
			Object.keys(note_types).forEach(type_key => {
				const occurences = note_types[type_key]
				if(!quantified_types[type_key]) quantified_types[type_key] = occurences
				else quantified_types[type_key] += occurences
			})
		})

		type_fonts.forEach(note_type_font => {
			Object.keys(note_type_font).forEach(type_key => {
				const occurences = note_type_font[type_key].occurences
				if(!quantified_fonts[type_key]) quantified_fonts[type_key] = note_type_font[type_key]
				else quantified_fonts[type_key].occurences += occurences
			})
		})
		
		return {
			types: quantified_types,
			type_fonts: quantified_fonts
		}
	}

	transform(item) {	
		return {
			note_id: item.note_id,
			note_title: item.note_title,
			note_comment: item.note_comment,
			note_type: item.note_type
		}
	}

	async fetchWithMediaTypes(col) {
		this.additionalColumns({
			'files': this.files
		})

		return col.with({
			horse: 'note_horse',
			acte:  'note_acte',
		})
	}

	async transformWithMediaTypes(item) {
		return {
			note_id		: item.note_id,
			note_title	: item.note_title,
			note_comment: item.note_comment,
			note_date	: item.note_date,
			note_type	: item.note_type,
			horse		: await HorseTransformer.process(item.horse, 'withTiersAndCurrentPart'),
			acte		: await ActeTransformer.process(item.acte, 'lite'),
			files		: item.files,
			note_synthetize	: await this.note_files_types(item)
		}
	}
}

export default NoteTransformer
