const SYNC_STATES = {
    STARTED: 'STARTED',
    OFFLINE: 'OFFLINE',
    STOPPED: 'STOPPED',
    ERROR: 'ERROR'
}

const PROGRESS_STATES = {
    SENDING: 'SENDING',
    CANCELLED: 'CANCELLED',
    COMPLETED: 'COMPLETED'
}

const RECORD_STATES = {
    WAITING: 'WAITING',
    SENDING: 'SENDING'
}

const FILE_TYPES = {
    ON_DEMAND: 'ondemand',
    AUTOMATIC: 'automatic'
}

const MEDIA_STATES = {
	DOWNLOADED: 'DOWNLOADED',
	NEED_UPLOAD: 'NEED_UPLOAD',
	UPLOADING: 'UPLOADING'
}

export {
    SYNC_STATES,
    PROGRESS_STATES,
    RECORD_STATES,
	FILE_TYPES,
	MEDIA_STATES
}
