import Constants from 'Constants'
import { EventBus } from 'EventBus'
import Common from '@/assets/js/common.js'
import Queue from '@/assets/js/utils/queue'

import ConfigMixin from "@/mixins/Config.js"
import Config from '@/mixins/Config.js'

import SyncWorker from 'comlink-loader!@/assets/js/sync/DataProcessor.js'
import { SYNC_STATES, PROGRESS_STATES, RECORD_STATES, FILE_TYPES, MEDIA_STATES } from '@/assets/js/sync/states'
import * as Comlink from 'comlink'
import GroomyDB from '@/assets/js/dexie/GroomyDB.js'
import { resetCachedCleaners } from '@/assets/js/cache'

import _cloneDeep from 'lodash/cloneDeep'
import _debounce from 'lodash/debounce'
import _defaults from 'lodash/defaults'
import _merge from 'lodash/merge'

export default {
    install (Vue, { store }) {
        const SyncPlugin = {
            /*************
             * VARIABLES *
             *************/
            /**
             * Timestamp de dernière synchro des Required et Optionnal
             */
			_lastSync: {},
			/**
             * Params de pagination de la dernière synchro des Required
             */
            _lastRequiredState: null,
			/**
             * Params de pagination de la dernière synchro des données Optional
             */
            _lastOptionnalState: null,
            /**
             * Indique si un export de la BDD a été demandé par l'API
             */
            _needExport: false,
            /**
             * ID du navigateur qui sert de référence pour la synchro
             */
            _deviceId: null,
            /**
             * ID du timer window.setInterval
             */
            _timerId: null,
            /**
             * Fonctions d'annnulation des requetes axios
             */
            _cancelFns: {},
            /**
             * IDs replicated pour mettre à jour les clés
             * étrangères et primaires à la volée
             */
			_replicated: {},
			/**
             * Worker's class designed to handle
             * synchronized data
             */
			_processor: null,
			/**
			 * Elements en attente d'insertion dans _sync_temp
			 */
			_pendingRecords: [],

			_debouncedForce: () => {},

            _queue : new Queue(),

			_handleDataQueue : new Queue(),

			/**
			* websocket permetant de forcer rapidement la réplication de synchro d'un autre client de la même licence
			*/
			_ws: null,

			PACKET_SIZE: 750,

            /********
             * MAIN *
             ********/
            /**
             * Initialise le plugin
             */
            async init() {
				this._lastSync.required = this.getLastSync('required')
				this._lastRequiredState = this.getLastRequiredState()
                this._lastSync.optionnal = this.getLastSync('optionnal')
				this._lastOptionnalState = this.getLastOptionnalState()
                this._needExport = this._getNeedExport()
				this._replicated = this._getReplicated()
				this._debouncedForce = _debounce(async () => {
					if (store.state.sync.progressState === PROGRESS_STATES.COMPLETED) {
						return this.force(true)
					}
				}, 500)

				// Si des lignes n'ont pas pu etre insérées dans _sync_temp à la dernière utilisation
				this._pendingRecords = this._getPendingRecords()
				await this.persistPendingRecords()
				this._setPendingRecords(this._pendingRecords)

				const inst = new SyncWorker()
				this._processor = await new inst.DataProcessor(
					Comlink.proxy(this._setSyncActualModelsState)
				)
				await this._processor.init()

				// TODO: A supprimer en 09/2021 lorsqu'on considèrera toutes les lignes renvoyées
				// Décaler l'envoi des lignes non synchronisées pour ne pas ralentir le chargement
				setTimeout(() => {
					this._processor.syncMissingRows()
				}, 10000)

				this.runFromScratchIfNeeded()

				window.addEventListener('focus', () => {
 					//this.onFocus()
				})
				window.addEventListener('beforeunload', () => {
 					this.onBeforeUnload()
				})

				this.websocketInit()
            },
            /**
             * Rends la synchronisation clean, notamment au delog/switch de licence
             */
            async reset() {
				if(this._processor) {
					await this._processor.reset()
					this._processor = null
				}

				resetCachedCleaners()

                await this.stop()
            },

            /**
             * Démarre la tâche de synchronisation
             */
            start() {
				store.commit('sync/setState', SYNC_STATES.STARTED)
				this._getSqliteDatabaseExport()

                this._timerId = setInterval(() => {
					if (store.state.sync.progressState === PROGRESS_STATES.COMPLETED
						&& window.navigator.onLine
						&& document.hasFocus()
					) {
                        this.force(true)
                    }
                }, process.env.VUE_APP_SYNC_INTERVAL || 30000)

                return this.force(true)
            },
            /**
             * Arrête la tâche de synchronisation
             */
            async stop() {
				window.clearInterval(this._timerId)
				this._timerId = null
                this._cancelRequests()

				store.commit('sync/setState', SYNC_STATES.STOPPED)
				if (store.state.sync.progressState !== PROGRESS_STATES.COMPLETED) {
					store.commit('sync/setProgressState', PROGRESS_STATES.CANCELLED)
					await Common.waitUntil(() => (store.state.sync.progressState === PROGRESS_STATES.COMPLETED))
				}
            },

            /********
             * DATA *
             ********/

            /**
             * Fonction principale pour la gestion de la synchronisation
             */
            async force(withOptional=false, onlyOptional=false) {
				// On vérifie si il est en ligne ou si l'onglet est actif
                if (!window.navigator.onLine) {
                    store.commit('sync/setState', SYNC_STATES.OFFLINE)
                    return
				}

                if (store.state.sync.progressState !== PROGRESS_STATES.COMPLETED) {
                    this._cancelRequests()
                    await Common.waitUntil(() => (store.state.sync.progressState === PROGRESS_STATES.COMPLETED))
                }

                try {
                    store.commit('sync/setProgressState', PROGRESS_STATES.SENDING)

                    if(!onlyOptional) {
						this._lastRequiredState = this.getLastRequiredState()
						await this._run('required', this._lastRequiredState)
					}

                    if (withOptional) {
						this._lastOptionnalState = this.getLastOptionnalState()
						await this._run('optionnal', this._lastOptionnalState)
					}

                    this._onSyncSuccess()
                } catch(e) {
					if (e.message !== 'Synchronisation cancelled') {
						// this._onSyncError(e)
						console.error(e);
					}
                } finally {
                    this._onSyncEnd()
                }
			},
			/**
			 * Synchronisation montante des médias
			 */
			async _runMedias() {
				const medias = await this._retrieveMedias()
				const table = await Vue.prototype.$storage.db.t('_files')

				await Common.asyncForEach(
					medias,
					async (media) => {
						try {
							await table.update(media.filename, {
								state: MEDIA_STATES.UPLOADING
							})

							const url = Constants.SYNC_FILE_API
							const formData = new FormData()
							let mediaContent = media.content

							if(!(mediaContent instanceof Blob)) {
								const temp = new Uint8Array(mediaContent)
								mediaContent = new Blob([temp], { type: media.filetype })
							}

							formData.append('media', mediaContent)
							formData.append('media_filename', media.filename)

							await this._sendRequest(
								url,
								formData,
								true,
								{ 'Content-Type': 'multipart/form-data' }
							)

							await table.update(media.filename, {
								state: MEDIA_STATES.DOWNLOADED,
								last_used: new Date()
							})
						}
						catch (err) {
							await table.update(media.filename, {
								state: MEDIA_STATES.NEED_UPLOAD
							})
							throw err
						}
					}
				)
			},
			async runUp(type='required', wishlist=null, opts={}, since=undefined) {

				await this._sendExportIfNeeded()

				// Il faut envoyer les médias avant les données pour éviter des documents pas trouvés
				await this._runMedias()

				// Récupérer les X premiers records de _sync_temp
				const syncTempRows = await this._retrieveUpdatedRecords()
				const needNextPacket = syncTempRows.length >= this.PACKET_SIZE

				// Transformation pour correspondre au format de l'API
				const records = []
				const syncTempIds = []

				syncTempRows.forEach((row) => {
					records.push({
						model: row.table_name,
						id: row.row_id,
						operation: row.operation,
						data: row.columns
					})

					syncTempIds.push(row.id)
				})


				// Pour ne pas récupérer la synchro descendante
				if(needNextPacket) {
					records.push({
						only_models: []
					})
				}
				else if (wishlist) {
					records.push({
						only_models: wishlist
					})
				}

				const response = await this._getData(type, {
					...records,
					...opts
				}, since)

				const data = response.data.retour
				data.replicated = data.replicated || []

				// si la synchro me retourne un token alors je le set dans la config et le header des prochaines requêtes
				if(data.token) {
					await ConfigMixin.methods.setConfig("token", data.token)
					Vue.axios.defaults.headers['Authorization'] = 'Bearer ' + data.token
				  	Vue.axios.defaults.headers.common['Authorization'] = 'Bearer ' + data.token
				}

				// Appliquer les replicated
				if(data.replicated.length > 0) {
					// On modifie les lignes dans l'IndexedDB pour virer les lignes négatives
					await this._processor.applyReplicated(data.replicated)

					// On va mettre à jour en RAM les replicated
					this._updateReplicated(data.replicated)
				}

				await this._deleteUpdatedRecords(syncTempIds)

				//si l'on a envoyé des données on lance un ping ws
				if(records.length > 0){
					this.websocketPush()
				}

				if(needNextPacket) {
					return this.runUp(type, wishlist, opts, since)
				}
				else {
					return response.data.retour
				}
			},
			/**
			 * Exécute la synchro montante et récupère les éventuelles données du type spécifié
			 * @param {String} type required ou optionnal en fonction du type de synchro voulu
			 * @param {Object} pagination Optionel - Indique l'avancement de la synchronisation
			 * @param {Array} wishlist Optionel - Synchroniser seulement une partie des tables
			 */
            async _run(type, pagination=undefined, wishlist=undefined, since=undefined, id="default") {
				const result = await this.runUp(type, wishlist, {
					pagination: pagination || undefined
				}, since)

				await this._handleData(result, type, id)

				// Quand tous les packets sont reçus, on a plus de pagination
				if(result.pagination == null) {
					// Comme on a récupéré seulement une partie des données, on doit pas se souvenir de la timestamp
					if (!wishlist) {
						store.commit('sync/setProgressState', PROGRESS_STATES.SENDING)
						this.setLastSync(type, result.timestamp)
					}
				}
				// Si on a une pagination, on relance avec les params de pagination
				else if(store.state.sync.progressState !== PROGRESS_STATES.CANCELLED) {
					store.commit('sync/setProgressState', PROGRESS_STATES.SENDING)
					return this._run(type, result.pagination, wishlist, since, id)
				}
				else {
					this._onSyncEnd()
				}
			},
			/**
			 * Exécute la synchronisation montante et récupère seulement les tables nécessaires
			 * @param {Array} whishlist
			 */
			async runRequiredWhishlist(whishlist, since=undefined, id) {
				if(!id) {
					id = Common.getRandomString(5)
				}
				return this._run('required', undefined, whishlist, since, id)
			},
			/**
			 * Exécute la synchronisation montante et récupère seulement les tables nécessaires pour les optional
			 * @param {Array} whishlist
			 */
			async runOptionalWhishlist(whishlist, since=undefined, id=undefined) {
				if(!id) {
					id = Common.getRandomString(5)
				}
				return this._run('optionnal', undefined, whishlist, since, id)
			},
			async runFromScratchIfNeeded() {
				const syncFromScratch = this.getSyncFromScratch()
				if(syncFromScratch.length > 0) {
					await this.runRequiredWhishlist(syncFromScratch, 1, 'from_scratch')
					await this.runOptionalWhishlist(syncFromScratch, 1, 'from_scratch')
					this.setSyncFromScratch([])
				}
			},
			/**
			 * Formatte l'URL de synchronisation des médias
			 * @param {String} filename
			 * @return {String} URL à requeter pour envoyer un média vers l'API
			 */
			_constructUploadMediaUrl(filename) {
				return Constants.SYNC_FILE_API
					+ `?licence_key=${Constants.USER_LICENCE_KEY}`
					+ `&device_id=${this.deviceId()}`
					+ `&media_filename=${filename}`
			},
			/**
			 * Récupère les médias en attente de synchronisation
			 * @returns {Array} Liste de fichiers de la table _files
			 */
			async _retrieveMedias() {
				return Vue.prototype
				.$storage
				.db
				.t('_files')
				.then(table => {
					return table.where({
						state: MEDIA_STATES.NEED_UPLOAD
					})
				})
				.then(col => {
					return col.toArray()
				})
			},
			async _sendExportIfNeeded() {
				if (this._needExport) {
					let tableItems = {}

					tableItems['_sync_temp'] = await Vue.prototype.$storage.db.t('_sync_temp')
					.then(table => {
						return table.toArray()
					})

					await Common.asyncForEach(
						Vue.prototype.$storage.db.tables,
						async (table) => {
							if (table.name.startsWith('_')) {
								return
							}

							const items = await table.where(':id').below(0).toArray()
							if(items.length) {
								tableItems[table.name] = items
							}
						}
					)

					await Vue.prototype.$sync.sendExport({
						licence_key: Constants.USER_LICENCE_KEY,
						deviceId: this.deviceId(),
						source: 'Sync::_retrieveUpdatedRecords',
						data: {
							tableItems,
						}
					})

					this._needExport = false
				}
			},
			/**
			 * Récupère les données en attente de synchronisation
			 * @returns {Array} Liste de lignes de la table _sync_temp
			 */
            async _retrieveUpdatedRecords() {
                // Récupération des modifications à envoyer
                return Vue.prototype
                .$storage
                .db
                .t('_sync_temp')
                .then(table => {
                    return table.limit(this.PACKET_SIZE).toArray()
				})
			},
			/**
			 * Lorsque la synchronisation a réussi, on supprime les données en attente d'envoie
			 */
            _deleteUpdatedRecords(syncTempIds) {
                return Vue.prototype
                .$storage
                .db
                .t('_sync_temp')
                .then(table => {
                    return table
                        .where('id')
						.anyOf(syncTempIds)
                        .delete()
                })
			},
			/**
			 * Traite les données reçues par l'API
			 * @param {Object} data Résultat de la requete de synchro de l'API
			 * @param {String} type Type de synchronisation - required ou optionnal
			 */
            async _handleData(data, type='required', id="default") {
                if (data.status !== 'ok') {
                    throw new Error(`Received a sync success with a <${data.status}> status`)
				}

				// On l'ajoute à une queue comme ça si un syncWishlist tourne en meme
				// temps les traitements vont se faire un par un
				await this._handleDataQueue.enqueue(async () => {

					_defaults(data, {
						type: type || 'required',
						pagination: {},
						inserted: {},
						updated:  {},
						deleted:  {}
					})

					// Processing inserted, updated & deleted
					await this._processor.processData(data)

					// Ne pas save la pagination si c'est la dernière page pour ne pas la renvoyer à la prochaine synchro
					if(data.pagination.model_page === 0) {
						data.pagination = null
					}

					// On se souvient de la pagination
					if(type == 'required') {
						this.setLastRequiredState(id, data.pagination)
					}
					else {
						this.setLastOptionnalState(id, data.pagination)
					}
				})
			},

			/**
			 * Permet de sauvegarder dans le Plugin et dans le localStorage les correspondances d'IDs
			 * @param {Array} replicatedRes Infos des données répliquées
			 */
            _updateReplicated(replicatedRes=[]) {
                if(replicatedRes.length === 0) {
                    return
				}

                replicatedRes.forEach(res => {
                    const primKeyName = Object.keys(res).find(key => (key !== 'old_id' && key.endsWith('_id')))
                    const pk = `${res.model}|${res.old_id}`
                    if (!this._replicated[pk]) {
                        this._replicated[pk] = {
                            value: res[primKeyName],
                            date: new Date()
                        }
                    }
                })
                this._setReplicated(this._replicated)
            },

            /**
             * Retourne le bon ID pour la ligne de la table correspondante
             * @param {String} tableName Nom de la table avec la clé
             * @param {Array | Number} val Valeur actuelle de la clé
             */
            replaceWithReplicated(tableName, ids) {
                const singleItem = !Array.isArray(ids)

                if (singleItem) {
                    ids = [ids]
                }

                ids = ids.map(val => {
					const id = parseInt(val)
                    if (!Number.isInteger(id)) {
                        return val
					}
					else if (id > 0) {
						return parseInt(id)
					}

                    const result =  this._replicated[`${tableName}|${id}`]
                    return result ? result.value : id
                })

                return singleItem ? ids[0] : ids
			},

			onFocus() {
				this._replicated = this._getReplicated()

				if (store.state.sync.progressState === PROGRESS_STATES.COMPLETED
					&& store.state.sync.state === SYNC_STATES.STARTED
				) {
					this.force(true)
				}
			},

			onBeforeUnload() {
				let oldPendingRecords = this._getPendingRecords()
				this._pendingRecords = oldPendingRecords.concat(this._pendingRecords)

				this._setPendingRecords(this._pendingRecords)
			},

			async addPendingRecords(records) {
				this._pendingRecords = this._pendingRecords.concat(records)

				await this.persistPendingRecords()

				this._debouncedForce(true)
			},

			async persistPendingRecords() {
                return this._queue.enqueue( async () => {
                    const records = this._pendingRecords.concat([])

                    if(records.length === 0) {
                        return
                    }

                    await Vue.prototype
                    .$storage
                    .db
                    .t('_sync_temp')
                    .then(table => {
                        return table.bulkAdd(records)
                    })

                    // Supprimer les X premiers éléments qu'on vient de save dans _sync_temp
                    this._pendingRecords.splice(0, records.length)
        		})
			},

            /**********
             * EVENTS *
             **********/
            _onSyncSuccess() {
				if (store.state.sync.state !== SYNC_STATES.STARTED) {
					store.commit('sync/setState', SYNC_STATES.STARTED)
				}

				EventBus.$emit('sync:progress_success')
            },
            _onSyncError(e) {
                store.commit('sync/setState', SYNC_STATES.ERROR)
                EventBus.$emit('sync:progress_error')
                console.error(e)
            },
            _onSyncEnd() {
                store.commit('sync/setProgressState', PROGRESS_STATES.COMPLETED)
				localStorage.removeItem("websocket:sync")
            },

            /************
             * REQUESTS *
             ************/
            _cancelRequests() {
                Object.keys(this._cancelFns).forEach(requestId => {
                    this._cancelFns[requestId]('Synchronisation cancelled')
                })
            },
            /**
             * Envoyer une requête annulable au serveur
             */
            _sendRequest(url, data={}, cancellable=true, opts={}) {
				const requestId = this.uuid()

				if (data instanceof FormData) {
					data.append('licence_key', Constants.USER_LICENCE_KEY)
					data.append('device_id', this.deviceId())
				}
				else {
					data = {
						licence_key: Constants.USER_LICENCE_KEY,
						device_id: this.deviceId(),
						...data
					}
				}

                return Vue.axios.post(url, data, {
                    cancelToken: new Vue.axios.CancelToken(
                        (c) => {
                            if (cancellable) {
                                this._cancelFns[requestId] = c
                            }
                        }
                    ),
                    ...opts
				})
				.catch((err) => {
					if (err.response && err.response.status === 401) {
						EventBus.$emit('App::forceDelogUser')
					}
					 else if (err.response.status == 406) {
		                EventBus.$emit('App::failureToast', err.response.data.message)

		                navigator.serviceWorker.register(`${process.env.BASE_URL}service-worker.js`).then(registration => {
		                    registration.update();
		                    EventBus.$emit('SW::updateFound')
		                    EventBus.$on('SW::updated', () => {
		                        window.location.reload()
		                    })
		                    setTimeout(() => {
		                    	window.location.reload()
						    }, 5000);
		                })
		            }
					throw err
				})
				.finally(() => {
                    delete this._cancelFns[requestId]
                })
            },
            _getData(type, updatedRecords = [], since=undefined) {
                since = since || this._lastSync[type]
                const url = Common.constructRoute(
					`${Constants.SYNC_DATA_API}?since=${since}`,
					{ type }
				)

				return this._sendRequest(url, updatedRecords)
            },
            _accuseReception() {
                return this._sendRequest(Constants.SYNC_ACCUSE_API)
			},
			_onMediaFetch(filename) {
				return Vue.prototype
				.$storage
				.db
				.t('_files')
				.then(table => {
					return table.update(filename, { last_used: new Date() })
				})
			},
			_getSqliteDatabaseExport() {
                const has_been_backuped = ConfigMixin.methods.getConfig('migrated', false)
				if(has_been_backuped) return

				if(Constants.IS_IOS_RUNNING) {
					window.nativeComm.fetchNative(res => {
						if(!res) return
						this._sendDbBackup(res)
					}, {
						licence_username: Constants.USER_NAME
					}, 'getOldDbBackup')
				}
				else if(Constants.IS_AND_RUNNING) {
					window.nativeComm.fetchNative(res => {
						if(!res) return
						this._sendDbBackup(res)
					}, Constants.USER_NAME, 'getOldDbBackup')
				}
			},
			_sendDbBackup(db_base64) {
				const url = `${Constants.SYNC_MIGRATION}/${Constants.APP_BUILD_NUMBER}`
				this._sendRequest(url, { export: db_base64 })
					.then(() => {
						Config.methods.setConfig('migrated', true, false)
					})
			},

            /**
             * Charge un fichier en local et depuis l'API pour voir si il a été mis à jour
             * On utilise une callback car on doit l'appeler une fois au localFile et une fois au remote file
             * @param {String} filename Filename servant d'identifiant au fichier
             * @returns {Blob} Blob file
             */
            async loadOnDemandFile(filename) {
				this._onMediaFetch(filename)

				const localBlob = await this._loadLocalFile(filename)
				if(localBlob) {

					if(localBlob.file_content instanceof Blob) {
						try {
							// Si le blob fonctionne - surtout pour les problèmes sous safari et dérivés
							const testUrl = window.URL.createObjectURL(localBlob)
					 		return localBlob.file_content
						}
						catch(e) {
							return this._loadRemoteFile(filename)
						}
					}

					const temp = new Uint8Array(localBlob.file_content)
					return new Blob([temp], {type: localBlob.file_type})
				}

				return this._loadRemoteFile(filename)
            },
            _loadLocalFile(filename) {
                return Vue.prototype
                    .$storage
                    .db
                    .t('_files')
                    .then(table => {
                        return table.get(filename)
                    })
                    .then(file => {
						if(!file) return null

                        return {
							file_content: file.content,
							file_type: file.filetype
						}
                    })
            },
            async _loadRemoteFile(filename) {
                const blob = await Vue.prototype.$request.request_get_file_api(
                    "Sync:loadRemoteFile",
                    Constants.SYNC_FILE_API,
                    {
                        licence_key: Constants.USER_LICENCE_KEY,
                        device_id: this.deviceId(),
                        media_filename: filename
                    }
                )

				this._saveFile(filename, blob, MEDIA_STATES.DOWNLOADED)

                return blob
            },
            async _saveFile(filename, blob, state, type=FILE_TYPES.ON_DEMAND) {
				const file_content = await GroomyDB.waitFor(blob.arrayBuffer())

                return Vue.prototype
                    .$storage
                    .db
                    .t('_files')
                    .then(table => {
                        return table.put({
							filename,
							state,
							last_used: new Date(),
                            type,
							content: file_content,
							filetype: blob.type
                        })
                    })
			},
			/**
			 * Envoie une document vers l'API via la synchro
			 * @param {Blob} blob Contenu du fichier au format Blob
			 */
			uploadFile(blob) {
				const nameParts = blob.name.split('.')
				const ext = nameParts.length > 1 ? `.${nameParts[nameParts.length - 1]}` : ''
				const filename = `${Common.getRandomString(32)}${ext}`

				this._saveFile(filename, blob, MEDIA_STATES.NEED_UPLOAD)
				return filename
			},

            /*********
             * TOOLS *
             *********/
            uuid() {
                return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, (c) => {
                    var r = Math.random() * 16 | 0, v = c == 'x' ? r : (r & 0x3 | 0x8)
                    return v.toString(16)
                })
            },
            deviceId() {
                if (!this._deviceId) {
                    this._deviceId = window.localStorage.getItem('sync:device_id')
                }
                if (!this._deviceId) {
                    this.setDeviceId(this.uuid())
                }

                return this._deviceId
            },
            setDeviceId(deviceId) {
                this._deviceId = deviceId
                window.localStorage.setItem('sync:device_id', deviceId)
            },
            getLastSync(type) {
                return parseInt(window.localStorage.getItem(`sync:${Constants.USER_LICENCE_KEY}:last_${type}_sync`) || 0)
            },
            setLastSync(type, lastSync) {
                this._lastSync[type] = lastSync
                window.localStorage.setItem(`sync:${Constants.USER_LICENCE_KEY}:last_${type}_sync`, lastSync)
			},
			getLastRequiredState(id="default") {
				const json = window.localStorage.getItem(`sync:${Constants.USER_LICENCE_KEY}:${id}:last_required_sync_state`)
                return JSON.parse(json)
			},
			setLastRequiredState(id="default", pagination) {
				this._lastRequiredState = pagination

				const key = `sync:${Constants.USER_LICENCE_KEY}:${id}:last_required_sync_state`
				if(!pagination) {
					window.localStorage.removeItem(key)
				}
				else {
					window.localStorage.setItem(key, JSON.stringify(pagination))
				}
			},
			getLastOptionnalState(id="default") {
				const json = window.localStorage.getItem(`sync:${Constants.USER_LICENCE_KEY}:${id}:last_optionnal_sync_state`)
                return JSON.parse(json)
			},
			setLastOptionnalState(id="default", pagination) {
				this._lastOptionnalState = pagination

				const key = `sync:${Constants.USER_LICENCE_KEY}:${id}:last_optionnal_sync_state`
				if(!pagination) {
					window.localStorage.removeItem(key)
				}
				else {
					window.localStorage.setItem(key, JSON.stringify(pagination))
				}
			},
            _getNeedExport() {
                return ConfigMixin.methods.getConfig('need_export', false)
            },
            _getReplicated() {
                let replicated = JSON.parse(window.localStorage.getItem(`sync:${Constants.USER_LICENCE_KEY}:replicated`) || '{}')

                const replicatedExpiry = new Date()
                replicatedExpiry.setMonth(replicatedExpiry.getMonth() - 2)
                Object.keys(replicated).forEach(pk => {
                    replicated[pk] = {
                        value: replicated[pk].value,
                        date: new Date(replicated[pk].date)
                    }

                    if (replicated[pk].date < replicatedExpiry) {
                        delete replicated[pk]
                    }
                })

                return replicated
            },
            _setReplicated(replicated) {
                return window.localStorage.setItem(`sync:${Constants.USER_LICENCE_KEY}:replicated`, JSON.stringify(replicated))
            },
			_getPendingRecords() {
				return JSON.parse(window.localStorage.getItem(`sync:${Constants.USER_LICENCE_KEY}:pending_records`) || '[]')
			},
			_setPendingRecords(pendingRecords) {
				return window.localStorage.setItem(`sync:${Constants.USER_LICENCE_KEY}:pending_records`, JSON.stringify(pendingRecords))
			},
            setSyncFromScratch(tables) {
                window.localStorage.setItem(`sync:${Constants.USER_LICENCE_KEY}:sync_from_scratch`, JSON.stringify(tables))
			},
			getSyncFromScratch() {
				const json = window.localStorage.getItem(`sync:${Constants.USER_LICENCE_KEY}:sync_from_scratch`)
                return json ? JSON.parse(json) : []
			},
            _getPrimaryKeyFromRow(row) {
                for(let attr in row) {
                    if (attr.endsWith('_id')) {
                        return attr
                    }
                }
                return null
            },
            _getBlobUrl(blob) {
                return window.URL.createObjectURL(blob)
            },
            async sendExport(params) {
				const url = `${Constants.SYNC_SEND_EXPORT}?licence_key=${Constants.USER_LICENCE_KEY}`
                return Vue.prototype.$request.request_post_api('Sync::sendExport', url, params, false)
			},
            async ack(action) {
                await Config.methods.setConfig(action, 0)

                const params = {
                    'user_id': ConfigMixin.methods.getConfig('user_id')
                }
                const url = `${Constants.SYNC_ACK}${action}/${Constants.USER_LICENCE_KEY}/${this.deviceId()}`
                return Vue.prototype.$request.request_post_api('Sync::ack', url, params, false)
			},
			isStarted() {
				return !!this._timerId
			},
			_setSyncActualModelsState(models) {
				store.commit('sync/setProgressModels', models)
			},
			getIndexingCount() {
				return Vue.prototype.$storage.db.t('_index_buffer')
				.then(table => {
					return table.count()
				})
			},
			indexTable(tableName) {
				return this._processor.indexTable(tableName)
			},

			/*********
             * WEBSOCKET *
             *********/
            websocketInit() {

			   let that = this;

			   //TODO mette en ${Constants l'url

			   this._ws = new WebSocket(process.env.VUE_APP_SYNC_WEBSOCKET)
			   this._ws.onopen = function() {
				 // subscribe to some channels
				 that._ws.send(JSON.stringify({
				   "action":"UpdateLicense",
				   "licence":`${process.env.VUE_APP_VERSION}:${Constants.USER_LICENCE_KEY}`,
				   "device":that.deviceId()
				 }))
			   }

			   this._ws.onmessage = function(e) {
				   if(e.data === "sync"){
					   //console.log('sync')
					   if(document.visibilityState === 'hidden') {
						   let timeout = Math.floor(Math.random() * (200 - 30)) + 30
						   //console.log(timeout)
						   setTimeout(function() {
							   let c = localStorage.getItem("websocket:sync")
							   //console.log(c)
							   if (c === null) {
								   localStorage.setItem("websocket:sync", "active")
								   //console.log('force')
								   that.force(true)
							   }
						   }, timeout)
					   }else{
						   localStorage.setItem("websocket:sync", "active")
						   //console.log('force')
						   that.force(true)
					   }
				   }
			   }

			   this._ws.onclose = function(e) {
				 //console.log('Socket is closed. Reconnect will be attempted in 1 second.', e.reason)

				 let timeout = 1000

				 if(!window.navigator.onLine) {
 					timeout = 60000
 				 }

				 setTimeout(function() {
				   that.websocketInit()
				 }, timeout)
			   };

			   this._ws.onerror = function(err) {
				 //console.error('Socket encountered error: ', err.message, 'Closing socket')
				 that._ws.close()
			   }
            },
			websocketPush() {
				this._ws.send(JSON.stringify({
				  "action":"OnMessageLicence",
				  "licence":`${process.env.VUE_APP_VERSION}:${Constants.USER_LICENCE_KEY}`,
				  "device":this.deviceId(),
				  "message":'sync'
				}))
			}
        }

        Vue.prototype.$sync = SyncPlugin
    }
}
