import Common from '@/assets/js/common.js'
import Transformer from './Transformer'
import HorseTransformer from '@/assets/js/dexie/transformers/HorseTransformer.js'
import SeasonMareTransformer from '@/assets/js/dexie/transformers/SeasonMareTransformer.js'
import ActeTransformer from '@/assets/js/dexie/transformers/ActeTransformer.js'
import _orderBy from 'lodash/orderBy'

/**
 * Retourne une liste de
 * @param {Dexie.Collection} season
 */
export default class SeasonMareStallionTransformer extends Transformer {

    mare(item) {
        return this.db().t('season_mare')
        .then(table => {
            return table
            .where({
                seasonmare_id: item.seasonmarestallion_seasonmare
            })
        }).then(col => {
            return col.first() || col.toArray()
        })
    }

    async fetch (col) {
        return col.with({
            'season_mare'                       : 'seasonmarestallion_seasonmare',
            'horse'                             : 'seasonmarestallion_horse',
            'porteuse'                          : 'seasonmarestallion_porteuse'
        })
    }

    async transform (seasonMareStallion) {
        return {
            'seasonmarestallion_id'             : seasonMareStallion.seasonmarestallion_id,
            'seasonmarestallion_id_absolute'    : Math.abs(seasonMareStallion.seasonmarestallion_id),
            'seasonmarestallion_seasonmare'     : seasonMareStallion.seasonmarestallion_seasonmare,
            'seasonmarestallion_horse'          : seasonMareStallion.seasonmarestallion_horse,
            'seasonmarestallion_dps'            : seasonMareStallion.seasonmarestallion_dps,
            'seasonmarestallion_dds'            : seasonMareStallion.seasonmarestallion_dds,
            'seasonmarestallion_dt'             : seasonMareStallion.seasonmarestallion_dt,
            'seasonmarestallion_default'        : seasonMareStallion.seasonmarestallion_default,
            'seasonmarestallion_status'         : seasonMareStallion.seasonmarestallion_status,
			'seasonmarestallion_porteuse'		: seasonMareStallion.seasonmarestallion_porteuse,
			'seasonmarestallion_num_dps'		: seasonMareStallion.seasonmarestallion_num_dps,
            'seasonmarestallion_archive'        : seasonMareStallion.seasonmarestallion_archive,
			'horse'								: await HorseTransformer.process(seasonMareStallion.horse, 'withPedigreeAndContact'),
			'mare'								: await SeasonMareTransformer.process(seasonMareStallion.season_mare, 'seasonMareOnlyHorse'),
			'porteuse'							: seasonMareStallion.porteuse.length === 0 ? null : await SeasonMareTransformer.process(seasonMareStallion.porteuse, 'seasonMareOnlyHorse'),
        }
	}

    async transformWithTranslatedStatus (seasonMareStallion) {
        return {
            'seasonmarestallion_id'             : seasonMareStallion.seasonmarestallion_id,
            'seasonmarestallion_id_absolute'    : Math.abs(seasonMareStallion.seasonmarestallion_id),
            'seasonmarestallion_seasonmare'     : seasonMareStallion.seasonmarestallion_seasonmare,
            'seasonmarestallion_horse'          : seasonMareStallion.seasonmarestallion_horse,
            'seasonmarestallion_dps'            : seasonMareStallion.seasonmarestallion_dps,
            'seasonmarestallion_dds'            : seasonMareStallion.seasonmarestallion_dds,
            'seasonmarestallion_dt'             : seasonMareStallion.seasonmarestallion_dt,
            'seasonmarestallion_default'        : seasonMareStallion.seasonmarestallion_default,
            'seasonmarestallion_status'         : seasonMareStallion.seasonmarestallion_status,
			'seasonmarestallion_porteuse'		: seasonMareStallion.seasonmarestallion_porteuse,
			'seasonmarestallion_num_dps'		: seasonMareStallion.seasonmarestallion_num_dps,
            'seasonmarestallion_archive'        : seasonMareStallion.seasonmarestallion_archive,
			'horse'								: await HorseTransformer.process(seasonMareStallion.horse, 'withPedigreeAndContact'),
			'mare'								: await SeasonMareTransformer.process(seasonMareStallion.season_mare, 'seasonMareOnlyHorse'),
			'porteuse'							: !seasonMareStallion.porteuse || seasonMareStallion.porteuse.length === 0 ? null : await SeasonMareTransformer.process(seasonMareStallion.porteuse, 'seasonMareOnlyHorse'),
        }
	}
	
	sort (lines) {
		return _orderBy(lines, ['seasonmarestallion_id_absolute'], ['asc'])
	}
 
    async transformLight (seasonMareStallion) {
        return {
            'seasonmarestallion_id'             : seasonMareStallion.seasonmarestallion_id,
            'seasonmarestallion_seasonmare'     : seasonMareStallion.seasonmarestallion_seasonmare,
            'seasonmarestallion_horse'          : seasonMareStallion.seasonmarestallion_horse,
            'seasonmarestallion_dps'            : seasonMareStallion.seasonmarestallion_dps,
            'seasonmarestallion_default'        : seasonMareStallion.seasonmarestallion_default
        }
    }


	async fetchWithMare (col) {
		this.additionalColumns({
			'mare': this.mare,
		})

		return col
	}

	async transformWithMare (seasonMareStallion) {
		return {
			'seasonmarestallion_id'				: seasonMareStallion.seasonmarestallion_id,
			'seasonmarestallion_id_absolute'	: Math.abs(seasonMareStallion.seasonmarestallion_id),
			'seasonmarestallion_seasonmare'		: seasonMareStallion.seasonmarestallion_seasonmare,
			'seasonmarestallion_horse'			: seasonMareStallion.seasonmarestallion_horse,
			'seasonmarestallion_dps'			: seasonMareStallion.seasonmarestallion_dps,
			'seasonmarestallion_dds'			: seasonMareStallion.seasonmarestallion_dds,
			'seasonmarestallion_dt'				: seasonMareStallion.seasonmarestallion_dt,
			'seasonmarestallion_default'		: seasonMareStallion.seasonmarestallion_default,
			'mare'								: await SeasonMareTransformer.process(seasonMareStallion.mare),
		}
	}


	async fetchWithHorse (col) {
		return col.with({
			'horse'                             : 'seasonmarestallion_horse',
		})
	}

    async transformWithHorse (seasonMareStallion) {
        return {
            'seasonmarestallion_id'             : seasonMareStallion.seasonmarestallion_id,
            'seasonmarestallion_seasonmare'     : seasonMareStallion.seasonmarestallion_seasonmare,
            'seasonmarestallion_horse'          : seasonMareStallion.seasonmarestallion_horse,
            'seasonmarestallion_dps'            : seasonMareStallion.seasonmarestallion_dps,
            'seasonmarestallion_dds'            : seasonMareStallion.seasonmarestallion_dds,
            'seasonmarestallion_dt'             : seasonMareStallion.seasonmarestallion_dt,
            'seasonmarestallion_default'        : seasonMareStallion.seasonmarestallion_default,
            'seasonmarestallion_status'         : seasonMareStallion.seasonmarestallion_status,
			'seasonmarestallion_porteuse'		: seasonMareStallion.seasonmarestallion_porteuse,
			'seasonmarestallion_num_dps'		: seasonMareStallion.seasonmarestallion_num_dps,
            'seasonmarestallion_archive'        : seasonMareStallion.seasonmarestallion_archive,
            'horse'                             : await HorseTransformer.process(seasonMareStallion.horse),
        }
    }
	async fetchWithMareAndHorse (col) {
		this.additionalColumns({
			'mare': this.mare,
		})

		return col.with({
			'horse'                             : 'seasonmarestallion_horse',
		})
	}

    async transformWithMareAndHorse (seasonMareStallion) {
        return {
            'seasonmarestallion_id'             : seasonMareStallion.seasonmarestallion_id,
            'seasonmarestallion_id_absolute'    : Math.abs(seasonMareStallion.seasonmarestallion_id),
            'seasonmarestallion_seasonmare'     : seasonMareStallion.seasonmarestallion_seasonmare,
            'seasonmarestallion_horse'          : seasonMareStallion.seasonmarestallion_horse,
            'seasonmarestallion_dps'            : seasonMareStallion.seasonmarestallion_dps,
            'seasonmarestallion_dds'            : seasonMareStallion.seasonmarestallion_dds,
            'seasonmarestallion_dt'             : seasonMareStallion.seasonmarestallion_dt,
            'seasonmarestallion_default'        : seasonMareStallion.seasonmarestallion_default,
            'horse'                             : await HorseTransformer.process(seasonMareStallion.horse),
            'mare'                              : await SeasonMareTransformer.process(seasonMareStallion.mare, 'seasonMareOnlyHorseSeason')
        }
    }

    // async fetchWithLastSaut(col) {
    //     return col.with({
    //         'ds' : 'seasonmarestallion_ds'
    //     })
    // }

    async transformWithLastSaut (seasonMareStallion) {
        // const dernier_saut = await ActeTransformer.process(seasonMareStallion.ds, 'lite')

        return {
            'seasonmarestallion_id'             : seasonMareStallion.seasonmarestallion_id,
            'seasonmarestallion_id_absolute'    : Math.abs(seasonMareStallion.seasonmarestallion_id),
            'seasonmarestallion_seasonmare'     : seasonMareStallion.seasonmarestallion_seasonmare,
            'seasonmarestallion_horse'          : seasonMareStallion.seasonmarestallion_horse,
            'seasonmarestallion_dps'            : seasonMareStallion.seasonmarestallion_dps,
            'seasonmarestallion_dds'            : seasonMareStallion.seasonmarestallion_dds,
            'seasonmarestallion_dt'             : seasonMareStallion.seasonmarestallion_dt,
            'seasonmarestallion_default'        : seasonMareStallion.seasonmarestallion_default,
            'seasonmarestallion_status'         : seasonMareStallion.seasonmarestallion_status,
            'seasonmarestallion_porteuse'       : seasonMareStallion.seasonmarestallion_porteuse,
            // 'dernier_saut'                      : dernier_saut
        }
    }

    async fetchWithMareLight (col) {
		this.additionalColumns({
			'mare': this.mare,
		})

		return col
	}

    async transformWithMareLight (seasonMareStallion) {
        return {
            'seasonmarestallion_id'             : seasonMareStallion.seasonmarestallion_id,
            'seasonmarestallion_horse'          : seasonMareStallion.seasonmarestallion_horse,
            'mare'                              : await SeasonMareTransformer.process(seasonMareStallion.mare)
        }
    }

    // EVITER LES ERREURS DE BUILD
    async last_avenant(contract) {
        return this.db().t('contract_avenant')
        .then(table => {
            return table
            .where({
                avenant_contract: contract.contract_id
            })
            .sortBy('avenant_num', 'DESC')
        }).then(avenants => {
            if (avenants.length === 0) {
                return null
            }
            return avenants[0]
        })
    }

    async fetchWithLastAvenantUltraLight (col) {
        this.additionalColumns({
            last_avenant: this.last_avenant
        })

        return col
    }

    async transformWithLastAvenantUltraLight (contract) {
        const last_avenant = contract.last_avenant ? {
            'avenant_id'                : contract.last_avenant.avenant_id,
            'avenant_horse'             : contract.last_avenant.avenant_horse
        } : null

        return {
            'contract_id'               : contract.contract_id,
            'last_avenant'              : last_avenant,
        }
    }
}
