import Transformer from './Transformer'
import HorseTransformer from './HorseTransformer.js'

export default class PedigreeTransformer extends Transformer {

    table = 'horse_pedigree'

    async transformLight (pedigree) {
        return {
			pedigree_horse: pedigree.pedigree_horse,
            pere: pedigree.pedigree_nom_1,
            mere: pedigree.pedigree_nom_2,
            pmere: pedigree.pedigree_nom_5
        }
    }

	async enfant(item) {
		const enfant = await this.fetchRelationship(item, 'pedigree_horse')
		return HorseTransformer.process(enfant, 'withResidLieu')
	}

    fetchWithParentsAndChild (col) {
		this.additionalColumns({
			'enfant': this.enfant
		})

		return col
	}

	async transformWithParentsAndChild (pedigree) {
        return {
            'pere': pedigree.pedigree_nom_1,
            'pere_sireencode': pedigree.pedigree_sireencode_1,
            'mere': pedigree.pedigree_nom_2,
            'mere_sireencode': pedigree.pedigree_sireencode_2,
            'enfant': pedigree.enfant
        }
    }
}
