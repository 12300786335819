import Vue from 'vue'
import RootToolsMixin from 'GroomyRoot/mixins/Tools.js'
import Constants from 'Constants'

export default Vue.util.mergeOptions(RootToolsMixin, {
    methods: {
        async verifyRedirectDomain(redirect_url) {
			const params = {
				redirect_url
			}

			const url = this.constructRoute(Constants.CHECK_DOMAIN_AUTHENTICITY, {})
			const result = await this.$request.request_post_api("ToolsMixin::verifyRedirectDomain", url, params, false)
			.catch(error => {
                console.error("ToolsMixin::verifyRedirectDomain => ERROR", error)
                return null
			})
			
            if(!result) {
				throw this.getTrad('compte.invalid_signature')
			}
		},

		async getMathedResults(search, horses, tiers, contacts, contracts, invoices, accurate_search) {
			const params = {search, horses, tiers, contacts, contracts, invoices, accurate_search}

			const url = this.constructRoute(Constants.GET_MATCHED_RESULTS, {}) + "?licence_key=" + Constants.USER_LICENCE_KEY
			const result = await this.$request.request_post_api("ToolsMixin::getMathedResults", url, params, false)
				.catch(error => {
					console.error("ToolsMixin::getMathedResults => ERROR", error)
					return null
				})

            if(result) return result.retour
            return null
		},

		async getAdvancedSearchHistory(history) {
			const url = this.constructRoute(Constants.GET_ADVANCED_SEARCH_HISTORY, {}) + "?licence_key=" + Constants.USER_LICENCE_KEY
			const result = await this.$request.request_post_api("ToolsMixin::getMathedResults", url, history, false)
				.catch(error => {
					console.error("ToolsMixin::getMathedResults => ERROR", error)
					return null
				})

            if(result) return result.retour
            return null
		},

        /* parse une chaine de caractère en float quelque soit le format (1 200,00 ou 1 200.00 ou 1,200.00) */
        parsePotentiallyGroupedFloat: function (stringValue) {
            if(typeof stringValue !== 'string')
                return parseFloat(stringValue)
            stringValue = stringValue.trim();
            var result = stringValue.replace(/[^0-9]/g, '');
            // eslint-disable-next-line
            if (/[,\.]\d{2}$/.test(stringValue)) {
                result = result.replace(/(\d{2})$/, '.$1');
            }
            // eslint-disable-next-line
            if (/[,\.]\d{1}$/.test(stringValue)) {
                result = result.replace(/(\d{1})$/, '.$1');
            }
            // si ma valeur commence par un - alors je suis en négatif :clown:
            if(stringValue.charAt(0) == '-') {
                return -parseFloat(result);
            }
            return parseFloat(result);
        },
    }
})
