import Vue from 'vue'
import Transformer from './Transformer'

/**
 * Retourne une liste de
 * @param {Dexie.Collection} actes
 */
export default class ReponseTransformer extends Transformer {

	table = 'reponse'

	async formatted_data(item) {
		const question = await this.fetchRelationship(item, 'reponse_question')
		if(!question) {
			return ""
		}

		const question_libelle = question.question_licencekey == "" ? Vue.i18n.translate('questions.' + [question.question_libelle]) : question.question_libelle
		if(item.reponse_data[0] === true) {
			return question_libelle + ": " + Vue.i18n.translate('global.oui')
		} else if(item.reponse_data[0] === false) {
			return question_libelle + ": " + Vue.i18n.translate('global.non')
		} else {
			if(Array.isArray(item.reponse_data)) {
				return question_libelle + ": " + item.reponse_data.map(
					(d) => (typeof d === 'object' && d !== null ?
							(Vue.i18n.translate('reponses.' + [d.value]).includes('reponses.') ? d.value : Vue.i18n.translate('reponses.' + [d.value]))
						: 
							(Vue.i18n.translate('reponses.' + [d]).includes('reponses.') ? d : Vue.i18n.translate('reponses.' + [d])))
				).join(', ')
			}
			else {
				return item.reponse_data
			}
	
		}
	}

	transform (reponse) {
		return {
			'reponse_id'			: reponse.reponse_id,
			'reponse_question'		: reponse.reponse_question,
			'reponse_fk'            : reponse.reponse_fk,
			'reponse_type'          : reponse.reponse_type,
			'reponse_data'          : reponse.reponse_data,
			'reponse_data_hidden'	: reponse.reponse_data_hidden,
			'reponse_formatted_data': reponse.reponse_data[0],
		}
	}

	fetchWithQuestion (col) {
		this.additionalColumns({
			formatted_data: this.formatted_data
		})

		return col.with({
            'question'   : 'reponse_question',
        })
	}

	async transformWithQuestion (reponse) {
		return {
			'reponse_id'            : reponse.reponse_id,
			'reponse_question'      : reponse.reponse_question,
			'question'				: reponse.question,
			'reponse_question_index': reponse.question.question_index,
			'reponse_question_code'	: reponse.question.question_code,
			'reponse_fk'			: reponse.reponse_fk,
			'reponse_type'			: reponse.reponse_type,
			'reponse_data'			: reponse.reponse_data,
			'reponse_formatted_data': reponse.reponse_data[0],
			'formatted_data'		: reponse.formatted_data,
			'reponse_data_hidden'	: reponse.reponse_data_hidden
		}
	}
}
