import Common from '@/assets/js/common.js'
import Constants from '../../config.local.js'
import HorsePensionCleaner from '@/assets/js/cache/cleaners/HorsePensionCleaner'

var PensionMixin = {
	methods: {
		loadPension: async function(pension_id) {
			const params = { 'pension_id': pension_id }
			const url = this.constructRoute(Constants.PENSION_GET_URL, params) + "?licence_key="+Constants.USER_LICENCE_KEY
			const result = await this.$request.request_get_api("PensionMixin::loadPension", url)
			.catch(error => {
				console.error("PensionMixin::loadPension => ERROR", error)
				return null
			})
			if(result) return result.retour
			return null
		},

		addPension: async function(horsepension_horse, horsepension_start, horsepension_end, horsepension_pension, close_previous, pension_article, date_cloture_previous) {
			const url = Constants.PENSION_URL+"?licence_key="+Constants.USER_LICENCE_KEY

            if(horsepension_horse < 0){
                await this.$sync.force(true)
                horsepension_horse = this.$sync.replaceWithReplicated('horse', horsepension_horse)
            }

			const infos = {
				"horsepension_horse": horsepension_horse,
				"horsepension_start": horsepension_start,
				"horsepension_end": horsepension_end,
				"horsepension_pension": horsepension_pension,
				"close_previous": close_previous,
				"date_cloture_previous": date_cloture_previous,
				"pension_article": pension_article
			}

			await this.refreshIndexedPension([horsepension_horse])

			return this.$request.request_post_api("PensionMixin::addPension", url, infos, false)
		},

		editPension: async function(horsepension_id, horsepension_horse, horsepension_start, horsepension_end, horsepension_pension, pension_article) {
			const params = { 'horsepension_id': horsepension_id }
			const url = this.constructRoute(Constants.PENSION_GET_URL, params) + "?licence_key="+Constants.USER_LICENCE_KEY

			const infos = {
				"horsepension_horse": horsepension_horse,
				"horsepension_start": horsepension_start,
				"horsepension_end": horsepension_end,
				"horsepension_pension": horsepension_pension,
				"pension_article": pension_article
			}

			const response = await this.$request.request_post_api("PensionMixin::editPension", url, infos, false)
			.catch(e => {
				console.error("PensionMixin::editPension", e)
				this.failureToast("toast.info_save_failed")
			})
			await this.refreshIndexedPension([horsepension_horse])

			return response
		},

		deletePension: async function(horse_id, horsepension_id) {
			const url = this.constructRoute(Constants.PENSION_GET_URL, { 'horsepension_id' : horsepension_id }) + "?licence_key="+Constants.USER_LICENCE_KEY
			await this.$request.request_delete_api("PensionMixin::deletePension", url, {})
			return this.refreshIndexedPension([horse_id])
		},

		checkDatePension: async function(horse_id, horsepension_start, horsepension_end, horsepension_id = null) {
			const params = { 'horse_id': horse_id }
			const url = this.constructRoute(Constants.PENSION_HORSE_DATE_URL, params) + "?licence_key="+Constants.USER_LICENCE_KEY

			const infos = {
				"horsepension_start": horsepension_start,
				"horsepension_end": horsepension_end,
				"horsepension_id": horsepension_id
			}

			const response = await this.$request.request_post_api("PensionMixin::checkDatePension", url, infos, false)
			.catch(e => {
				console.error("PensionMixin::checkDatePension", e)
				this.failureToast("toast.info_save_failed")
			})

			await this.refreshIndexedPension([horse_id])

			return response
		},

		cloturePension: async function(horse_id, date) {
			const url = this.constructRoute(Constants.PENSION_HORSE_CLOSE_URL, {horse_id}) + "?licence_key="+Constants.USER_LICENCE_KEY

			const infos = {
				"pension_end": date,
			}

			const response = await this.$request.request_post_api("PensionMixin::cloturePension", url, infos, false)
			.catch(e => {
				console.error("PensionMixin::cloturePension", e)
				this.failureToast("toast.info_save_failed")
			})

			await this.refreshIndexedPension([horse_id])

			return response
		},

		async refreshIndexedPension(horse_ids) {
			await this.$sync.runOptionalWhishlist([
				'horse_pension',
				'horse_pension_article',
				'pension'
			])
			return HorsePensionCleaner.inst().onMutation(horse_ids)
		},

		async changePensionState(pension_id, state) {
			const infos = {
				'pension_inactive': state
			}

			const url = this.constructRoute(Constants.PENSION_STATE_URL, {pension_id}) + "?licence_key="+Constants.USER_LICENCE_KEY
			const response = await this.$request.request_post_api("PensionMixin::changePensionState", url, infos, false)
			.catch(e => {
				console.error("PensionMixin::changePensionState", e)
				this.failureToast("toast.info_save_failed")
			})

			return response
		},

		async loadPensionsOnPeriod(start_date, end_date) {
			start_date = start_date ? start_date.toDateInputValue() : ''
			end_date = end_date ? end_date.toDateInputValue() : ''

			const url = `${this.constructRoute(Constants.PENSION_LISTE_URL, {})}?licence_key=${Constants.USER_LICENCE_KEY}&from=${start_date}&to=${end_date}`
			const result = await this.$request.request_get_api("PensionMixin::loadPensionsOnPeriod", url)
			.catch(e => {
				console.error("PensionMixin::loadPensionsOnPeriod", e)
				this.failureToast("toast.no_dates")
			})

			if(result) return result.retour
			return null
		},

		async printPdfPensions(start_date, end_date) {
			start_date = start_date ? start_date.toDateInputValue() : ''
			end_date = end_date ? end_date.toDateInputValue() : ''

			const url = `${this.constructRoute(Constants.PENSION_PRINT_URL, {})}?licence_key=${Constants.USER_LICENCE_KEY}&from=${start_date}&to=${end_date}`
			const result = await this.$request.request_post_api("PensionMixin::printPdfPensions", url)
			.catch(e => {
				console.error("PensionMixin::loadPensionsOnPeriod", e)
				this.failureToast("toast.no_dates")
			})

			if(result) {
				Common.base64ToPdf(result, "pensions.pdf")
			}
			else {
				return result
			}
		},

		getHorseWithoutPensions: async function(categories_ids, residence_id, pensions_ids, start, end) {
			const params = {
				categories_ids,
				residence_id,
				pensions_ids,
				start,
				end
			}
			const url = Constants.HORSE_WITHOUT_PENSIONS+"?licence_key="+Constants.USER_LICENCE_KEY
            const result = await this.$request.request_post_api("PensionMixin::getHorseWithoutPension", url, params, false)
			.catch(e => {
                console.error("PensionMixin::getHorseWithoutPension => ", e)
                return null
			})

            if(result) return result.retour
            return []
        },

		getPensions: async function() {
            return this.$storage.db.t('pension')
                .then(table => {
                    return table.toArray()
                })
		}
	}
}

export default PensionMixin
