import Transformer from './Transformer'
import FonctionTransformer from '@/assets/js/dexie/transformers/FonctionTransformer.js'

/**
 * Retourne une liste de
 * @param {Dexie.Collection} season
 */
export default class TiersContactFonctionTransformer extends Transformer {

    async fetch (col) {
        return col.with({
            'fonction'                   : 'tierscontactfonction_fonction',
        })
    }

    async transform (tiersContactFonction) {
        return await FonctionTransformer.process(tiersContactFonction.fonction)
    }
}
