import Common from '@/assets/js/common'
import Transformer from './Transformer'
import IntraLocationTypeTransformer from './IntraLocationTypeTransformer'

/**
 * Retourne une liste de
 * @param {Dexie.Collection} intralocation
 */
export class IntraLocationTransformer extends Transformer {

    table = 'intra_location'

	async children(location) {
		return this.db().t('intra_location')
			.then(table => {
				return table.where({
					intralocation_parent: location.intralocation_id,
					intralocation_parent_type: 'intra_location'
				}).sortBy('intralocation_order')
			})
			.then(async col => {
				return await IntraLocationTransformer.process(col, 'withTypeAndChildren')
			})
	}

	async parent(location) {
		return this.db().t('intra_location')
			.then(table => {
				return table.where({
					intralocation_id: location.intralocation_parent
				}).sortBy('intralocation_order')
			})
			.then(async col => {
				return await IntraLocationTransformer.process(col, 'withParent')
			})
	}

	async fetchWithTypeAndChildren(col) {
		this.additionalColumns({
			children: this.children
		})

        return col.with({
			'type'	: 'intralocation_type'
		})
	}

	async transformWithTypeAndChildren(il) {
		const type = await IntraLocationTypeTransformer.process(il.type)

        return {
            'intralocation_id'		: il.intralocation_id,
            'intralocation_label'	: il.intralocation_label,
			'intralocation_horses'  : il.intralocation_horses,
			'intralocation_type'    : type,
			'intralocation_type_label': type.label,
			'intralocation_size'    : il.intralocation_size,
			'intralocation_parent'	: il.intralocation_parent,
			'intralocation_shape'	: il.intralocation_shape,
			'intralocation_order'	: il.intralocation_order,
			'intralocation_img_url'	: il.intralocation_img_url,
			'children'				: il.children,
			'uuid'					: Common.getNegativeId()
        }
	}


	async fetchWithType(col) {
        return col.with({
			'type'	: 'intralocation_type'
		})
	}

	async transformWithType(il) {
		const type = await IntraLocationTypeTransformer.process(il.type)

        return {
            'intralocation_id'		: il.intralocation_id,
            'intralocation_label'	: il.intralocation_label,
			'intralocation_horses'  : il.intralocation_horses,
			'intralocation_type'    : type,
			'intralocation_type_label': type.label,
			'intralocation_size'    : il.intralocation_size,
			'intralocation_parent'	: il.intralocation_parent,
			'intralocation_shape'	: il.intralocation_shape,
			'intralocation_order'	: il.intralocation_order,
        }
	}

	async fetchWithParent(col) {
		this.additionalColumns({
			parent: this.parent
		})

		return col
	}

	async transformWithParent(il) {
        return {
            'intralocation_id'		: il.intralocation_id,
            'intralocation_label'	: il.intralocation_label,
			'intralocation_horses'  : il.intralocation_horses,
			'intralocation_type'    : il.intralocation_type,
			'intralocation_size'    : il.intralocation_size,
			'intralocation_parent'	: il.intralocation_parent,
			'intralocation_shape'	: il.intralocation_shape,
			'intralocation_order'	: il.intralocation_order,
			'intralocation_parent_type': il.intralocation_parent_type,
			'parent'				: il.parent,
			'uuid'					: Common.getNegativeId()
        }
	}

	async fetchWithParentAndType(col) {
		this.additionalColumns({
			parent: this.parent
		})

        return col.with({
			'type'	: 'intralocation_type'
		})
	}

	async transformWithParentAndType(il) {
		const type = await IntraLocationTypeTransformer.process(il.type)

        return {
            'intralocation_id'		: il.intralocation_id,
            'intralocation_label'	: il.intralocation_label,
			'intralocation_horses'  : il.intralocation_horses,
			'intralocation_type'    : type,
			'intralocation_type_label': type.label,
			'intralocation_size'    : il.intralocation_size,
			'intralocation_parent'	: il.intralocation_parent,
			'intralocation_shape'	: il.intralocation_shape,
			'intralocation_order'	: il.intralocation_order,
			'parent'				: il.parent,
			'uuid'					: Common.getNegativeId()
        }
	}
}

export default IntraLocationTransformer