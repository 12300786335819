let cleaners = {}

/**
 * Récupère une instance de cleaner
 * @param {String} name Nom du cleaner
 * @returns {Transformer} Instance de CacheCleaner
 */
export async function getCleanerInstance(name) {
	if (!cleaners[name]) {
		const module = await import(`@/assets/js/cache/cleaners/${name}.js`)
		cleaners[name] = new module.default()
	}

	return cleaners[name]
}

export function resetCachedCleaners() {
	Object.keys(cleaners).forEach(cleanerName => {
		const cleaner = cleaners[cleanerName]
		cleaner.reset()
		delete cleaners[cleanerName]
	})
}
