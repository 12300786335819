export default [
	'cards',
	'check',
	'compte',
	'courrier_type',
	'editor',
	'fonction',
	'invoice',
	'intra_location',
	'gynecologie',
	'menu',
	'model',
	'monte',
	'notes',
	'payment',
	'questions',
	'reponses',
    'storage',
    'syndication',
	'table',
	'toast',
	'trigger',
	'plan',
	'user_management',
	'qualification',
	'localisation',
	'error',
	'types/blocks',
	'types/lieu_type',
	'types/modules_code',
	'types/qualification',
	'types/variable'
]