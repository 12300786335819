import Transformer from './Transformer'

/**
 * Retourne une liste de
 * @param {Dexie.Collection} userconfig
 */
export default class UserConfigTransformer extends Transformer {

    transform(user_config) {
        return {
			'user'			: user_config.userconfig_user,
			'licence'		: user_config.userconfig_licence,
			'licence_key'	: user_config.userconfig_licencekey,
			'permissions'	: user_config.userconfig_permissions,
			'lang'			: user_config.userconfig_lang
		}
	}
}