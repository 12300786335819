export default {
	fr: {
		label: {
			1: 'Andorre',
			2: 'Émirats arabes unis',
			3: 'Afghanistan',
			4: 'Antigua et Barbuda',
			5: 'Anguilla',
			6: 'Albanie',
			7: 'Armenie',
			8: 'Antilles Néerlandaises',
			9: 'Angola',
			10: 'Antarctique',
			11: 'Argentine',
			12: 'Samoa américaines',
			13: 'Autriche',
			14: 'Australie',
			15: 'Aruba',
			16: 'Îles Åland',
			17: 'Azerbaïdjan',
			18: 'Bosnie-Herzegovine',
			19: 'Barbade',
			20: 'Bangladesh',
			21: 'Belgique',
			22: 'Burkina Faso',
			23: 'Bulgarie',
			24: 'Bahreïn',
			25: 'Burundi',
			26: 'Benin',
			27: 'Bermudes',
			28: 'Brunei',
			29: 'Bolivie',
			30: 'Brésil',
			31: 'Bahamas',
			32: 'Bhoutan',
			33: 'Ile Bouvet',
			34: 'Botswana',
			35: 'Biélorussie',
			36: 'Belize',
			37: 'Canada',
			38: 'Îles des Cocos',
			39: 'République Démocratique du Congo',
			40: 'Centrafrique',
			41: 'République du Congo',
			42: 'Suisse',
			43: 'Côte d\'Ivoire',
			45: 'Îles Cook',
			46: 'Chili',
			47: 'Cameroun',
			48: 'Chine',
			49: 'Colombie',
			50: 'Costa Rica',
			52: 'Cuba',
			53: 'Îles du Cap-Vert',
			54: 'Ile Christmas',
			55: 'Chypre',
			56: 'République Tchèque',
			57: 'Allemagne',
			58: 'Djibouti',
			59: 'Danemark',
			60: 'Ile Dominique',
			61: 'République Dominicaine',
			62: 'Algérie',
			63: 'Equateur',
			64: 'Estonie',
			65: 'Égypte',
			66: 'Sahara occidental',
			67: 'Erythrée',
			68: 'Espagne',
			69: 'Éthiopie',
			70: 'Finlande',
			71: 'Fidji',
			72: 'Îles Falkland',
			73: 'États fédérés de Micronésie',
			74: 'Îles Féroé',
			75: 'France',
			76: 'Gabon',
			77: 'Royaume-Uni',
			78: 'Guernesey',
			79: 'Jersey',
			80: 'Ile de Man',
			81: 'Grenade',
			82: 'Géorgie',
			83: 'France (Guyane)',
			84: 'Ghana',
			85: 'Gibraltar',
			86: 'Groenland',
			87: 'Gambie',
			88: 'République de Guinée',
			89: 'France (Guadeloupe)',
			90: 'Guinée Équatoriale',
			91: 'Grèce',
			92: 'Géorgie du sud et îles Sandwich du Sud',
			93: 'Guatemala',
			94: 'Guam',
			95: 'Guinée-Bissau',
			96: 'Guyane',
			97: 'Hong-Kong',
			98: 'Îles Heard-et-MacDonald',
			99: 'Honduras',
			100: 'Croatie',
			101: 'Haïti',
			102: 'Hongrie',
			103: 'Indonésie',
			104: 'Irlande',
			105: 'Israël',
			106: 'Inde',
			108: 'Iraq',
			109: 'Iran',
			110: 'Islande',
			111: 'Italie',
			112: 'Jamaïque',
			113: 'Jordanie',
			114: 'Japon',
			115: 'Kenya',
			116: 'Kirghizistan',
			117: 'Cambodge',
			118: 'Kiribati',
			119: 'Comores',
			120: 'Saint-Christophe-et-Niévès',
			121: 'Corée du Nord',
			122: 'Corée du Sud',
			123: 'Koweït',
			124: 'Îles Caïmans',
			125: 'Kazakhstan',
			126: 'Laos',
			127: 'Liban',
			128: 'Sainte Lucie',
			129: 'Liechtenstein',
			130: 'Sri Lanka',
			131: 'Libérie',
			132: 'Lesotho',
			133: 'Lituanie',
			134: 'Luxembourg',
			135: 'Lettonie',
			136: 'Libye',
			137: 'Maroc',
			138: 'Monaco',
			139: 'Moldavie',
			140: 'Madagascar',
			141: 'Îles Marshall',
			142: 'Macédoine',
			143: 'Mali',
			144: 'Myanmar',
			145: 'Mongolie',
			146: 'Macao',
			147: 'Îles Mariannes du Nord',
			148: 'Martinique',
			149: 'Mauritanie',
			150: 'Montserrat',
			151: 'Malte',
			152: 'Ile Maurice',
			153: 'Maldives',
			154: 'Malawi',
			155: 'Mexique',
			156: 'Malaisie',
			157: 'Mozambique',
			158: 'Namibie',
			159: 'Nouvelle-Calédonie',
			160: 'Niger',
			161: 'Ile Norfolk',
			162: 'Nigéria',
			163: 'Nicaragua',
			164: 'Pays-Bas',
			165: 'Norvège',
			166: 'Népal',
			167: 'Nauru',
			168: 'Niue',
			169: 'Nouvelle-Zélande',
			170: 'Oman',
			171: 'Panama',
			172: 'Pérou',
			173: 'Polynésie française',
			174: 'Papouasie-Nouvelle-Guinée',
			175: 'Philippines',
			176: 'Pakistan',
			177: 'Pologne',
			178: 'St-Pierre et Miquelon',
			179: 'Îles Pitcairn',
			180: 'Porto Rico',
			181: 'Territoires palestiniens',
			182: 'Portugal',
			183: 'Palau',
			184: 'Paraguay',
			185: 'Qatar',
			186: 'Réunion',
			187: 'Roumanie',
			188: 'Russie',
			189: 'Rwanda',
			190: 'Arabie saoudite',
			191: 'Îles Salomon',
			192: 'Seychelles',
			193: 'Soudan',
			194: 'Suède',
			224: 'Singapour',
			225: 'Sainte-Hélène, Ascension et Tristan da Cunha',
			226: 'Tristan da Cunha',
			227: 'Slovénie',
			228: 'Slovaquie',
			229: 'Sierra Leone',
			230: 'St-Marin',
			231: 'Sénégal',
			232: 'Somalie',
			233: 'Suriname',
			234: 'St-Thomas et Prince',
			235: 'El Salvador',
			236: 'Syrie',
			237: 'Eswatini',
			238: 'Îles Turques-et-Caïques',
			239: 'Tchad',
			241: 'Togo',
			242: 'Thaïlande',
			243: 'Tadjikistan',
			245: 'Turkmenistan',		
			246: 'Tunisie',
			247: 'Tonga',
			248: 'Timor oriental',
			249: 'Turquie',
			250: 'Trinité et Tobago',
			251: 'Tuvalu',
			252: 'Taiwan',
			253: 'Tanzanie',
			254: 'Ukraine',
			255: 'Ouganda',
			256: 'Îles mineures éloignées des États-Unis',
			257: 'États-Unis',
			258: 'Uruguay',
			259: 'Ouzbekistan',
			260: 'Vatican',
			261: 'St-Vincent et Grenadines',
			262: 'Venezuela',
			263: 'Îles Vierges britanniques (Tortola)',
			264: 'Îles Vierges des États-Unis',
			265: 'Vietnam',
			266: 'Vanuatu',
			267: 'Wallis et Futuna',
			268: 'Samoa occidental (Savaii, Upola)',
			269: 'Kosovo',
			270: 'Yémen',
			271: 'Mayotte',
			272: 'Afrique du sud',
			273: 'Zambie',
			274: 'Zimbabwe',
			275: "Serbie",
			290: 'België'
		},
		iso: {
			1: "AD",
			2: "AE",
			3: "AF",
			4: "AG",
			5: "AI",
			6: "AL",
			7: "AM",
			8: "AN",
			9: "AO",
			10: "AQ",
			11: "AR",
			12: "AS",
			13: "AT",
			14: "AU",
			15: "AW",
			16: "AX",
			17: "AZ",
			18: "BA",
			19: "BB",
			20: "BD",
			21: "BE",
			22: "BF",
			23: "BG",
			24: "BH",
			25: "BI",
			26: "BJ",
			27: "BM",
			28: "BN",
			29: "BO",
			30: "BR",
			31: "BS",
			32: "BT",
			33: "BV",
			34: "BW",
			35: "BY",
			36: "BZ",
			37: "CA",
			38: "CC",
			39: "CD",
			40: "CF",
			41: "CG",
			42: "CH",
			43: "CI",
			45: "CK",
			46: "CL",
			47: "CM",
			48: "CN",
			49: "CO",
			50: "CR",
			51: "CS",
			52: "CU",
			53: "CV",
			54: "CX",
			55: "CY",
			56: "CZ",
			57: "DE",
			58: "DJ",
			59: "DK",
			60: "DM",
			61: "DO",
			62: "DZ",
			63: "EC",
			64: "EE",
			65: "EG",
			66: "EH",
			67: "ER",
			68: "ES",
			69: "ET",
			70: "FI",
			71: "FJ",
			72: "FK",
			73: "FM",
			74: "FO",
			75: "FR",
			76: "GA",
			77: "GB",
			78: "GG",
			79: "JE",
			80: "IM",
			81: "GD",
			82: "GE",
			83: "GF",
			84: "GH",
			85: "GI",
			86: "GL",
			87: "GM",
			88: "GN",
			89: "GP",
			90: "GQ",
			91: "GR",
			92: "GS",
			93: "GT",
			94: "GU",
			95: "GW",
			96: "GY",
			97: "HK",
			98: "HM",
			99: "HN",
			100: "HR",
			101: "HT",
			102: "HU",
			103: "ID",
			104: "IE",
			105: "IL",
			106: "IN",
			107: "IO",
			108: "IQ",
			109: "IR",
			110: "IS",
			111: "IT",
			112: "JM",
			113: "JO",
			114: "JP",
			115: "KE",
			116: "KG",
			117: "KH",
			118: "KI",
			119: "KM",
			120: "KN",
			121: "KP",
			122: "KR",
			123: "KW",
			124: "KY",
			125: "KZ",
			126: "LA",
			127: "LB",
			128: "LC",
			129: "LI",
			130: "LK",
			131: "LR",
			132: "LS",
			133: "LT",
			134: "LU",
			135: "LV",
			136: "LY",
			137: "MA",
			138: "MC",
			139: "MD",
			140: "MG",
			141: "MH",
			142: "MK",
			143: "ML",
			144: "MM",
			145: "MN",
			146: "MO",
			147: "MP",
			148: "MQ",
			149: "MR",
			150: "MS",
			151: "MT",
			152: "MU",
			153: "MV",
			154: "MW",
			155: "MX",
			156: "MY",
			157: "MZ",
			158: "NA",
			159: "NC",
			160: "NE",
			161: "NF",
			162: "NG",
			163: "NI",
			164: "NL",
			165: "NO",
			166: "NP",
			167: "NR",
			168: "NU",
			169: "NZ",
			170: "OM",
			171: "PA",
			172: "PE",
			173: "PF",
			174: "PG",
			175: "PH",
			176: "PK",
			177: "PL",
			178: "PM",
			179: "PN",
			180: "PR",
			181: "PS",
			182: "PT",
			183: "PW",
			184: "PY",
			185: "QA",
			186: "RE",
			187: "RO",
			188: "RU",
			189: "RW",
			190: "SA",
			191: "SB",
			192: "SC",
			193: "SD",
			194: "SE",
			224: "SG",
			225: "SH",
			226: "SH",
			227: "SI",
			228: "SK",
			229: "SL",
			230: "SM",
			231: "SN",
			232: "SO",
			233: "SR",
			234: "ST",
			235: "SV",
			236: "SY",
			237: "SZ",
			238: "TC",
			239: "TD",
			240: "TF",
			241: "TG",
			242: "TH",
			243: "TJ",
			244: "TK",
			245: "TM",
			246: "TN",
			247: "TO",
			248: "TL",
			249: "TR",
			250: "TT",
			251: "TV",
			252: "TW",
			253: "TZ",
			254: "UA",
			255: "UG",
			256: "UM",
			257: "US",
			258: "UY",
			259: "UZ",
			260: "VA",
			261: "VC",
			262: "VE",
			263: "VG",
			264: "VI",
			265: "VN",
			266: "VU",
			267: "WF",
			268: "WS",
			269: "XZ",
			270: "YE",
			271: "YT",
			272: "ZA",
			273: "ZM",
			274: "ZW",
			290: "BE"
		},
		iso_3: {
			1: "AND",
			2: "ARE",
			3: "AFG",
			4: "ATG",
			5: "AIA",
			6: "ALB",
			7: "ARM",
			8: "ANT",
			9: "AGO",
			10: "ATA",
			11: "ARG",
			12: "ASM",
			13: "AUT",
			14: "AUS",
			15: "ABW",
			16: "ALA",
			17: "AZE",
			18: "BIH",
			19: "BRB",
			20: "BGD",
			21: "BEL",
			22: "BFA",
			23: "BGR",
			24: "BHR",
			25: "BDI",
			26: "BEN",
			27: "BMU",
			28: "BRN",
			29: "BOL",
			30: "BRA",
			31: "BHS",
			32: "BTN",
			33: "BVT",
			34: "BWA",
			35: "BLR",
			36: "BLZ",
			37: "CAN",
			38: "CCK",
			39: "COD",
			40: "CAF",
			41: "COG",
			42: "CHE",
			43: "CIV",
			45: "COK",
			46: "CHL",
			47: "CMR",
			48: "CHN",
			49: "COL",
			50: "CRI",
			52: "CUB",
			53: "CPV",
			54: "CXR",
			55: "CYP",
			56: "CZE",
			57: "DEU",
			58: "DJI",
			59: "DNK",
			60: "DMA",
			61: "DOM",
			62: "DZA",
			63: "ECU",
			64: "EST",
			65: "EGY",
			66: "ESH",
			67: "ERI",
			68: "ESP",
			69: "ETH",
			70: "FIN",
			71: "FJI",
			72: "FLK",
			73: "FSM",
			74: "FRO",
			75: "FRA",
			76: "GAB",
			77: "GBR",
			78: "GGY",
			79: "JEY",
			80: "IMN",
			81: "GRD",
			82: "GEO",
			83: "GUF",
			84: "GHA",
			85: "GIB",
			86: "GRL",
			87: "GMB",
			88: "GIN",
			89: "GLP",
			90: "GNQ",
			91: "GRC",
			92: "SGS",
			93: "GTM",
			94: "GUM",
			95: "GNB",
			96: "GUY",
			97: "HKG",
			98: "HMD",
			99: "HND",
			100: "HRV",
			101: "HTI",
			102: "HUN",
			103: "IDN",
			104: "IRL",
			105: "ISR",
			106: "IND",
			108: "IRQ",
			109: "IRN",
			110: "ISL",
			111: "ITA",
			112: "JAM",
			113: "JOR",
			114: "JPN",
			115: "KEN",
			116: "KGZ",
			117: "KHM",
			118: "KIR",
			119: "COM",
			120: "KNA",
			121: "PRK",
			122: "KOR",
			123: "KWT",
			124: "CYM",
			125: "KAZ",
			126: "LAO",
			127: "LBN",
			128: "LCA",
			129: "LIE",
			130: "LKA",
			131: "LBR",
			132: "LSO",
			133: "LTU",
			134: "LUX",
			135: "LVA",
			136: "LBY",
			137: "MAR",
			138: "MCO",
			139: "MDA",
			140: "MDG",
			141: "MHL",
			142: "MKD",
			143: "MLI",
			144: "MMR",
			145: "MNG",
			146: "MAC",
			147: "MNP",
			148: "MTQ",
			149: "MRT",
			150: "MSR",
			151: "MLT",
			152: "MUS",
			153: "MDV",
			154: "MWI",
			155: "MEX",
			156: "MYS",
			157: "MOZ",
			158: "NAM",
			159: "NCL",
			160: "NER",
			161: "NFK",
			162: "NGA",
			163: "NIC",
			164: "NLD",
			165: "NOR",
			166: "NPL",
			167: "NRU",
			168: "NIU",
			169: "NZL",
			170: "OMN",
			171: "PAN",
			172: "PER",
			173: "PYF",
			174: "PNG",
			175: "PHL",
			176: "PAK",
			177: "POL",
			178: "SPM",
			179: "PCN",
			180: "PRI",
			181: "PSE",
			182: "PRT",
			183: "PLW",
			184: "PRY",
			185: "QAT",
			186: "REU",
			187: "ROU",
			188: "RUS",
			189: "RWA",
			190: "SAU",
			191: "SLB",
			192: "SYC",
			193: "SDN",
			194: "SWE",
			224: "SGP",
			225: "SHN",
			226: "SHN",
			227: "SVN",
			228: "SVK",
			229: "SLE",
			230: "SMR",
			231: "SEN",
			232: "SOM",
			233: "SUR",
			234: "STP",
			235: "SLV",
			236: "SYR",
			237: "SWZ",
			238: "TCA",
			239: "TCD",
			241: "TGO",
			242: "THA",
			243: "TJK",
			245: "TKM",
			246: "TUN",
			247: "TON",
			248: "TLS",
			249: "TUR",
			250: "TTO",
			251: "TUV",
			252: "TWN",
			253: "TZA",
			254: "UKR",
			255: "UGA",
			256: "UMI",
			257: "USA",
			258: "URY",
			259: "UZB",
			260: "VAT",
			261: "VCT",
			262: "VEN",
			263: "VGB",
			264: "VIR",
			265: "VNM",
			266: "VUT",
			267: "WLF",
			268: "WSM",
			269: "XXK",
			270: "YEM",
			271: "MYT",
			272: "ZAF",
			273: "ZMB",
			274: "ZWE",
			275: "SRB",
			290: "BEL"
		}
	},
	en: {
		label: {
			1: 'Andorra',
			2: 'United Arab Emirates',
			3: 'Afghanistan',
			4: 'Antigua and Barbuda',
			5: 'Anguilla',
			6: 'Albania',
			7: 'Armenia',
			8: 'Netherlands Antilles',
			9: 'Angola',
			10: 'Antarctica',
			11: 'Argentina',
			12: 'American Samoa',
			13: 'Austria',
			14: 'Australia',
			15: 'Aruba',
			16: 'Åland Islands',
			17: 'Azerbaijan',
			18: 'Bosnia and Herzegovina',
			19: 'Barbados',
			20: 'Bangladesh',
			21: 'Belgium',
			22: 'Burkina Faso',
			23: 'Bulgaria',
			24: 'Bahrain',
			25: 'Burundi',
			26: 'Benin',
			27: 'Bermuda',
			28: 'Brunei Darussalam',
			29: 'Bolivia',
			30: 'Brazil',
			31: 'Bahamas',
			32: 'Bhutan',
			33: 'Bouvet Island',
			34: 'Botswana',
			35: 'Belarus',
			36: 'Belize',
			37: 'Canada',
			38: 'Cocos (Keeling) Islands',
			39: 'Congo, the Democratic Republic of the',
			40: 'Central African Republic',
			41: 'Congo',
			42: 'Switzerland',
			43: 'Côte d\'ivoire',
			45: 'Cook Islands',
			46: 'Chile',
			47: 'Cameroon',
			48: 'China',
			49: 'Colombia',
			50: 'Costa Rica',
			52: 'Cuba',
			53: 'Cape verde',
			54: 'Christmas island',
			55: 'Cyprus',
			56: 'Czech Republic',
			57: 'Germany',
			58: 'Djibouti',
			59: 'Denmark',
			60: 'Dominica',
			61: 'Dominican Republic',
			62: 'Algeria',
			63: 'Ecuador',
			64: 'Estonia',
			65: 'Egypt',
			66: 'Western Sahara',
			67: 'Eritrea',
			68: 'Spain',
			69: 'Ethiopia',
			70: 'Finland',
			71: 'Fiji',
			72: 'Falkland Islands (Malvinas)',
			73: 'Micronesia',
			74: 'Faroe Islands',
			75: 'France',
			76: 'Gabon',
			77: 'United Kingdom',
			78: 'Guernesey',
			79: 'Jersey',
			80: 'Isle of Man',
			81: 'Grenada',
			82: 'Georgia',
			83: 'French Guiana',
			84: 'Ghana',
			85: 'Gibraltar',
			86: 'Greenland',
			87: 'Gambia',
			88: 'Guinea',
			89: 'France (Guadeloupe)',
			90: 'Equatorial Guinea',
			91: 'Greece',
			92: 'South Georgia and the South Sandwich Islands',
			93: 'Guatemala',
			94: 'Guam',
			95: 'Guinea-Bissau',
			96: 'Guyana',
			97: 'Hong Kong',
			98: 'Heard Island and Mcdonald Islands',
			99: 'Honduras',
			100: 'Croatia',
			101: 'Haiti',
			102: 'Hungary',
			103: 'Indonesia',
			104: 'Ireland',
			105: 'Israel',
			106: 'India',
			108: 'Iraq',
			109: 'Iran',
			110: 'Iceland',
			111: 'Italy',
			112: 'Jamaica',
			113: 'Jordan',
			114: 'Japan',
			115: 'Kenya',
			116: 'Kyrgyzstan',
			117: 'Cambodia',
			118: 'Kiribati',
			119: 'Comoros',
			120: 'Saint Kitts and Nevis',
			121: 'North Korea',
			122: 'South Korea',
			123: 'Kuwait',
			124: 'Cayman islands',
			125: 'Kazakhstan',
			126: 'Laos',
			127: 'Lebanon',
			128: 'Saint Lucia',
			129: 'Liechtenstein',
			130: 'Sri Lanka',
			131: 'Liberia',
			132: 'Lesotho',
			133: 'Lithuania',
			134: 'Luxembourg',
			135: 'Latvia',
			136: 'Libyan Arab Jamahiriya',
			137: 'Morocco',
			138: 'Monaco',
			139: 'Moldova',
			140: 'Madagascar',
			141: 'Marshall islands',
			142: 'Macedonia',
			143: 'Mali',
			144: 'Myanmar',
			145: 'Mongolia',
			146: 'Macao',
			147: 'Northern Mariana Islands',
			148: 'Martinique',
			149: 'Mauritania',
			150: 'Montserrat',
			151: 'Malta',
			152: 'Mauritius',
			153: 'Maldives',
			154: 'Malawi',
			155: 'Mexico',
			156: 'Malaysia',
			157: 'Mozambique',
			158: 'Namibia',
			159: 'New Caledonia',
			160: 'Niger',
			161: 'Norfolk Island',
			162: 'Nigeria',
			163: 'Nicaragua',
			164: 'Netherlands',
			165: 'Norway',
			166: 'Nepal',
			167: 'Nauru',
			168: 'Niue',
			169: 'New Zealand',
			170: 'Oman',
			171: 'Panama',
			172: 'Peru',
			173: 'French Polynesia',
			174: 'Papua New Guinea',
			175: 'Philippines',
			176: 'Pakistan',
			177: 'Poland',
			178: 'Saint Pierre and Miquelon',
			179: 'Pitcairn',
			180: 'Puerto Rico',
			181: 'Palestinian Territory',
			182: 'Portugal',
			183: 'Palau',
			184: 'Paraguay',
			185: 'Qatar',
			186: 'Réunion',
			187: 'Romania',
			188: 'Russia',
			189: 'Rwanda',
			190: 'Saudi Arabia',
			191: 'Solomon Islands',
			192: 'Seychelles',
			193: 'Sudan',
			194: 'Sweden',
			224: 'Singapore',
			225: 'Saint helena',
			226: 'Tristan Da Cunha',
			227: 'Slovenia',
			228: 'Slovakia',
			229: 'Sierra leone',
			230: 'San marino',
			231: 'Senegal',
			232: 'Somalia',
			233: 'Suriname',
			234: 'Sao Tome and Principe',
			235: 'El salvador',
			236: 'Syrian Arab Republic',
			237: 'Swaziland',
			238: 'Turks and Caicos Islands',
			239: 'Chad',
			241: 'Togo',
			242: 'Thailand',
			243: 'Tajikistan',
			245: 'Turkmenistan',
			246: 'Tunisia',
			247: 'Tonga',
			248: 'Timor-Leste',
			249: 'Turkey',
			250: 'Trinidad and Tobago',
			251: 'Tuvalu',
			252: 'Taiwan',
			253: 'Tanzania',
			254: 'Ukraine',
			255: 'Uganda',
			256: 'United States Minor Outlying Islands',
			257: 'United States',
			258: 'Uruguay',
			259: 'Uzbekistan',
			260: 'Holy See (Vatican city state)',
			261: 'Saint Vincent and the Grenadines',
			262: 'Venezuela',
			263: 'Virgin Islands, British',
			264: 'Virgin Islands, U.S.',
			265: 'Vietnam',
			266: 'Vanuatu',
			267: 'Wallis and Futuna',
			268: 'Samoa',
			269: 'Kosovo',
			270: 'Yemen',
			271: 'Mayotte',
			272: 'South Africa',
			273: 'Zambia',
			274: 'Zimbabwe',
			275: 'Serbia',
			290: 'Belgium'
		},
		iso: {
			1: "AD",
			2: "AE",
			3: "AF",
			4: "AG",
			5: "AI",
			6: "AL",
			7: "AM",
			8: "AN",
			9: "AO",
			10: "AQ",
			11: "AR",
			12: "AS",
			13: "AT",
			14: "AU",
			15: "AW",
			16: "AX",
			17: "AZ",
			18: "BA",
			19: "BB",
			20: "BD",
			21: "BE",
			22: "BF",
			23: "BG",
			24: "BH",
			25: "BI",
			26: "BJ",
			27: "BM",
			28: "BN",
			29: "BO",
			30: "BR",
			31: "BS",
			32: "BT",
			33: "BV",
			34: "BW",
			35: "BY",
			36: "BZ",
			37: "CA",
			38: "CC",
			39: "CD",
			40: "CF",
			41: "CG",
			42: "CH",
			43: "CI",
			45: "CK",
			46: "CL",
			47: "CM",
			48: "CN",
			49: "CO",
			50: "CR",
			51: "CS",
			52: "CU",
			53: "CV",
			54: "CX",
			55: "CY",
			56: "CZ",
			57: "DE",
			58: "DJ",
			59: "DK",
			60: "DM",
			61: "DO",
			62: "DZ",
			63: "EC",
			64: "EE",
			65: "EG",
			66: "EH",
			67: "ER",
			68: "ES",
			69: "ET",
			70: "FI",
			71: "FJ",
			72: "FK",
			73: "FM",
			74: "FO",
			75: "FR",
			76: "GA",
			77: "GB",
			78: "GG",
			79: "JE",
			80: "IM",
			81: "GD",
			82: "GE",
			83: "GF",
			84: "GH",
			85: "GI",
			86: "GL",
			87: "GM",
			88: "GN",
			89: "GP",
			90: "GQ",
			91: "GR",
			92: "GS",
			93: "GT",
			94: "GU",
			95: "GW",
			96: "GY",
			97: "HK",
			98: "HM",
			99: "HN",
			100: "HR",
			101: "HT",
			102: "HU",
			103: "ID",
			104: "IE",
			105: "IL",
			106: "IN",
			107: "IO",
			108: "IQ",
			109: "IR",
			110: "IS",
			111: "IT",
			112: "JM",
			113: "JO",
			114: "JP",
			115: "KE",
			116: "KG",
			117: "KH",
			118: "KI",
			119: "KM",
			120: "KN",
			121: "KP",
			122: "KR",
			123: "KW",
			124: "KY",
			125: "KZ",
			126: "LA",
			127: "LB",
			128: "LC",
			129: "LI",
			130: "LK",
			131: "LR",
			132: "LS",
			133: "LT",
			134: "LU",
			135: "LV",
			136: "LY",
			137: "MA",
			138: "MC",
			139: "MD",
			140: "MG",
			141: "MH",
			142: "MK",
			143: "ML",
			144: "MM",
			145: "MN",
			146: "MO",
			147: "MP",
			148: "MQ",
			149: "MR",
			150: "MS",
			151: "MT",
			152: "MU",
			153: "MV",
			154: "MW",
			155: "MX",
			156: "MY",
			157: "MZ",
			158: "NA",
			159: "NC",
			160: "NE",
			161: "NF",
			162: "NG",
			163: "NI",
			164: "NL",
			165: "NO",
			166: "NP",
			167: "NR",
			168: "NU",
			169: "NZ",
			170: "OM",
			171: "PA",
			172: "PE",
			173: "PF",
			174: "PG",
			175: "PH",
			176: "PK",
			177: "PL",
			178: "PM",
			179: "PN",
			180: "PR",
			181: "PS",
			182: "PT",
			183: "PW",
			184: "PY",
			185: "QA",
			186: "RE",
			187: "RO",
			188: "RU",
			189: "RW",
			190: "SA",
			191: "SB",
			192: "SC",
			193: "SD",
			194: "SE",
			224: "SG",
			225: "SH",
			226: "SH",
			227: "SI",
			228: "SK",
			229: "SL",
			230: "SM",
			231: "SN",
			232: "SO",
			233: "SR",
			234: "ST",
			235: "SV",
			236: "SY",
			237: "SZ",
			238: "TC",
			239: "TD",
			240: "TF",
			241: "TG",
			242: "TH",
			243: "TJ",
			244: "TK",
			245: "TM",
			246: "TN",
			247: "TO",
			248: "TL",
			249: "TR",
			250: "TT",
			251: "TV",
			252: "TW",
			253: "TZ",
			254: "UA",
			255: "UG",
			256: "UM",
			257: "US",
			258: "UY",
			259: "UZ",
			260: "VA",
			261: "VC",
			262: "VE",
			263: "VG",
			264: "VI",
			265: "VN",
			266: "VU",
			267: "WF",
			268: "WS",
			269: "XZ",
			270: "YE",
			271: "YT",
			272: "ZA",
			273: "ZM",
			274: "ZW",
			290: "BE"
		},
		iso_3: {
			1: "AND",
			2: "ARE",
			3: "AFG",
			4: "ATG",
			5: "AIA",
			6: "ALB",
			7: "ARM",
			8: "ANT",
			9: "AGO",
			10: "ATA",
			11: "ARG",
			12: "ASM",
			13: "AUT",
			14: "AUS",
			15: "ABW",
			16: "ALA",
			17: "AZE",
			18: "BIH",
			19: "BRB",
			20: "BGD",
			21: "BEL",
			22: "BFA",
			23: "BGR",
			24: "BHR",
			25: "BDI",
			26: "BEN",
			27: "BMU",
			28: "BRN",
			29: "BOL",
			30: "BRA",
			31: "BHS",
			32: "BTN",
			33: "BVT",
			34: "BWA",
			35: "BLR",
			36: "BLZ",
			37: "CAN",
			38: "CCK",
			39: "COD",
			40: "CAF",
			41: "COG",
			42: "CHE",
			43: "CIV",
			45: "COK",
			46: "CHL",
			47: "CMR",
			48: "CHN",
			49: "COL",
			50: "CRI",
			52: "CUB",
			53: "CPV",
			54: "CXR",
			55: "CYP",
			56: "CZE",
			57: "DEU",
			58: "DJI",
			59: "DNK",
			60: "DMA",
			61: "DOM",
			62: "DZA",
			63: "ECU",
			64: "EST",
			65: "EGY",
			66: "ESH",
			67: "ERI",
			68: "ESP",
			69: "ETH",
			70: "FIN",
			71: "FJI",
			72: "FLK",
			73: "FSM",
			74: "FRO",
			75: "FRA",
			76: "GAB",
			77: "GBR",
			78: "GGY",
			79: "JEY",
			80: "IMN",
			81: "GRD",
			82: "GEO",
			83: "GUF",
			84: "GHA",
			85: "GIB",
			86: "GRL",
			87: "GMB",
			88: "GIN",
			89: "GLP",
			90: "GNQ",
			91: "GRC",
			92: "SGS",
			93: "GTM",
			94: "GUM",
			95: "GNB",
			96: "GUY",
			97: "HKG",
			98: "HMD",
			99: "HND",
			100: "HRV",
			101: "HTI",
			102: "HUN",
			103: "IDN",
			104: "IRL",
			105: "ISR",
			106: "IND",
			108: "IRQ",
			109: "IRN",
			110: "ISL",
			111: "ITA",
			112: "JAM",
			113: "JOR",
			114: "JPN",
			115: "KEN",
			116: "KGZ",
			117: "KHM",
			118: "KIR",
			119: "COM",
			120: "KNA",
			121: "PRK",
			122: "KOR",
			123: "KWT",
			124: "CYM",
			125: "KAZ",
			126: "LAO",
			127: "LBN",
			128: "LCA",
			129: "LIE",
			130: "LKA",
			131: "LBR",
			132: "LSO",
			133: "LTU",
			134: "LUX",
			135: "LVA",
			136: "LBY",
			137: "MAR",
			138: "MCO",
			139: "MDA",
			140: "MDG",
			141: "MHL",
			142: "MKD",
			143: "MLI",
			144: "MMR",
			145: "MNG",
			146: "MAC",
			147: "MNP",
			148: "MTQ",
			149: "MRT",
			150: "MSR",
			151: "MLT",
			152: "MUS",
			153: "MDV",
			154: "MWI",
			155: "MEX",
			156: "MYS",
			157: "MOZ",
			158: "NAM",
			159: "NCL",
			160: "NER",
			161: "NFK",
			162: "NGA",
			163: "NIC",
			164: "NLD",
			165: "NOR",
			166: "NPL",
			167: "NRU",
			168: "NIU",
			169: "NZL",
			170: "OMN",
			171: "PAN",
			172: "PER",
			173: "PYF",
			174: "PNG",
			175: "PHL",
			176: "PAK",
			177: "POL",
			178: "SPM",
			179: "PCN",
			180: "PRI",
			181: "PSE",
			182: "PRT",
			183: "PLW",
			184: "PRY",
			185: "QAT",
			186: "REU",
			187: "ROU",
			188: "RUS",
			189: "RWA",
			190: "SAU",
			191: "SLB",
			192: "SYC",
			193: "SDN",
			194: "SWE",
			224: "SGP",
			225: "SHN",
			226: "SHN",
			227: "SVN",
			228: "SVK",
			229: "SLE",
			230: "SMR",
			231: "SEN",
			232: "SOM",
			233: "SUR",
			234: "STP",
			235: "SLV",
			236: "SYR",
			237: "SWZ",
			238: "TCA",
			239: "TCD",
			241: "TGO",
			242: "THA",
			243: "TJK",
			245: "TKM",
			246: "TUN",
			247: "TON",
			248: "TLS",
			249: "TUR",
			250: "TTO",
			251: "TUV",
			252: "TWN",
			253: "TZA",
			254: "UKR",
			255: "UGA",
			256: "UMI",
			257: "USA",
			258: "URY",
			259: "UZB",
			260: "VAT",
			261: "VCT",
			262: "VEN",
			263: "VGB",
			264: "VIR",
			265: "VNM",
			266: "VUT",
			267: "WLF",
			268: "WSM",
			269: "XXK",
			270: "YEM",
			271: "MYT",
			272: "ZAF",
			273: "ZMB",
			274: "ZWE",
			275: "SRB",
			290: "BEL"
		}
	}
}