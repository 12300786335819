import Dexie from 'dexie'

export default class GroomyConfig {

	static dbName = 'groomy-config'
	static schema = {
		storage: "&key"
	}
	static version = 1
	static db = null

	static async getInstance() {
		if (!this.db) {
			const dbExists = await Dexie.exists(this.dbName)

			this.db = new Dexie(this.dbName)
			this.db.version(this.version).stores(this.schema)
			await this.db.open()

			// TODO: Supprimer lorsque tout le monde sera passé du localStorage à GroomyConfig
			if (!dbExists && typeof(window) !== 'undefined') {
				const keys = JSON.parse(window.localStorage.getItem('config_list') || '[]')
				for (let i = 0; i < keys.length; i++) {
					const key = keys[i]
					const value = JSON.parse(window.localStorage.getItem(key))
					await GroomyConfig.setItem(key, value)
				}
			}
		}
		else if (!await this.db.isOpen()) {
			await this.db.open()
		}

		return this.db
	}

	static async getItem(key) {
		const db = await this.getInstance()
		const item = await db.storage.get(key)
		return item ? item.value : null
	}

	static async setItem(key, value) {
		const db = await this.getInstance()
		await db.storage.put({
			key,
			value
		})
	}

	static async removeItem(key) {
		const db = await this.getInstance()
		await db.storage.delete(key)
	}
}
