import Transformer from './Transformer'
import TierTransformer from './TierTransformer'

/**
 * Retourne une liste de
 * @param {Dexie.Collection} season
 */
export default class AccountingPlanTransformer extends Transformer {

    async fetch (col) {
        return col.with({
            'tiers'                     : 'accountingplan_tiers'
        })
    }

    async transform (accounting_plan) {
        return {
            'accountingplan_id'             			: accounting_plan.accountingplan_id,
            'accountingplan_valide'         			: accounting_plan.accountingplan_valide,
            'accountingplan_tiers'          			: accounting_plan.accountingplan_tiers,
            'accountingplan_label'          			: accounting_plan.accountingplan_label,
            'accountingplan_fromnumber'     			: accounting_plan.accountingplan_fromnumber,
            'tiers'                         			: await TierTransformer.process(accounting_plan.tiers, 'light'),
			'accountingplan_default'     				: accounting_plan.accountingplan_default,
			'accountingplan_clientaccountfromdefault'   : accounting_plan.accountingplan_clientaccountfromdefault,
            'accountingplan_archive'                    : accounting_plan.accountingplan_archive,
            'accountingplan_closing_date'               : accounting_plan.accountingplan_closing_date
        }
    }

    async transformLight (accounting_plan) {
        return {
            'accountingplan_id'             : accounting_plan.accountingplan_id,
            'accountingplan_valide'         : accounting_plan.accountingplan_valide,
            'accountingplan_tiers'          : accounting_plan.accountingplan_tiers,
            'accountingplan_label'          : accounting_plan.accountingplan_label,
            'accountingplan_fromnumber'     : accounting_plan.accountingplan_fromnumber,
        }
    }
}
