import Vue from 'vue'
import GroomyDB from "../GroomyDB"

export async function beforeCreateOrUpdate(tableName, request) {
	const db = await GroomyDB.getInstance(false)
	const table = db.table(tableName)
	request.oldValues = []

	for (let i=0; i < request.values.length; i++) {
		let obj = request.values[i]

		const oldObj = await table.get(obj[table.schema.primKey.name])
		request.oldValues.push(oldObj || {})

        beforeMutateObject(table, obj)
	}

    return request
}

export function beforeDelete(tableName, request) {
	if(!Vue.prototype.$sync) {
		return request
	}

    let keys = []
    request.keys.forEach(id => {
        keys.push(Vue.prototype.$sync.replaceWithReplicated(tableName, id))
    })
    request.keys = keys

    return request
}

export function beforeDeleteRange(tableName, request) {
	if(!Vue.prototype.$sync) {
		return request
	}

    request.range.lower = Vue.prototype.$sync.replaceWithReplicated(tableName, request.range.lower)
    request.range.upper = Vue.prototype.$sync.replaceWithReplicated(tableName, request.range.upper)
    
    return request
}

/**
 * Modifie les lignes avec les bons IDs replicated avant leur modification
 * @param {Dexie.Table} table Instance de la table modifiée
 * @param {Object} mods Modification apportées à l'objet
 */
export function beforeMutateObject(table, mods) {
    // Transformer les dates en String en vraies dates
    Object.keys(mods).forEach(key => {
        if(typeof mods[key] === 'string') {
            if (mods[key].isValidDateTime()) {
                mods[key] = Date.parseTz(mods[key])
            }
        }
	})

	// Si pas de $sync, on est dans le worker, donc pas de replicated
	if(!Vue.prototype.$sync) {
		return
	}

	// Remplacer la PK par les replicated
    const primKeyName = table.schema.primKey.name
	if (mods[primKeyName]) {
		mods[primKeyName] = Vue.prototype.$sync.replaceWithReplicated(table.name, mods[primKeyName])
	}

    // Remplacer les FK classiques par les replicated
    table.schema.foreignKeys.forEach(fk => {
        if (mods[fk.index]) {
            mods[fk.index] = Vue.prototype.$sync.replaceWithReplicated(fk.targetTable, mods[fk.index])
        }
    })

    // Remplacer les valeurs des colonnes _fk par les replicated
    table.schema.indexes
    .filter((index) => {
        return index.name.endsWith('_fk')
    })
    .forEach((index) => {
		if (mods[index.name]) {
			mods[index.name] = Vue.prototype.$sync.replaceWithReplicated(
				mods[index.name.replace('_fk', '_type')],
				mods[index.name]
			)
		}
    })
}
