import Transformer from './Transformer'

export default class DuedateConfigTransformer extends Transformer {

	table = 'duedate_config'

    transform(duedate_config) {
        return {
			'duedate_config_id': duedate_config.duedate_config_id,
			'duedate_config_days': duedate_config.duedate_config_days,
			'duedate_config_endmonth': duedate_config.duedate_config_endmonth
        }
	}
}