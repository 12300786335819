import Transformer from './Transformer'

export default class MediaPlaylistTransformer extends Transformer {

    table = 'horse_media_playlist'

    async transform (mediaplaylist) {
        return {
            mediaplaylist_id: mediaplaylist.mediaplaylist_id,
            mediaplaylist_horse: mediaplaylist.mediaplaylist_horse,
            mediaplaylist_libelle: mediaplaylist.mediaplaylist_libelle,
            mediaplaylist_type: mediaplaylist.mediaplaylist_type,
            mediaplaylist_order: mediaplaylist.mediaplaylist_order,
        }
    }
}
