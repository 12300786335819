import Transformer from './Transformer'

/**
 * Retourne une liste de
 * @param {Dexie.Collection} season
 */
export default class ArticlesTypeTransformer extends Transformer {

    async transform (articlestype) {
        return {
            'articlestype_id'					: articlestype.articlestype_id,
            'articlestype_label'				: articlestype.articlestype_label,
            'articlestype_code'					: articlestype.articlestype_code
        }
    }
}
