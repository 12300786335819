import Constants from '../../config.local.js'

var ShortcutsMixin = {
	methods: {
		getShortcuts: async function() {
			const url = Constants.GET_SHORTCUTS + "?licence_key="+Constants.USER_LICENCE_KEY
			const result = await this.$request.request_get_api("ShortcutsMixin::getShortcuts", url)
			.catch(error => {
				console.error("ShortcutsMixin::getShortcuts => ERROR", error)
				return null
			})

			if(result && result.retour){
				return result.retour
			}

			return null
		},

		saveShortcuts: async function(shortcuts) {
			let params = {
				shortcuts: shortcuts
			}

			const url = Constants.SAVE_SHORTCUTS + "?licence_key="+Constants.USER_LICENCE_KEY
			const result = await this.$request.request_post_api("ShortcutsMixin::saveShortcuts", url, params, false)
			.catch(error => {
				console.error("ShortcutsMixin::saveShortcuts => ERROR", error)
				return null
			})

			return result
		}
	}
}

export default ShortcutsMixin
