import Transformer from './Transformer'
import AccountingAccountTransformer from './AccountingAccountTransformer'
import ArticlesTypeTransformer from './ArticlesTypeTransformer'
import VatTransformer from './VatTransformer'

/**
 * Retourne une liste de
 * @param {Dexie.Collection} season
 */
export default class ArticleTransformer extends Transformer {

	table = 'articles'

	async fetch (col) {
        return col.with({
            'vat'						: 'articles_vat',
            'articles_type'				: 'articles_articlestype'
        })
    }


    async transform (article) {
        return {
            'articles_id'               : article.articles_id,
            'articles_label'            : article.articles_label,
            'articles_code'             : article.articles_code,
            'articles_ht'               : article.articles_ht,
            'articles_ttc'              : article.articles_ttc,
            'articles_articlestype'     : article.articles_articlestype,
            'vat'                       : await VatTransformer.process(article.vat),
            'articles_type'             : await ArticlesTypeTransformer.process(article.articles_type),
            'articles_quantite'         : article.articles_quantite,
            'articles_commentaire'      : article.articles_commentaire,
            'articles_label_format'     : article.articles_label + ' - (' +  article.articles_code+')',
        }
	}

    async transformLight (article) {
        return {
            'articles_id'               : article.articles_id,
            'articles_label'            : article.articles_label,
            'articles_code'             : article.articles_code,
            'articles_ht'               : article.articles_ht,
            'articles_ttc'              : article.articles_ttc,
        }
	}
	
	async fetchWithAccountingAccountLite (col) {
        return col.with({
			'vat'						: 'articles_vat',
			'accountingaccount'			: 'articles_accountingaccount'
        })
    }

    async transformWithAccountingAccountLite (article) {
		const accounting_account = await AccountingAccountTransformer.process(article.accountingaccount, 'withAccountingPlanLite')

        return {
            'articles_id'               : article.articles_id,
            'articles_label'            : article.articles_label,
            'articles_code'             : article.articles_code,
            'articles_ht'               : article.articles_ht,
			'articles_ttc'              : article.articles_ttc,
            'articles_quantite'         : article.articles_quantite,
            'articles_commentaire'      : article.articles_commentaire,
			'vat'                       : await VatTransformer.process(article.vat),
			...accounting_account
        }
    }


    async fetchWithAccountingPlan (col) {
        return col.with({
            'vat'                       : 'articles_vat',
            'accounting_account'        : 'articles_accountingaccount',
            'accountingaccount_fdvex'   : 'articles_accountingaccountfdvex',
            'accountingaccount_fdvxp'   : 'articles_accountingaccountfdvxp',

            'vataccount'                : 'articles_vataccount',
            'vataccount_fdvex'          : 'articles_vataccountfdvex',
            'vataccount_fdvxp'          : 'articles_vataccountfdvxp'
        })
    }

    async transformWithAccountingPlan (article) {
        const accounting_account = await AccountingAccountTransformer.process(article.accounting_account)
        const accounting_account_fdvex = await AccountingAccountTransformer.process(article.accountingaccount_fdvex)
        const accounting_account_fdvxp = await AccountingAccountTransformer.process(article.accountingaccount_fdvxp)

        const vat_account = await AccountingAccountTransformer.process(article.vataccount)
        const vat_account_fdvex = await AccountingAccountTransformer.process(article.vataccount_fdvex)
        const vat_account_fdvxp = await AccountingAccountTransformer.process(article.vataccount_fdvxp)

        return {
            'articles_id'               : article.articles_id,
            'articles_label'            : article.articles_label,
            'articles_code'             : article.articles_code,
            'articles_ht'               : article.articles_ht,
            'articles_ttc'              : article.articles_ttc,
            'vat'                       : await VatTransformer.process(article.vat),
            'accounting_account'        : accounting_account,
            'accounting_account_fdvex'  : accounting_account_fdvex,
            'accounting_account_fdvxp'  : accounting_account_fdvxp,
            'vat_account'               : vat_account,
            'vat_account_fdvex'         : vat_account_fdvex,
            'vat_account_fdvxp'         : vat_account_fdvxp
        }
    }
}
