import CacheCleaner from "./CacheCleaner"
import HorseCleaner from "./HorseCleaner"

export default class MouvementCleaner extends CacheCleaner {

	async onMutation(mouvement_ids) {
		await this.init()

		// Vider le cache de tous les chevaux avec ces résidences
		return this.db().t('horse_mouvement')
		.then(table => {
			return table.where(':id').anyOf(mouvement_ids).toArray()
		})
		.then(mouvements => {
			return {
				'regular' : Array.from(new Set(mouvements.filter(m => m.mouvement_type != 5).map(r => r.mouvement_horse))),
				'intraloc': Array.from(new Set(mouvements.filter(m => m.mouvement_type == 5).map(r => r.mouvement_horse)))
			}
		})
		.then(horse_ids => {
			return Promise.all([
				this.onMutationHorseMouv(horse_ids.regular),
				this.onMutationHorseIntraloc(horse_ids.intraloc)
			])
		})
	}

	// Horse est après parce qu'on a bien update une résidence
	// mais on donne des horse_ids en paramètre
	async onMutationHorseMouv(horse_ids) {
		await HorseCleaner.inst().onMutation(horse_ids, ['horse_last_mouv_label'])
	}

	async onMutationHorseIntraloc(horse_ids) {
		await HorseCleaner.inst().onMutation(horse_ids, ['horse_intraloc_label'])
	}
}
