import Common from '@/assets/js/common'

export default class Queue {

	queue = []
	isWorking = false
	isFlushed = false
	resolveFlush = null

	async enqueue(promise, context, args=[], isFirst=false) {
		return new Promise((resolve, reject) => {
			const queueItem = {
				promise,
				context,
				args,
				resolve,
				reject,
			}
			if (isFirst) {
				this.queue.unshift(queueItem)
			}
			else {
				this.queue.push(queueItem)
			}
			this.dequeue()
		})
	}

	async dequeue() {
		if (this.isWorking) {
			return false
		}

		const item = this.queue.shift()
		if (!item) {
			return false
		}

		try {
			this.isWorking = true
			const value = await item.promise.apply(item.context, item.args)
			item.resolve(value)
		}
		catch (err) {
			item.reject(err)
		}
		finally {
			this.isWorking = false
			if (this.isFlushed) {
				this.queue = []
				this.isFlushed = false
				this.resolveFlush()
			}
			else {
				this.dequeue()
			}
		}

		return true
	}

	flush() {
		if (this.isWorking) {
			return new Promise(resolve => {
				this.isFlushed = true
				this.resolveFlush = resolve
			})
		}
	}
}
