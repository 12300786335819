import Transformer from './Transformer'
import ContactTransformer from '@/assets/js/dexie/transformers/ContactTransformer.js'
import TiersContactFonctionTransformer from '@/assets/js/dexie/transformers/TiersContactFonctionTransformer.js'
import TierTransformer from './TierTransformer'

/**
 * Retourne une liste de
 * @param {Dexie.Collection} season
 */
export default class TiersContactTransformer extends Transformer {

    async transform (tiersContact) {
        return {
            'tierscontact_id'           : tiersContact.tierscontact_id,
            'tierscontact_tiers'        : tiersContact.tierscontact_tiers,
            'tierscontact_contact'      : tiersContact.tierscontact_contact,
            'tierscontact_favoris'      : tiersContact.tierscontact_favoris
        }
    }

    async fetchWithContactAndFonction (col) {
        return col.with({
            'contact'                   : 'tierscontact_contact',
            'fonction'                  : 'tiers_contact_fonction'
        })
    }

    async transformWithContactAndFonction (tiersContact) {
        return {
            'tierscontact_id'           : tiersContact.tierscontact_id,
            'tierscontact_tiers'        : tiersContact.tierscontact_tiers,
            'tierscontact_contact'      : tiersContact.tierscontact_contact,
            'tierscontact_favoris'      : tiersContact.tierscontact_favoris,
            'contact'                   : await ContactTransformer.process(tiersContact.contact, 'withPhone'),
            'fonctions'                 : await TiersContactFonctionTransformer.process(tiersContact.fonction)
        }
    }

    async fetchWithTiersAndFonction (col) {
        return col.with({
            'fonction'      : 'tiers_contact_fonction',
            'tiers'         : 'tierscontact_tiers'
        })
    }

    async transformWithTiersAndFonction (tiersContact) {
        return {
            'tierscontact_id'           : tiersContact.tierscontact_id,
            'tierscontact_tiers'        : tiersContact.tierscontact_tiers,
            'tierscontact_contact'      : tiersContact.tierscontact_contact,
            'tierscontact_favoris'      : tiersContact.tierscontact_favoris,
            'fonctions'                 : await TiersContactFonctionTransformer.process(tiersContact.fonction),
            'tiers'                     : await TierTransformer.process(tiersContact.tiers, 'withPhone')
        }
    }
}
