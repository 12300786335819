import Vue from 'vue'
import _orderBy from 'lodash/orderBy'

import CancelError from '@/assets/js/errors/CancelError'
import Common from '@/assets/js/common'
import GroomyConfig from '@/assets/js/dexie/GroomyConfig'
import GroomyDB from '@/assets/js/dexie/GroomyDB'
import '@/assets/js/dexie/addons'
import Store from '@/store/index'
import dateFormat from "dateformat"

import { loadI18nPlugin } from 'GroomyRoot/assets/js/i18n'
import { applyFilters, isValidItem } from 'GroomyRoot/assets/js/utils/table'

const STATES = {
	RUNNING: 0, // La requete est démarrée et est en train de chercher des résultats
	CANCELLED: 1, // L'annulation a été demandée et elle est en attente
	TERMINATED: 2 // Tout est terminé et on a renvoyé les items
}

let isI18nLoaded = false
/**
 * Droits d'accès mis en cache
 */
let accessRights = ''
/**
 * Format de date mis en cache
 */
let dateConfig = 'dd/mm/yyyy'

export class Transformer {
	/**
	 * Nom de la table des items que le Transformer doit transformer
	 */
	table = null
	/**
	 * Liste des tables supplémentaires à inclure dans la transaction de récupération des items
	 * Ex: ['horse'] pour la récupération du cheval dans le stringified de l'ActeTransformer
	 */
	transactionTables = []
	/**
	 * Nom de la clé primaire de la table
	 */
	primaryKey = null
	/**
	 * Données en cache et état de la requete de récupération des items
	 */
	requests = {}
	/**
	 * Indique si les droits d'accès doivent etre récupérés dans les workers
	 */
	needAccessRights = false
	/**
	 * Instance de base de données mise en cache
	 */
	#db = null
	/**
	 * Colonnes supplémentaires à récupérer lors de la transformation
	 */
	#additionalColumns = {}
	/**
	 * Mise en cache des relations et des colonnes supplémentaires
	 */
    #cachedColumns = {}
	/**
	 * Client axios pret à requeter l'API
	 */
	#api_requests = null
	/**
	 * Lignes à save à l'appel de submitIndexationBuffer
	 *
	 * id: {
	 * 	col1: 'lol' // Valeur de la colonne indexée
	 * 	col1_at: 123145645654 // Heure de mise à jour
	 * }
	 */
	#indexationBuffer = {}

    constructor(transformName='', api_requests = null) {
        transformName = this.capitalize(transformName)

		this.fetchFn = this[`fetch${transformName}`]

		const resolveFnName = `resolve${transformName}`
		this.resolveFn = this[resolveFnName]

		const transformFnName = `transform${transformName}`
        this.transformFn = this[transformFnName]

		this.sortFn = this[`sort${transformName}`]

        if (!this.transformFn) {
            throw new Error(`The function ${transformFnName} cannot be found in the Transformer`)
		}

		this.#api_requests = api_requests
	}

	/**
	 * Initialisation de l'environnement dans les workers
	 */
	async init() {
		// Sur le thread principal
		if (Vue.prototype.$storage && Vue.prototype.$storage.db) {
			this.#db = Vue.prototype.$storage.db

			if (this.needAccessRights && !accessRights) {
				accessRights = Store.state.user.permissions
			}
		}

		// Dans les workers
		if (!this.#db) {
			this.#db = await GroomyDB.getInstance(false)

			if (this.needAccessRights && !accessRights) {
				accessRights = await GroomyConfig.getItem('user_access')
			}

			if (!isI18nLoaded) {
				isI18nLoaded = true
				const lang = await GroomyConfig.getItem('lang')
				loadI18nPlugin(lang)
			}
		}

		if (this.table) {
			const table = await this.db().t(this.table)
			if (!this.primaryKey) {
				this.primaryKey = table.schema.primKey.name
			}
		}

		dateConfig = await GroomyConfig.getItem('format_date') ?? 'd/m/Y'
		dateConfig = dateConfig.replace('y', 'yy').replace('Y', 'yyyy').replace('d', 'dd').replace('m', 'mm')
	}

	async reset() {
		this.cancelAll()

		if (this.db() && await this.db().isOpen()) {
			await this.db().close()
		}

		this.#db = null
	}

    capitalize (s) {
        if (typeof s !== 'string') return ''
        return s.charAt(0).toUpperCase() + s.slice(1)
    }

	/**
	 * Indique au transformer quelles colonnes récupérer au moment de la transformation
	 * @param {Object} columns Le nom de la nouvelle colonne en clé et une Promise en valeur
	 */
    additionalColumns(columns) {
        this.#additionalColumns = columns
    }

	/**
	 * Récupère les colonnes supplémentaires d'un item
	 * @param {Object} item Item sur lequel il faut récupérer les colonnes
	 * @returns {Object} Item avec les colonnes supplémentaires
	 */
    async fetchAdditionalColumns(item) {
        await Common.asyncForEach(
			Object.keys(this.#additionalColumns),
			async (key) => {
				item[key] = await this.fetchAdditionalColumn(item, key)
			}
		)

        return item
    }

	/**
	 * Récupère la colonne supplémentaire configurée pour un item
	 * @param {Object} item Item sur lequel il faut récupérer la colonne
	 * @param {String} colName Nom de la colonne supplémentaire
	 * @returns {Any} Retourne la valeur de la colonne supplémentaire
	 */
    async fetchAdditionalColumn(item, colName) {
		// La valeur est peut-etre indexée, dans ce cas on la retourne directement
		let val = item[colName]
		if(val !== undefined) {
			return val
		}

        // On regarde si on a une valeur en cache car on va parfois récupérer
        // les colonnes plusieurs fois pour un meme item
        // Ex: pour filtrer dans un tableau on va fetch la méthode du transformer
        const cachedValue = this._getCachedColumn(item, colName)
        if(cachedValue !== undefined) {
            return cachedValue
        }
        else {
			// Récupération de la valeur
			let indexedAt = new Date().getTime()
            const val = await this[colName].apply(this, [item])
			await this._setCachedColumn(item, colName, val, indexedAt)

            return val
        }
    }

	/**
	 * Récupère la valeur de la relation d'un item
	 * @param {Object} item Item sur lequel il faut récupérer la relation
	 * @param {String} relName Nom de la colonne de l'item qui a une clé étrangère OU nom de la table distante qui a une relation vers cet item
	 * @returns {Any} Valeur de la relation ou undefined si elle n'existe pas
	 */
    async fetchRelationship(item, relName) {
        // Récupération de la valeur en cache si elle existe
        const cachedValue = this._getCachedColumn(item, relName)
        if (cachedValue !== undefined) {
            return cachedValue
        }

        // Rechercher si la relation est le nom d'une des FK
		const table = await this.db().t(this.table)
        for (let i = 0; i < table.schema.foreignKeys.length; i++) {
            const fk = table.schema.foreignKeys[i]
            if (fk.index === relName) {
				if (!item[fk.index]) {
					return
                }
                let where = {}
                where[fk.targetIndex] = item[fk.index]
				let value = await this._relationshipValue(fk.targetTable, where)
                if (value) {
                    return value[0]
                }
            }
		}

        // Rechercher si la relation est le nom d'une table distante
        const targetTable = this.db().tables.find(t => t.name === relName)
        if (targetTable) {
            for(let i = 0; i < targetTable.schema.foreignKeys.length; i++) {
                const fk = targetTable.schema.foreignKeys[i]
                if (fk.targetTable === this.table) {
					if (!item[fk.targetIndex]) {
						return
					}
					let where = {}
                    where[fk.index] = item[fk.targetIndex]
                    let value = await this._relationshipValue(relName, where)
                    if (value) {
                        await this._setCachedColumn(item, relName, value)
                        return value
                    }
                }
            }
        }
    }

	/**
	 * Exécute la requete de récupération d'une relation
	 * @param {String} targetTable Nom de la table sur laquelle il faut récupérer la relation
	 * @param {Object} targetWhere Condition de filtrage des résultats
	 * @returns {Array} Résultat de la requete
	 */
    _relationshipValue(targetTable, targetWhere) {
        return this.db().t(targetTable)
        .then(table => {
            return table.where(targetWhere)
        })
        .then(col => {
            return col.toArray()
        })
    }

	/**
	 * Récupère une colonne mise en cache lors d'une requete précédente
	 * @param {Object} item Item sur lequel on veut récupérer la colonne en cache
	 * @param {*} colName Nom de la colonne en cache
	 * @returns {Any} Valeur de la colonne en cache ou undefined si elle n'est pas en cache
	 */
    _getCachedColumn(item, colName) {
        if (!this.#cachedColumns[colName]) {
            this.#cachedColumns[colName] = {}
        }

        const primaryKey = this._getPrimaryKey(item)
        return this.#cachedColumns[colName][item[primaryKey]]
    }

	/**
	 * Met en cache la relation ou la colonne supplémentaire d'un item
	 * @param {Object} item Item sur lequel il faut mettre en cache une valeur
	 * @param {String} colName Nom de la colonne à mettre en cache
	 * @param {Any} value Valeur à mettre en cache
	 */
    async _setCachedColumn(item, colName, value, indexedAt=null) {
        if (!this.#cachedColumns[colName]) {
            this.#cachedColumns[colName] = {}
		}

        const primaryKey = this._getPrimaryKey(item)
        this.#cachedColumns[colName][item[primaryKey]] = value

		// Si la colonne est indéxée, on l'ajoute au buffer pour la sauvegarder dans la table
		if (this.table) {
			const table = await this.db().t(this.table)
			const isIndexedColumn = this.isIndexed(table, colName)
			if(isIndexedColumn) {
				if(!this.#indexationBuffer[item[primaryKey]]) {
					this.#indexationBuffer[item[primaryKey]] = {}
				}

				this.#indexationBuffer[item[primaryKey]][colName] = value
				if(indexedAt) {
					this.#indexationBuffer[item[primaryKey]][`${colName}_at`] = indexedAt
				}
			}
		}
	}

	async submitIndexationBuffer() {
		const items = Object.assign({}, this.#indexationBuffer)
		const item_ids = Object.keys(items)

		if(item_ids.length > 0) {
			this.#indexationBuffer = {}

			const table = await this.db().t(this.table)

			await Common.asyncForEach(item_ids, async (item_id) => {
				const item = items[item_id]

				// Récupérer la timestamp de l'indexation indiquant l'heure à laquelle la donnée a été récupérée
				const indexedAt = {}
				Object.keys(item).forEach(colName => {
					indexedAt[colName] = item[`${colName}_at`]
					// Il faut supprimer la clé pour ne pas la sauvegarder dans l'indexedDb
					delete item[`${colName}_at`]
				})

				// Sauvegarder les colonnes indexées dans la table
				await table.update(parseInt(item_id), item)

				// Supprimer les lignes du buffer qui ont été insérées avant la date d'indexation
				// pour réindexer les colonnes si elles ont changées depuis leur récupération
				const indexBufferTable = await this.db().t('_index_buffer')
				await Common.asyncForEach(
					Object.keys(item),
					async colName => {
						return indexBufferTable.where(':id').equals(`${this.table}_${item_id}_${colName}`)
						.and(index => {
							return !index.inserted || !indexedAt[colName] || index.inserted <= indexedAt[colName]
						})
						.delete()
					}
				)
			})
		}
	}

	/**
	 * Récupère le nom de la clé primaire à partir d'un item
	 * @param {Object} item
	 */
    _getPrimaryKey(item) {
        if(!this.primaryKey) {
            this.primaryKey = Object.keys(item).find((attr) => (attr.endsWith('_id')))
        }
        return this.primaryKey
    }

	/**
	 * Passe d'un array à un objet avec en clé la primary key et en valeur l'objet entier
	 * @param {Liste d'objets} arr liste d'objets qu'il faut transformer en clé - valeur
	 * @param {String} key clé qui sera utilisée pour mapper l'objet
	 */
	_mapArrayToObject(arr, key) {
		let res = {}

		arr.forEach(item => {
			let keyValue = Common.getNestedObjectString(item, key)
			res[keyValue] = item
		})

		return res
	}

	_arrayToCollection(col) {
		let singleItem = false
		// Ce n'est pas une collection
		if (!col.each) {
			// Initialisation
			let items = col
			if(!Array.isArray(col)) {
				items = [col]
				singleItem = true
			}

			// Récupération des IDs des items à refetch
			let primaryKey = this._getPrimaryKey(items[0])
			let ids = []
			items.forEach(item => {
				ids.push(item[primaryKey])
			})

			// Refetch des données
			const table = this.db().tables.find((table) => {
				return primaryKey === table.schema.primKey.name
			})
			col = table.where(primaryKey).anyOf(ids)

			if (singleItem) {
				col.limit(1)
			}
		}

		return {
			col,
			singleItem
		}
	}

    /**
	 * Retourne un array ou un item avec les relations récupérées
	 * @param {Dexie.Collection|Array|Object} col Element à fetch
	 */
    async f(col) {
        if (this.fetchFn) {

            // Si c'est un item unique ou un array on force le fetch pour avoir une collection
			const res = await this._arrayToCollection(col)
			const singleItem = res.singleItem

			// Retourne un Array ou une Collection
			col = await this.fetchFn(res.col)

            // On ne prend que le premier item si on ne fetch qu'un seul item
            if (singleItem) {
				// On a une Collection en retour
				if (col.first) {
					return await col.first()
				}
				// On a un Array en retour
				else if (col.length > 0) {
					return col[0]
				}
				// On a pas de résultat
				else {
					return null
				}
            }
        }
        return col.toArray ? await col.toArray() : col
    }

	/**
	 * Formatte et récupère les différentes relations en bulk
	 * @param {Array} arr Liste d'éléments bruts
	 * @returns Liste d'éléments formattés, prets à etre affichés
	 */
	async r(arr, ctx) {
		if(arr.length === 0) {
			return []
		}

		return await this.resolveFn(arr, ctx)
	}

    /**
	 * Formatte un objet en fonction du transformer spécifié
	 * @param {Object} item Objet avec les relations à formatter
	 * @return {Object} Objet formatté
	 */
    async t(item) {
        return await this.transformFn(item)
    }

	/**
	 * Tri le résultat du transformer avant de le renvoyer
	 * @param {Array} array Array tranformé et avec les relations à trier
	 * @return {Array} Array trié
	 */
    s(array) {
        if (this.sortFn) {
            return this.sortFn(array)
        }
        return array
    }

	/**
	 * Retourne un objet ou un array avec les relations, formatté et trié
	 * @param {Array|Object} array Objet ou array d'objets à traiter
	 * @param {String} requestId Identifiant de la requete
	 */
    async handle(array, requestId, ctx) {
		if(array === undefined) {
			return null
		}

		if(ctx === undefined){
			ctx = {
				columns: null
			}
		}


		if(requestId) {
			this.requests[requestId] = {
				items: this.requests[requestId] ? this.requests[requestId].items : {},
				state: STATES.RUNNING
			}
		}

		await this.init()

		let items = []
		let singleItem = false

		if(this.resolveFn) {
			const res = await this._arrayToCollection(array)
			singleItem = res.singleItem

			// RESOLVE
			items = await this.r(res.col, ctx)

			await this.submitIndexationBuffer()
		}
		else {
			// FETCH
			array = await this.f(array)

			// when array is a singleItem, f() can return null
			if (array === null) {
				return null
			}

			// array is an array or a single item
			if (array.length === undefined) {
				array = [array]
				singleItem = true
			}

			if(requestId && this.isCancelled(requestId)) {
				return singleItem ? null : []
			}

			// TRANSFORM
			for (let i = 0; i < array.length; i++) {
				array[i] = await this.fetchAdditionalColumns(array[i])

				if(requestId && this.isCancelled(requestId)) {
					return singleItem ? null : []
				}

				items.push(await this.t(array[i]))

				if(requestId && this.isCancelled(requestId)) {
					return singleItem ? null : []
				}
			}

			await this.submitIndexationBuffer()
		}

		// SORT
		items = await this.s(items)

		if(requestId) {
			this.requests[requestId].state = STATES.TERMINATED
		}

		return singleItem ? items[0] : items
    }

	/**
	 * Retourne l'instance de base de données
	 * @returns {GroomyDB} Instance de base de données prete à etre utilisée
	 */
    db() {
        return this.#db
    }

	/**
	 * Retourne l'instance d'axios
	 * @returns {Request} Instance du client HTTP pret à requeter l'API
	 */
	api_requests() {
		return this.#api_requests
	}

    /**
     * Utilisation du transformer en dehors des collections
     * @param {Object|Dexie.Collection|Array} item contenu à fetch,transform,sort
     * @param {String} name nom du transformer
     */
    static process(item, name) {
        if (!item) {
            return
        }
        if (item.length === 0) {
            return []
        }

        const transformer = new this(name)
        return transformer.handle(item)
	}

	/**
	 * Point d'entrée pour le filtrage des items
	 * @param {Object} ctx Informations pour la récupération des items
	 * @param {String} requestId Identifiant unique de la requete
	 */
	async filter(ctx, requestId='default') {
		this.requests[requestId] = {
			items: this.requests[requestId] ? this.requests[requestId].items : {},
			state: STATES.RUNNING
		}

		const table = await this.db().t(this.table)

		// Filtres qui ne peuvent pas etre fait en "natif Dexie"
		const nonNativeOperators = [
			'mustContain',
			'mustNotContain',
			'doesntBeginWith',
			'endWith',
			'doesntEndWith',
			'isNotBetween'
		]

		// Pour exécuter en dernier les filtres non natifs (ne pas commencer par un AND alors qu'on peut éliminer des lignes avant)
		let orderedFilters = []
		const nonNativeFilters = []
		Object.keys(ctx.filter).sort().forEach((filterKey) => {
			let filter = ctx.filter[filterKey]

			// Vérifier si on doit prendre en compte le filtre
			const isNotEmpty = (filter.value === 0 || filter.value)
				|| (
					filter.operator == "isEmpty"
					|| filter.operator == "isNotEmpty"
					|| filter.operator == "isTrue"
					|| filter.operator == "isFalse"
				)

			if(isNotEmpty) {
				filter.isIndexed = this.isIndexed(table, filter.column)
				filter.isNative = !nonNativeOperators.includes(filter.operator)

				if (filter.isNative && filter.isIndexed) {
					orderedFilters.push(filter)
				}
				else {
					nonNativeFilters.push(filter)
				}
			}
		})

		// Mettre les filtres non natifs à la fin
		ctx.filter = orderedFilters.concat(nonNativeFilters)

		// Mettre à jour les items actuels pour enlever ceux qui ne correspondent pas aux nouveaux filtres
		const filteredItems = applyFilters(Object.values(this.requests[requestId].items), ctx.filter)
		this.requests[requestId].items = {}
		filteredItems.forEach(item => {
			this.requests[requestId].items[item[this.primaryKey]] = item
		})

		let query = table
		let needFetchFilters = []
		let needJSFilters = []

		// Application des filtres
		ctx.filter.forEach((filter) => {
			// Lorsqu'une colonne n'est pas indéxée on doit appeler fetchItemColumn
			if (!filter.isIndexed) {
				needFetchFilters.push(filter)
				return
			}

			if(filter.value instanceof Date) {
				needJSFilters.push(filter)
				return
			}

			// Si on a déjà appliqué un filtre on est obligé de passer par un JS and()
			const isCollection = query.toCollection === undefined
			if (isCollection || !filter.isNative) {
				// Si les seuls filtres qu'on a ne sont pas natifs, query est encore une table
				if (!isCollection) {
					query = query.toCollection()
				}

				query = query.and((item) => {
					return isValidItem(item, filter)
				})

				return
			}

			// On applique les méthodes Dexie quand on a encore une table
			query = query.where(filter.column)

			if(filter.operator === 'isEqualTo') {
				query = query.equals(filter.value)
			}
			else if(filter.operator === 'isNotEqualTo') {
				query = query.notEqual(filter.value)
			}
			else if(filter.operator === 'isLowerThan') {
				query = query.below(filter.value)
			}
			else if(filter.operator === 'isLowerOrEqualThan') {
				query = query.belowOrEqual(filter.value)
			}
			else if(filter.operator === 'isGreaterThan') {
				query = query.above(filter.value)
			}
			else if(filter.operator === 'isGreaterOrEqualThan') {
				query = query.aboveOrEqual(filter.value)
			}
			else if(filter.operator === 'isEmpty') {
				query = query.equals('')
			}
			else if(filter.operator === 'isNotEmpty') {
				query = query.notEqual('')
			}
			else if(filter.operator === 'isBetween') {
				const [lower, upper] = filter.value.split('|')
				query = query.between(lower, upper)
			}
			else if(filter.operator === 'isInList') {
				let value = filter.value
				if(typeof value == 'string') {
					value = value.split(',')
				}
				query = query.anyOf(value)
			}
			else if(filter.operator === 'isNotInList') {
				let value = filter.value
				if(typeof value == 'string') {
					value = value.split(',')
				}
				query = query.noneOf(value)
			}
			else if(filter.operator === 'isTrue') {
				query = query.equals(1)
			}
			else if(filter.operator === 'isFalse') {
				query = query.equals(0)
			}
			else if(filter.operator === 'beginWith') {
				query = query.startsWith(filter.value)
			}
			else {
				throw new Error('Unknow operator ' + filter.operator)
			}
		})

		if (ctx.limit) {
			query = query.limit(ctx.limit)
		}

		// Si aucun filtre n'a été appliqué
		const isCollection = query.toCollection === undefined
		if (!isCollection) {
			query = query.toCollection()
		}

		// Récupérer les items brut
		const items = await query.raw().toArray()

		// Récupérer les relations éventuelles et ajouter l'item au résultat de la requete
		for(let i = 0; i < items.length; i++) {
			if(this.requests[requestId].state === STATES.CANCELLED) {
				throw new CancelError()
			}

			const item = items[i]
			let isValid = true

			for(let j = 0; j < needJSFilters.length; j++) {
				const filter = needJSFilters[j]

				isValid = isValidItem(item, filter)
				if (!isValid) {
					break
				}
			}

			// Tous les filtres qui ont besoin d'une relation
			// Ralentit énormément l'algorithme donc à éviter
			if(isValid) {
				for (let j = 0; j < needFetchFilters.length; j++) {
					const filter = needFetchFilters[j]
					item[filter.column] = await this.fetchItemColumn(item, filter.column)

					if(!Object.prototype.hasOwnProperty.call(item, filter.column)) {
						item[filter.column] = null
					}

					isValid = isValidItem(item, filter)

					if (!isValid) {
						break
					}
				}
			}

			// this.requests[requestId] peut etre à undefined quand la requete a été annulée
			if (this.requests[requestId] && isValid) {
				this.requests[requestId].items[item[this.primaryKey]] = item
			}
			else if(this.requests[requestId]) {
				delete this.requests[requestId].items[item[this.primaryKey]]
			}
		}

		await this.submitIndexationBuffer()

		this.requests[requestId].state = STATES.TERMINATED
	}

	/**
	 * Formatte la chaine de caractère utilisée pour la recherche
	 * @param {Object} item Item pour lequel on veut récupérer les champs sur laquelle la recherche s'applique
	 * @returns {String} String dans laquelle on cherchera la valeur recherchée
	 */
	async stringified(item) {
		return Object.values(item).join('|')
	}

	/**
	 * Récupère la liste des ID de la page demandée par le ctx
	 * @param {String} requestId Identifiant de la requete de filtrage
	 * @param {Object} ctx Informations sur la page à récupérer
	 * @returns {Array} Liste des IDs de la page
	 */
	async pickItemsIds(requestId, ctx) {
		let start = 0
		let end
		if (ctx.perPage !== -1) {
			start = ctx.perPage * (ctx.currentPage - 1)
			end = start + ctx.perPage
		}

		if(this.requests[requestId].state === 0) {
			return []
		}

		if(!this.requests[requestId].sortedItems) {
			return []
		}

		return this.requests[requestId].sortedItems.slice(start, end)
	}

	/**
	 * Récupère les items avec les ID pageIds complètement transformés
	 * @param {Array} pageIds Liste des IDs des items à transformer
	 * @param {Object} ctx Informations utilisées pour le tri des items
	 * @param {String} requestId Identifiant de la requete
	 * @returns {Array} Liste des items transformés
	 */
	async retrievePage(pageIds, ctx, requestId) {
		if (pageIds.length === 0) {
			return []
		}

		const table = await this.db().t(this.table)
		const pkName = table.schema.primKey.name

		// Faire la requete classique grace aux IDs récupérés
		const col = await table
		.where(pkName)
		.anyOf(pageIds)

		const pageItems = await this.handle(col, requestId, ctx)

		await Common.asyncForEach(pageItems, async (item) => {
			item.stringified = await this.stringified(item)
		})
		
		// Il faut retrier car on ne reçoit pas nécessairement le résultat dans l'ordre des IDs
		return this.applySort(pageItems, ctx)
	}

	retrieveAll(requestId, ctx) {
		const ids = Object.keys(this.requests[requestId].items).map(id => (parseInt(id)))
		return this.retrievePage(ids, ctx)
	}

	/**
	 * Récupère le nombre total de lignes qui correspondent aux filtres
	 * @param {String} requestId Identifiant de la requete
	 * @returns {Number} Nombre total d'élements dans la liste
	 */
	getTotalRows(requestId) {
		// Compliqué mais plus rapide qu'un Object.keys(obj).length
		let hasOwn = Object.prototype.hasOwnProperty;
		let count = 0;

		for (let k in this.requests[requestId].items) if (hasOwn.call(this.requests[requestId].items, k)) ++count;

		return count
	}

	/**
	 * Permet de savoir si une requete a été annulée via cancel()
	 * @param {String} requestId Identifiant de la requete
	 * @returns {Boolean} True si la requete a été annulée
	 */
	isCancelled(requestId) {
		return this.requests[requestId] && this.requests[requestId].state === STATES.CANCELLED
	}

	/**
	 * Demande l'annulation d'une requete en cours
	 * @param {String} requestId Identifiant de la requete à annuler
	 */
	cancel(requestId) {
		if (this.requests[requestId] && this.requests[requestId].state !== STATES.TERMINATED) {
			this.requests[requestId].state = STATES.CANCELLED
		}
	}

	/**
	 * Demande l'annulation de toutes les requetes en cours
	 */
	cancelAll() {
		Object.keys(this.requests).forEach((requestId) => {
			this.cancel(requestId)
		})
	}

	/**
	 * Récupère la valeur d'une colonne présente dans le transformer
	 * @param {Object} item Item sur lequel il faut récupérer la colonne
	 * @param {String} col Nom de la colonne à récupérer sur le transformer
	 * @param {String} requestId Identifiant de la requete
	 */
	async fetchItemColumn(item, col, requestId) {
		// Récupérer la colonne pour pouvoir trier les items
		let colValue = Common.getNestedObjectString(item, col)
		if (col && colValue === undefined) {
			colValue = await this.callFn(item, col, requestId)
		}

		return colValue
	}

	/**
	 * Récupère la valeur d'une colonne du transformer
	 * Si la colonne est une méthode du transformer alors on appelle seulement cette méthode
	 * Sinon on transformer complètement l'item pour avoir la valeur de cette colonne
	 * @param {Object} item Item sur lequel il faut appeler la méthode du transformer
	 * @param {String} column Nom de la colonne
	 * @param {String} requestId Identifiant de la requete
	 * @returns {Object} Item avec la colonne récupérée à partir du transformer
	 */
	async callFn(item, column, requestId) {
		const pathParts = column.split('.')

		const ctx = {
			columns:[column]
		}

		// On ne peut pas appeler une colonne de transformer récursivement
		let firstPart = pathParts[0]

		if (this[firstPart]) {
			item[firstPart] = await this.fetchAdditionalColumn(item, firstPart)
		}

		const colValue = Common.getNestedObjectString(item, column)
		if(colValue !== undefined) {
			return colValue
		}

		item = await this.handle(item, requestId, ctx)
		return Common.getNestedObjectString(item, column)
	}

	async sortRequestItems(requestId, ctx) {
		const items = await this.applySort(this.requests[requestId].items, ctx)
		this.requests[requestId].sortedItems = items.map(item => item[this.primaryKey])
	}

	/**
	 * Tri des items en fonction d'un CTX
	 * @param {Array} items Items à trier
	 * @param {Object} ctx Informations sur le tri à effectuer
	 * @returns {Array} Nouvel array trié
	 */
	async applySort(items, ctx) {
		if (items.length === undefined) {
			items = Object.values(items)
		}

		if(!ctx.sortBy) {
			return items
		}

		const table = await this.db().t(this.table)
		const isIndexed = this.isIndexed(table, ctx.sortBy)

		if (!isIndexed) {
			await Common.asyncForEach(items, async (item) => {
				item[ctx.sortBy] = await this.fetchItemColumn(item, ctx.sortBy)
			})
		}

		return _orderBy(items, ctx.sortBy, ctx.sortDesc ? 'desc' : 'asc')
	}

	/**
	 * Permet la récupération du nom de la table liée au transformer
	 * @returns {String} Nom de la table
	 */
	getTable() {
		return this.table
	}

	/**
	 * Vérifie si l'utilisateur a les droits sur un certain module
	 * @param {String} code Code de module à vérifier
	 * @returns {Boolean} Indique si un utilisateur a le droit ou non sur ce module
	 */
	hasRight(code) {
		return accessRights.includes(code)
	}

	/**
	 * Formatte la date en fonction de la config de l'utilisateur
	 * @returns {String} Date formattée
	 */
	formatDate(date) {
		return dateFormat(date, dateConfig ?? 'dd/mm/yyyy')
	}

	/**
	 * Indique si une colonne fait partit des indexes d'une table
	 * @param {Dexie.Table} table Table sur laquelle il faut vérifier les indexes
	 * @param {String} column Nom de la colonne à vérifier
	 */
	isIndexed(table, column) {
		return table.schema.primKey.name === column
			|| table.schema.indexes.findIndex((idx) => idx.name === column) !== -1
	}

	/**
	 * Vide le cache de toutes les requetes de l'instance
	 */
	emptyCache() {
		this.#cachedColumns = {}

		Object.keys(this.requests).forEach((requestId) => {
			this.requests[requestId].items = {}
		})
	}

	/**
	 * Indexe les colonnes d'un élément de la table
	 * @param {Number} itemId ID de l'élément à mettre en cache
	 * @param {Array} columns Colonnes à mettre en cache
	 */
	async indexItemColumns(itemId, columns) {
		const table = await this.db().t(this.table)

		// On a besoin de l'objet complet lorsqu'on va chercher les relations
		let obj = await table.get(parseInt(itemId))

		if(!obj) {
			const index_ids = columns.map(colName => (`${this.table}_${itemId}_${colName}`))
			await this.db().t('_index_buffer').then(table => {
				return table.bulkDelete(index_ids)
			})

			return
		}

		// Aller chercher les infos grace au transformer
		await Common.asyncForEach(columns, async (columnName) => {
			// Pour forcer la réindexation on delete l'ancienne valeur
			delete obj[columnName]
			obj[columnName] = await this.fetchItemColumn(obj, columnName)
		})

		await this.submitIndexationBuffer()

		this.emptyCache()
	}

	async isIndexingTable() {
		return this.db().t('_index_buffer')
		.then(table => {
			return table.where('table_name').equals(this.table).count()
		})
		.then(count => {
			return count > 0
		})
	}

	/**
	 * Récupère la liste des ID entre l'item a de la page lastPageId et l'item b de la page du ctx
	 * @param {Number} lastPageId Page de l'item a
	 * @param {String} requestId Identifiant de la requete de filtrage
	 * @param {Object} ctx Informations sur la page à récupérer
	 * @param {Number} a Item de départ
	 * @param {Number} b Item d'arrivé
	 * @returns {Array} Liste des IDs de la page
	 */
	async pickItemsBetweenIds(lastPageId, requestId, ctx, a, b) {
		let start = 0
		let end
		if (ctx.perPage !== -1) {
			start = (ctx.perPage * (lastPageId - 1)) + a + 1
			end = (ctx.perPage * (ctx.currentPage - 1)) + b
		}

		if(this.requests[requestId].state === 0) {
			return []
		}

		if(!this.requests[requestId].sortedItems) {
			return []
		}

		return this.requests[requestId].sortedItems.slice(start, end)
	}

}

export default Transformer
