/* eslint-disable no-console */

import { register } from 'register-service-worker'
import { EventBus } from 'EventBus'

if (process.env.NODE_ENV === 'production') {
  register(`${process.env.BASE_URL}service-worker.js`, {
    ready () {
      // Utilisation du cache
      console.log('ready')
    },
    registered () {
      // Le Service Worker a été initialisé
      console.log('registered')
    },
    cached () {
      // Les fichiers ont été téléchargés
      console.log('cached')
    },
    updatefound () {
      // Début du téléchargement des fichiers
      console.log('updateFound')
      window.groomyPwa.updateState = 'UPDATE_FOUND'
    },
    updated () {
      // Le nouveau contenu a été téléchargé et sera prit en compte au prochain refresh
      console.log('updated')
      window.groomyPwa.updateState = 'UPDATE_READY'
      EventBus.$emit('SW::updated')
    },
    offline () {
      // L'application est hors ligne et utilise le cache
      console.log('offline')
    },
    error () {
      // Une erreur s'est produite (le premier paramètre représente l'erreur)
      console.log('error')
    }
  })
}
