let theme = window.localStorage.getItem('theme')
if (theme) {
	theme = JSON.parse(theme)
}
else {
	if (window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches) {
		theme = 'dark'
	}
	else {
		theme = 'light'
	}
	window.localStorage.setItem('theme', JSON.stringify(theme))
}

if (theme === 'dark') {
	import('@/assets/scss/themes-app/app-dark.scss')
}
else if (theme === 'contraste') {
	import('@/assets/scss/themes-app/app-contraste.scss')
}
else{
	import('@/assets/scss/themes-app/app-light.scss')
}