import Constants from '../../config.local.js'
import AccountingPlanTransformer from '@/assets/js/dexie/transformers/AccountingPlanTransformer.js'
import AccountingAccountTransformer from '@/assets/js/dexie/transformers/AccountingAccountTransformer.js'
import ActeTypeTransformer from '../assets/js/dexie/transformers/ActeTypeTransformer.js'
import CurrencyTransformer from '@/assets/js/dexie/transformers/CurrencyTransformer'

var AccountingMixin = {
    methods: {
        // Charge la liste des plan comptables depuis la BDD pour l'utilisateur courant
        loadAccountingPlans: async function() {
            const url = this.constructRoute(Constants.ACCOUNTING_PLAN_URL, {}) + "?licence_key="+Constants.USER_LICENCE_KEY
            const result = await this.$request.request_get_api("AccountingMixin::loadAccountingPlan", url)
            .catch(error => {
                console.error("AccountingMixin::loadAccountingPlans => ERROR", error)
                return null
            })
            if(result) return result.retour
            return null
        },

        loadAccountingPlansByUser: async function($user_licence_key, $user_licence_id) {
			const params = { 'user_licence_key': $user_licence_key, 'user_licence_id': $user_licence_id }
            const url = this.constructRoute(Constants.ACCOUNTING_PLAN_URL_BY_USER, params) + "?licence_key="+Constants.USER_LICENCE_KEY
            const result = await this.$request.request_get_api("AccountingMixin::loadAccountingPlansByUser", url)
            .catch(error => {
                console.error("AccountingMixin::loadAccountingPlansByUser => ERROR", error)
                return null
            })
            if(result) return result.retour
            return null
        },

		// Charge la liste des plan comptables archivés depuis la BDD pour l'utilisateur courant
		loadArchivedAccountingPlans: async function() {
			const url = this.constructRoute(Constants.ARCHIVED_ACCOUNTING_PLAN_URL, {}) + "?licence_key="+Constants.USER_LICENCE_KEY
			const result = await this.$request.request_get_api("AccountingMixin::loadArchivedAccountingPlans", url)
			.catch(error => {
				console.error("AccountingMixin::loadArchivedAccountingPlans => ERROR", error)
				return null
			})
			if(result) return result.retour
			return null
		},

		// Récupère un plan comptable depuis la BDD pour l'utilisateur courant
		loadAccountingPlan: async function(accountingplan_id) {
			const params = { 'accountingplan_id': accountingplan_id }
			const url = this.constructRoute(Constants.ACCOUNTING_PLAN_GET_URL, params) + "?licence_key="+Constants.USER_LICENCE_KEY
			const result = await this.$request.request_get_api("AccountingMixin::loadAccountingPlan", url)
			.catch(error => {
				console.error("AccountingMixin::loadAccountingPlan => ERROR", error)
				return null
			})
			if(result) return result.retour
			return null
		},

		// Récupère un plan comptable
		loadAccountingPlanByEntity: async function(entity_id) {
			return this.$storage.db.t('accounting_plan')
			.then(table => {
			    return table.where({accountingplan_tiers: parseInt(entity_id)}).first()
			})
			.then(item => {
                return AccountingPlanTransformer.process(item)
            })
		},

		// Récupère le plan comptable par défaut
		loadAccountingFromLocal: async function() {
			return this.$storage.db.t('accounting_plan')
			.then(table => {
				return table.orderBy('accountingplan_label')
			})
			.then(col => {
                return col.transform(new AccountingPlanTransformer())
            })
		},
		loadAccountingPlanDefault: async function() {
			return this.$storage.db.t('accounting_plan')
			.then(table => {
			    return table.where({
					accountingplan_default: 1
				})
				.first()
			})
			.then(item => {
                return AccountingPlanTransformer.process(item)
            })
		},
		// Récupère la 1ere compta si pas de défaut
		loadFirstAccountingPlan: async function() {
			return this.$storage.db.t('accounting_plan')
			.then(table => {
			    return table.where({
					accountingplan_valide: 1
				}).first()
			})
			.then(item => {
                return AccountingPlanTransformer.process(item)
            })
		},

		loadAccountingAccountsFromLocal: async function(accountingplan_id){
			return await this.$storage.db.t('accounting_account')
			.then(table => {
				return table.where({
					accountingaccount_accountingplan: parseInt(accountingplan_id)
				})
			})
			.then(col => {
				return col.transform(new AccountingAccountTransformer('withAccountingPlanLite'))
			})
		},

		loadAccountingPlansPensionType: async function() {
			const url = this.constructRoute(Constants.ACCOUNTING_PLAN_WITH_PENSION_TYPE_URL, {}) + "?licence_key="+Constants.USER_LICENCE_KEY
			const result = await this.$request.request_get_api("AccountingMixin::loadAccountingPlansPensionType", url)
			.catch(error => {
				console.error("AccountingMixin::loadAccountingPlansPensionType => ERROR", error)
				return null
			})
			if(result) return result.retour
			return null
		},

		loadPensionType: async function(accountingplan_id) {
			const params = { 'accountingplan_id': accountingplan_id }
			const url = this.constructRoute(Constants.ACCOUNTING_PENSION_TYPE_URL, params) + "?licence_key="+Constants.USER_LICENCE_KEY
			const result = await this.$request.request_get_api("AccountingMixin::loadPension", url)
			.catch(error => {
				console.error("AccountingMixin::loadPension => ERROR", error)
				return null
			})
			if(result) return result.retour
			return null
		},

		loadAccountingPlansArticles: async function() {
			const url = this.constructRoute(Constants.ACCOUNTING_PLAN_WITH_ARTICLES_URL, {}) + "?licence_key="+Constants.USER_LICENCE_KEY
			const result = await this.$request.request_get_api("AccountingMixin::loadAccountingPlansArticles", url)
			.catch(error => {
				console.error("AccountingMixin::loadAccountingPlansArticles => ERROR", error)
				return null
			})
			if(result) return result.retour
			return null
		},

		// Charge la liste des articles d'une comptabilite depuis la BDD pour l'utilisateur courant
		loadArticles: async function(accountingplan_id) {
			const params = { 'accountingplan_id': accountingplan_id }
			const url = this.constructRoute(Constants.ACCOUNTING_ARTICLES_URL, params) + "?licence_key="+Constants.USER_LICENCE_KEY
			const result = await this.$request.request_get_api("AccountingMixin::loadArticles", url)
			.catch(error => {
				console.error("AccountingMixin::loadArticles => ERROR", error)
				return null
			})
			if(result) return result.retour
			return null
		},

		// Charge la liste des articles d'une comptabilite avec les articles inactifs
		loadAllArticles: async function(accountingplan_id) {
			const params = { 'accountingplan_id': accountingplan_id }
			const url = this.constructRoute(Constants.ACCOUNTING_ALL_ARTICLES_URL, params) + "?licence_key="+Constants.USER_LICENCE_KEY
			const result = await this.$request.request_get_api("AccountingMixin::loadArticles", url)
			.catch(error => {
				console.error("AccountingMixin::loadArticles => ERROR", error)
				return null
			})
			if(result) return result.retour
			return null
		},

		loadActesTypeArticles: async function(accountingplan_id, actestype_id) {
			const params = { 'accountingplan_id': accountingplan_id, 'actestype_id': actestype_id }
			const url = this.constructRoute(Constants.ACCOUNTING_ACTES_TYPE_ARTICLES_URL, params) + "?licence_key="+Constants.USER_LICENCE_KEY
			const result = await this.$request.request_get_api("AccountingMixin::loadActesTypeArticles", url)
			.catch(error => {
				console.error("AccountingMixin::loadActesTypeArticles => ERROR", error)
				return null
			})
			if(result) return result.retour
			return null
		},

		// Utilisé seulement sur les actes donc on peut les garder hors ligne
		loadAccountingPlanActesTypeArticles: async function(actestype_id) {
			return this.$storage.db.t('horse_actes_type')
			.then(table => {
				return table.get(parseInt(actestype_id))
			})
			.then(actestype => {
				return ActeTypeTransformer.process(actestype, 'withArticles')
			})
			.then(actestype => {
				return actestype.articles
			})
		},

		loadArticlesByEntity: async function(entity_id) {
			const params = { 'tiers_id': entity_id }
			const url = this.constructRoute(Constants.TIERS_ARTICLES_URL, params) + "?licence_key="+Constants.USER_LICENCE_KEY
			const result = await this.$request.request_get_api("AccountingMixin::loadArticlesByEntity", url)
			.catch(error => {
				console.error("AccountingMixin::loadArticlesByEntity => ERROR", error)
				return null
			})
			if(result) return result.retour
			return null
		},

		// Charge la liste des comptes comptables d'une comptabilite depuis la BDD pour l'utilisateur courant
		loadAccountingAccounts: async function(accountingplan_id) {
			const params = { 'accountingplan_id': accountingplan_id }
			const url = this.constructRoute(Constants.ACCOUNTING_ACCOUNT_ACCOUNTING_URL, params) + "?licence_key="+Constants.USER_LICENCE_KEY
			const result = await this.$request.request_get_api("AccountingMixin::loadComptesComptables", url)
			.catch(error => {
				console.error("AccountingMixin::loadComptesComptables => ERROR", error)
				return null
			})
			if(result) return result.retour
			return null
		},

		loadAccountingPaymentAccounts: async function(accountingplan_id) {
			const params = { 'accountingplan_id': accountingplan_id }
			const url = this.constructRoute(Constants.ACCOUNTING_PAYMENT_ACCOUNT_ACCOUNTING_URL, params) + "?licence_key="+Constants.USER_LICENCE_KEY
			const result = await this.$request.request_get_api("AccountingMixin::loadAccountingPaymentAccounts", url)
			.catch(error => {
				console.error("AccountingMixin::loadAccountingPaymentAccounts => ERROR", error)
				return null
			})
			if(result) return result.retour
			return null
		},

		//Charge les comptes comptables à partir d'un tiers id (Entité de facturation)
		loadAccountingAccountsTiers: async function(tiers_id) {
			const params = { 'tiers_id': tiers_id }
			const url = this.constructRoute(Constants.TIERS_ACCOUNTING_ACCOUNT_URL, params) + "?licence_key="+Constants.USER_LICENCE_KEY
			const result = await this.$request.request_get_api("AccountingMixin::loadAccountingAccountsTiers", url)
			.catch(error => {
				console.error("AccountingMixin::loadAccountingAccountsTiers => ERROR", error)
				return null
			})
			if(result) return result.retour
			return null
		},

		loadCurrencies() {
			return this.$storage.db.t('currency')
			.then(table => {
				return table.orderBy('currency_name')
			})
			.then(col => {
				return col.transform(new CurrencyTransformer())
			})
		},

        // Ajoute une comptabilité
        addAccountingPlan: async function(
            accountingplan_tiers,
            accountingplan_label,
            accountingplan_sales_journal,
            accountingplan_bank_journal,
            accountingplan_fromnumber,
            numbertemplate_prefix,
            numbertemplate_pattern,
            accountingplan_nextclientaccount,
            client_numbertemplate_prefix,
            client_numbertemplate_pattern,
			accountingplan_default,
			accountingplan_clientaccountfromdefault,
			accountingplan_closing_date,
			accountingplan_rounded,
			accountingplan_format_date,
			salon = false,
			accountingplan_supplier_journal
        ) {
            let url = Constants.ACCOUNTING_PLAN_URL+"?licence_key="+Constants.USER_LICENCE_KEY
            if(salon) {
            	url += '&salon=true';
            }

            const infos = {
                accountingplan_tiers,
                accountingplan_label,
                accountingplan_sales_journal,
                accountingplan_bank_journal,
                accountingplan_fromnumber,
                numbertemplate_prefix,
                numbertemplate_pattern,
                accountingplan_nextclientaccount,
                client_numbertemplate_prefix,
                client_numbertemplate_pattern,
				accountingplan_default,
				accountingplan_clientaccountfromdefault,
				accountingplan_closing_date,
				accountingplan_rounded,
				accountingplan_format_date,
				accountingplan_supplier_journal
            }

			const response = await this.$request.request_post_api("AccountingMixin::addAccountingPlan", url, infos, false)
			.catch(e => {
				console.error("AccountingMixin::addAccountingPlan", e)
				if(e.response && e.response.data) {
					if(e.response.data.code_retour == "AE") {
						this.failureToast("toast.compta_default_exists")
					}
					else if(e.response.data.code_retour == "NOR") {
						this.failureToast("toast.no_compta_default")
					}
				}
				else {
					this.failureToast("toast.info_save_failed")
				}
			})

			return response
		},

        // Modifie une comptabilité
        editAccountingPlan: async function(
            accountingplan_id,
            accountingplan_tiers,
            accountingplan_label,
            accountingplan_sales_journal,
            accountingplan_bank_journal,
            accountingplan_fromnumber,
            numbertemplate_prefix,
            numbertemplate_pattern,
            accountingplan_nextclientaccount,
            client_numbertemplate_prefix,
            client_numbertemplate_pattern,
			accountingplan_default,
			accountingplan_clientaccountfromdefault,
			accountingplan_closing_date,
			accountingplan_rounded,
			accountingplan_format_date,
			accountingplan_supplier_journal
        ) {
            const url = Constants.ACCOUNTING_PLAN_URL+"/"+accountingplan_id+"?licence_key="+Constants.USER_LICENCE_KEY

            const infos = {
                accountingplan_tiers,
                accountingplan_label,
                accountingplan_sales_journal,
                accountingplan_bank_journal,
                accountingplan_fromnumber,
                numbertemplate_prefix,
                numbertemplate_pattern,
                accountingplan_nextclientaccount,
                client_numbertemplate_prefix,
                client_numbertemplate_pattern,
				accountingplan_default,
				accountingplan_clientaccountfromdefault,
				accountingplan_closing_date,
				accountingplan_rounded,
				accountingplan_format_date,
				accountingplan_supplier_journal
            }

			const response = await this.$request.request_post_api("AccountingMixin::editAccountingPlan", url, infos, false)
			.catch(e => {
				console.error("AccountingMixin::editAccountingPlan", e)
				if(e.response && e.response.data) {
					if(e.response.data.code_retour == "AE") {
						this.failureToast("toast.compta_default_exists")
					}
					else if(e.response.data.code_retour == "NOR") {
						this.failureToast("toast.no_compta_default")
					}
				}
				else {
					this.failureToast("toast.info_save_failed")
				}
			})

			return response
		},

		// Duplique une comptabilité
        duplicateAccountingPlan: async function(
            accountingplan_id,
            accountingplan_tiers,
            accountingplan_label,
            accountingplan_sales_journal,
            accountingplan_bank_journal,
            accountingplan_fromnumber,
            numbertemplate_prefix,
            numbertemplate_pattern,
            accountingplan_nextclientaccount,
            client_numbertemplate_prefix,
            client_numbertemplate_pattern,
			accountingplan_default,
			accountingplan_clientaccountfromdefault,
			accountingplan_closing_date,
			accountingplan_rounded,
			accountingplan_format_date,
			accountingplan_supplier_journal
        ) {
            const url = this.constructRoute(Constants.ACCOUNTING_PLAN_DUPLICATE_URL, {accountingplan_id: accountingplan_id}) + "?licence_key="+Constants.USER_LICENCE_KEY

            const infos = {
                accountingplan_tiers,
                accountingplan_label,
                accountingplan_sales_journal,
                accountingplan_bank_journal,
                accountingplan_fromnumber,
                numbertemplate_prefix,
                numbertemplate_pattern,
                accountingplan_nextclientaccount,
                client_numbertemplate_prefix,
                client_numbertemplate_pattern,
				accountingplan_default,
				accountingplan_clientaccountfromdefault,
				accountingplan_closing_date,
				accountingplan_rounded,
				accountingplan_format_date,
				accountingplan_supplier_journal
            }

			const response = await this.$request.request_post_api("AccountingMixin::duplicateAccountingPlan", url, infos, false)
			.catch(e => {
				console.error("AccountingMixin::duplicateAccountingPlan", e)
				if(e.response && e.response.data) {
					if(e.response.data.code_retour == "AE") {
						this.failureToast("toast.compta_default_exists")
					}
					else if(e.response.data.code_retour == "NOR") {
						this.failureToast("toast.no_compta_default")
					}
				}
				else {
					this.failureToast("toast.info_save_failed")
				}
			})

			return response
		},

		//Récupère les configurations de remises de cheques
		getCheckDepositConfigListe: async function(accountingplan_id) {
			const params = { 'accountingplan_id': accountingplan_id }
			const url = this.constructRoute(Constants.ACCOUNTING_REMISE_CHEQUE_CONFIG_URL, params) + "?licence_key="+Constants.USER_LICENCE_KEY
			const result = await this.$request.request_get_api("AccountingMixin::getCheckDepositConfigListe", url)
			.catch(error => {
				console.error("AccountingMixin::getCheckDepositConfigListe => ERROR", error)
				return null
			})
			if(result) return result.retour
			return null
		},

		getAllCheckDepositConfigListe: async function(accountingplan_id) {
			const params = { 'accountingplan_id': accountingplan_id }
			const url = this.constructRoute(Constants.ACCOUNTING_ALL_REMISE_CHEQUE_CONFIG_URL, params) + "?licence_key="+Constants.USER_LICENCE_KEY
			const result = await this.$request.request_get_api("AccountingMixin::getAllCheckDepositConfigListe", url)
			.catch(error => {
				console.error("AccountingMixin::getAllCheckDepositConfigListe => ERROR", error)
				return null
			})
			if(result) return result.retour
			return null
		},

		//A ne pas utiliser ailleurs que dans le contract config (Pas ouf mais on liste toutes les configs peu importe la compta)
		getAllCheckDepositConfig: async function() {
			const params = {}
			const url = this.constructRoute(Constants.ALL_REMISE_CHEQUE_CONFIG_URL, params) + "?licence_key="+Constants.USER_LICENCE_KEY
			const result = await this.$request.request_get_api("AccountingMixin::getAllCheckDepositConfig", url)
			.catch(error => {
				console.error("AccountingMixin::getAllCheckDepositConfig => ERROR", error)
				return null
			})
			if(result) return result.retour
			return null
		},

        updateCreateCheckDepositConfig: async function(form, accountingplan_id) {

        	const params = { 'accountingplan_id': accountingplan_id }
        	const url = this.constructRoute(Constants.ACCOUNTING_REMISE_CHEQUE_CONFIG_URL, params) + "?licence_key="+Constants.USER_LICENCE_KEY

			const response = await this.$request.request_post_api("AccountingMixin::updateCreateCheckDepositConfig", url, form, false)
			.catch(e => {
				console.error("AccountingMixin::updateCreateCheckDepositConfig", e)
				this.failureToast("toast.info_save_failed")
			})

			return response
		},

        deleteCheckDepositConfig: async function(accountingplan_id, checkdepositconfiguration_id) {

        	const params = { 'accountingplan_id': accountingplan_id, 'checkdepositconfiguration_id': checkdepositconfiguration_id}

        	const url = this.constructRoute(Constants.ACCOUNTING_REMISE_CHEQUE_CONFIG_DELETE_URL, params) + "?licence_key="+Constants.USER_LICENCE_KEY

			const response = await this.$request.request_delete_api("AccountingMixin::deleteCheckDepositConfig", url, {})
			.catch(e => {
				console.log(e);
				console.error("AccountingMixin::deleteCheckDepositConfig", e)
				this.failureToast("toast.info_save_failed")
				return null
			})

			return response
		},

		updateExport: async function(export_id, params) {
			const id = { 'export_id': export_id }

        	const url = this.constructRoute(Constants.EXPORT_PERSO_UPDATE, id) + "?licence_key="+Constants.USER_LICENCE_KEY

			const response = await this.$request.request_post_api("AccountingMixin::updateExport", url, params, false)
			.catch(e => {
				console.log(e);
				console.error("AccountingMixin::updateExport", e)
				this.failureToast("toast.info_save_failed")
				return null
			})

			return response
		},

		createExport: async function(params) {
			const url = Constants.EXPORT_PERSO_CREATE + "?licence_key="+Constants.USER_LICENCE_KEY

			const response = await this.$request.request_post_api("AccountingMixin::createExport", url, params, false)
			.catch(e => {
				console.log(e);
				console.error("AccountingMixin::createExport", e)
				this.failureToast("toast.info_save_failed")
				return null
			})

			return response
		},

		getExportPerso: async function(licence_key) {
			const url = this.constructRoute(Constants.EXPORT_PERSO_GET, {licence_key: licence_key}) + "?licence_key="+Constants.USER_LICENCE_KEY
			const result = await this.$request.request_get_api("AccountingMixin::getExportPerso", url)
			.catch(error => {
				console.error("AccountingMixin::getExportPerso => ERROR", error)
				return null
			})
			if(result) return result.retour
			return null
		},

		getExportAccountingPlan: async function(accountingplan_id) {
			const url = this.constructRoute(Constants.EXPORT_PERSO_GET_ACCOUNTING, {accountingplan_id: accountingplan_id}) + "?licence_key="+Constants.USER_LICENCE_KEY
			const result = await this.$request.request_get_api("AccountingMixin::getExportAccountingPlan", url)
			.catch(error => {
				console.error("AccountingMixin::getExportAccountingPlan => ERROR", error)
				return null
			})
			if(result) return result.retour
			return null
		},

		selectExportPerso: async function(export_id, accountingplan_id) {
			const params = { 'export_id':export_id, 'accountingplan_id':accountingplan_id }
        	const url = Constants.EXPORT_PERSO_DEFAULT + "?licence_key="+Constants.USER_LICENCE_KEY

			const response = await this.$request.request_post_api("AccountingMixin::selectExportPerso", url, params, false)
			.catch(e => {
				console.error("AccountingMixin::selectExportPerso", e)
				this.failureToast("toast.info_save_failed")
				return null
			})

			return response
		},

		regenerateClientAccount: async function(accountingplan_id) {
			const params = { 'accountingplan_id': accountingplan_id }
        	const url = Constants.ACCOUNTING_PLAN_CLIENTACCOUNT_GENERATE + "?licence_key="+Constants.USER_LICENCE_KEY

			const response = await this.$request.request_post_api("AccountingMixin::regenerateClientAccount", url, params, false)
			.catch(e => {
				console.error("AccountingMixin::regenerateClientAccount", e)
				this.failureToast("toast.info_save_failed")
				return null
			})

			return response
		},

		archiveAccountingPlan: async function(accounting_plan_ids) {
			const url = this.constructRoute(Constants.ARCHIVE_ACCOUNTING_PLAN_URL, {accounting_plan_ids}) + "?licence_key="+Constants.USER_LICENCE_KEY
			const result = await this.$request.request_get_api("AccountingMixin::archiveAccountingPlan", url)
			.catch(error => {
				console.error("AccountingMixin::archiveAccountingPlan => ERROR", error)
				return null
			})
			if(result) return result.retour
			return null
		},
        
		unarchiveAccountingPlan: async function(accounting_plan_ids) {
			const url = this.constructRoute(Constants.UNARCHIVE_ACCOUNTING_PLAN_URL, {accounting_plan_ids}) + "?licence_key="+Constants.USER_LICENCE_KEY
			const result = await this.$request.request_get_api("AccountingMixin::unarchiveAccountingPlan", url)
			.catch(error => {
				console.error("AccountingMixin::unarchiveAccountingPlan => ERROR", error)
				return null
			})
			if(result) return result.retour
			return null
		},

		getTrainerCode: async function(accountingplan_id) {
			const url = this.constructRoute(Constants.GET_TRAINER_CODE_URL, {accountingplan_id}) + "?licence_key="+Constants.USER_LICENCE_KEY
			const result = await this.$request.request_get_api("AccountingMixin::getTrainerCode", url)
			.catch(error => {
				console.error("AccountingMixin::getTrainerCode => ERROR", error)
				return null
			})
			if(result) return result.retour
			return null
		},

		saveTrainerCode: async function(accountingplan_id, trainer_code) {
			const params = {
				trainer_code: trainer_code
			}

			const url = this.constructRoute(Constants.SAVE_TRAINER_CODE_URL, {accountingplan_id}) + "?licence_key="+Constants.USER_LICENCE_KEY
			const result = await this.$request.request_post_api("AccountingMixin::saveTrainerCode", url, params, false)
			.catch(error => {
				console.error("AccountingMixin::saveTrainerCode => ERROR", error)
				return null
			})
			if(result) return result.retour
			return null
		},

		getTrainerJockeyArticles: async function(accountingplan_id) {
			const url = this.constructRoute(Constants.GET_TRAINER_JOCKEY_ARTICLE_URL, {accountingplan_id}) + "?licence_key="+Constants.USER_LICENCE_KEY
			const result = await this.$request.request_get_api("AccountingMixin::getTrainerJockeyArticle", url)
			.catch(error => {
				console.error("AccountingMixin::getTrainerJockeyArticle => ERROR", error)
				return null
			})
			if(result) return result.retour
			return null
		},

		saveTrainerJockeyArticles: async function(accountingplan_id, jockey_article, trainer_article) {
			const params = {
				trainer_article: trainer_article,
				jockey_article: jockey_article
			}

			const url = this.constructRoute(Constants.SAVE_TRAINER_JOCKEY_ARTICLE_URL, {accountingplan_id}) + "?licence_key="+Constants.USER_LICENCE_KEY
			const result = await this.$request.request_post_api("AccountingMixin::saveTrainerJockeyArticle", url, params, false)
			.catch(error => {
				console.error("AccountingMixin::saveTrainerJockeyArticle => ERROR", error)
				return null
			})
			if(result) return result.retour
			return null
		},

		syncPerformances: async function() {
			const url = Constants.SYNC_PERFOMANCES+"?licence_key="+Constants.USER_LICENCE_KEY
			const result = await this.$request.request_post_api("AccountingMixin::syncPerformances", url, {}, false)
			.catch(e => {
				console.error("AccountingMixin::syncPerformances => ", e)
				return null
			})
		
			if(result) return result.retour
			return null
		},

		checkHasReachEntitySupplierLimit: async function(accountingplan_id) {
			const accounting_plans = await this.loadAccountingPlans()
			const accounting_plans_supplier = accounting_plans.filter(acc => acc.accountingplan_supplier)

			return accounting_plans_supplier.length >= this.getUserEntitySupplierLimit()
		},

		editAccountingPlanSupplier: async function(accountingplan_id, accountingplan_supplier) {
            const url = this.constructRoute(Constants.ACCOUNTING_PLAN_SUPPLIER_URL, {accountingplan_id})+"?licence_key="+Constants.USER_LICENCE_KEY

			const result = await this.$request.request_post_api("AccountingMixin::editAccountingPlanSupplier", url, {accountingplan_supplier}, false)
			.catch(e => {
				console.error("AccountingMixin::editAccountingPlanSupplier", e)
			})
		
			if(result) return result.retour
			return null
		},

		addBridgeConnection: async function() {
            const url = Constants.BRIDGE_URL+"?licence_key="+Constants.USER_LICENCE_KEY

			const result = await this.$request.request_post_api("AccountingMixin::BRIDGE_URL", url, {}, false)
			.catch(e => {
				console.error("AccountingMixin::BRIDGE_URL", e)
			})
		
			if(result) return result.retour
			return null
		},

		getBridgeConnection: async function() {
            const url = Constants.BRIDGE_ITEMS_URL+"?licence_key="+Constants.USER_LICENCE_KEY

			const result = await this.$request.request_get_api("AccountingMixin::getBridgeConnection", url)
			.catch(e => {
				console.error("AccountingMixin::getBridgeConnection", e)
			})
		
			if(result) return result.retour
			return null
		},

		refreshBridgeConnection: async function(item_id) {
            const url = this.constructRoute(Constants.BRIDGE_ITEMS_REFRESH_URL, {item_id})+"?licence_key="+Constants.USER_LICENCE_KEY

			const result = await this.$request.request_get_api("AccountingMixin::refreshBridgeConnection", url)
			.catch(e => {
				console.error("AccountingMixin::refreshBridgeConnection", e)
			})
		
			if(result) return result.retour
			return null
		},

		getBridgeAccounts: async function() {
            const url = Constants.BRIDGE_ACCOUNTS_URL+"?licence_key="+Constants.USER_LICENCE_KEY

			const result = await this.$request.request_get_api("AccountingMixin::getBridgeAccounts", url)
			.catch(e => {
				console.error("AccountingMixin::getBridgeAccounts", e)
			})
		
			if(result) return result.retour
			return null
		},

		updateBridgeAccounts: async function() {
            const url = Constants.BRIDGE_ACCOUNTS_UPDATE_URL+"?licence_key="+Constants.USER_LICENCE_KEY

			const result = await this.$request.request_get_api("AccountingMixin::updateBridgeAccounts", url)
			.catch(e => {
				console.error("AccountingMixin::updateBridgeAccounts", e)
			})
		
			if(result) return result.retour
			return null
		},

		getBridgeTransaction: async function(start, end) {
            const url = Constants.BRIDGE_TRANSACTIONS_URL+"?licence_key="+Constants.USER_LICENCE_KEY+"&start="+start+"&end="+end

			const result = await this.$request.request_get_api("AccountingMixin::getBridgeTransaction", url)
			.catch(e => {
				console.error("AccountingMixin::getBridgeTransaction", e)
			})
		
			if(result) return result.retour
			return null
		},

		getPotentialsInvoicesByBridgeTransaction: async function(transaction_id) {
            const url = this.constructRoute(Constants.BRIDGE_TRANSACTION_SUGGESTION_URL, {transaction_id})+"?licence_key="+Constants.USER_LICENCE_KEY

			const result = await this.$request.request_get_api("AccountingMixin::getPotentialsInvoicesByBridgeTransaction", url)
			.catch(e => {
				console.error("AccountingMixin::getPotentialsInvoicesByBridgeTransaction", e)
			})
		
			if(result) return result.retour
			return null
		},

		searchInvoiceBridgeTransaction: async function(supplier, search_value) {
            const url = Constants.BRIDGE_TRANSACTION_SEARCH_URL+"?licence_key="+Constants.USER_LICENCE_KEY

			const result = await this.$request.request_post_api("AccountingMixin::searchInvoiceBridgeTransaction", url, {supplier, search_value}, false)
			.catch(e => {
				console.error("AccountingMixin::searchInvoiceBridgeTransaction", e)
			})
		
			if(result) return result.retour
			return null
		},

		assignBridgeTransaction: async function(transaction_id, supplier, invoices_ids, memo) {
            const url = this.constructRoute(Constants.BRIDGE_TRANSACTION_ASSIGNATION_URL, {transaction_id})+"?licence_key="+Constants.USER_LICENCE_KEY

			const result = await this.$request.request_post_api("AccountingMixin::assignBridgeTransaction", url, {supplier, invoices_ids, memo}, false)
			.catch(e => {
				console.error("AccountingMixin::assignBridgeTransaction", e)
			})
		
			if(result) return result.retour
			return null
		}
	}
}

export default AccountingMixin
