import CacheCleaner from "./CacheCleaner"
import HorseCleaner from "./HorseCleaner"

export default class HorseCategorieCleaner extends CacheCleaner {

	async onMutation(categorie_ids) {
		await this.init()

		// Vider le cache de tous les chevaux avec ces catégorie
		return this.db().t('horse_categorie_lien')
		.then(table => {
			return table.where('lien_categorie').anyOf(categorie_ids).toArray()
		})
		.then(categorie => {
			return Array.from(new Set(categorie.map(r => r.lien_horse)))
		})
		.then(horse_ids => {
			if(horse_ids[0] != undefined) {
				return this.onMutationHorse(horse_ids)
			}
			else {
				return true
			}
			
		})
	}

	// Horse est après parce qu'on a bien update une catégorie
	// mais on donne des horse_ids en paramètre
	async onMutationHorse(horse_ids) {
		await HorseCleaner.inst().onMutation(horse_ids, ['horse_categories'])
	}

	// Event appelé lorsque la catégorie est modifié
	async onCategorieLienMutation(lien_ids) {
		await this.init()

		const categorie = await this.db().t('horse_categorie_lien')
		.then(table => {
			return table
				.where('lien_id')
				.anyOf(lien_ids)
				.toArray()
		})

		const horse_ids = categorie.map((r) => (r.lien_horse))

		// Récup tous les horse_residence avec ces lieux et invalider le cache des chevaux
		await HorseCleaner.inst().onMutation(horse_ids, ['horse_categories'])
	}
}
