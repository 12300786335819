import Transformer from './Transformer'

/**
 * Retourne une liste de
 * @param {Dexie.Collection} actes
 */
export default class ActeTransformer extends Transformer {

    async transform (currency) {
        return {
            currency_id: currency.currency_id,
            currency_code: currency.currency_code,
            currency_symbol: currency.currency_symbol,
            currency_decimal_digits: currency.currency_decimal_digits,
            currency_name: currency.currency_name,
            currency_name_plural: currency.currency_name_plural,
        }
    }
}
