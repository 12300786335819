import Transformer from './Transformer'
import ArticleTransformer from './ArticleTransformer'
import VatTransformer from './VatTransformer'

/**
 * Retourne une liste de
 * @param {Dexie.Collection} horse_actes_articles
 */
export default class ActeArticleTransformer extends Transformer {

    table = 'horse_actes_articles'

	async fetch (col) {
        return col.with({
            'articles'                      : 'actesarticles_articles',
            'vat'                           : 'actesarticles_vat',
        })
    }

    async transform (actesAarticles) {
        return {
            'actesarticles_id'              : actesAarticles.actesarticles_id,
            'actesarticles_ht'              : actesAarticles.actesarticles_ht,
            'actesarticles_ttc'             : actesAarticles.actesarticles_ttc,
            'actesarticles_quantite'        : actesAarticles.actesarticles_quantite,
            'actesarticles_articles'        : actesAarticles.actesarticles_articles,
            'actesarticles_commentaire'     : actesAarticles.actesarticles_commentaire,
            'actesarticles_vat'             : actesAarticles.actesarticles_vat,
            'articles'                      : await ArticleTransformer.process(actesAarticles.articles),
            'vat'                           : await VatTransformer.process(actesAarticles.vat)
        }
    }

    fetchWithArticles (col) {
        return col.with({
            'actesarticles_articles': 'actesarticles_articles'
        })
    }

    transformWithArticles (actes_articles) {
        return actes_articles
    }
}
