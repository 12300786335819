import firebase from 'firebase/app'
import 'firebase/messaging'
import Store from '@/store'
import Constants from 'Constants'
import Config from "@/mixins/Config.js"
import Toasts from '@/mixins/Toasts.js'

const FIREBASE_CONFIG = {
    apiKey: "AIzaSyCMa5yFQZzB6-PVZEvJTMuRamhVQstYoRQ",
    authDomain: "groomy-fba49.firebaseapp.com",
    databaseURL: "https://groomy-fba49.firebaseio.com",
    projectId: "groomy-fba49",
    storageBucket: "groomy-fba49.appspot.com",
    messagingSenderId: "985682852650",
    appId: "1:985682852650:web:4dfca70e79c7ed5f497c53"
}

const MESSAGING_VAPID = "BAtCAaWkx33pTw9-JhDG4uqsZakCU4iiPbttVfy_DtsIr_CCiWYNFqSJWeS74wAP662EAVIfAnhuFr83evRTjog"

export default {
    install(Vue) {
		firebase.initializeApp(FIREBASE_CONFIG);

        const MessagingPlugin = {
            instance: null,
			token: null,

            // On page loading
            preinit() {
                if (firebase.messaging.isSupported()) {
                    this.instance = firebase.messaging()
                    this.instance.usePublicVapidKey(MESSAGING_VAPID)
                }

                this.token = this.getLocalToken()
            },

            init() {
                if (!this.instance) {
                    return
				}

				if (window.Notification.permission !== 'granted') {
					return
				}

                if (!this.token) {
                    this.instance.getToken()
                    .then((token) => {
                        this.onTokenChange(token)
                    })
                }

                this.instance.onTokenRefresh(() => {
                    this.instance.getToken()
                    .then((token) => {
                        this.onTokenChange(token)
                    })
				})
				
				// Si le compte logué a les accès dev, on traite la notif reçue
				this.instance.onMessage((message) => {
					if(Store.state.userAccess.hasDevAccess || Store.state.userAccess.hasAdminAccess) {
						console.debug("Firebase on message", message)
						const notification = message.notification
						Toasts.methods.notificationToast(notification.title, notification.body)
					}
				})
            },

            async reset() {
                if (this.token) {
                    await this.unsubscribeFromNotifications(this.token)
                    await this.setLocalToken(null)
                }
            },

            async onTokenChange(token) {
                if (this.token) {
                    await this.unsubscribeFromNotifications(this.token)
                }

                await this.subscribeToNotifications(token)
                await this.setLocalToken(token)
            },

            subscribeToNotifications(token) {
                const url = `${Constants.NOTIFICATIONS_SUBSCRIBE_API}/${token}?licence_key=${Constants.USER_LICENCE_KEY}`
                return Vue.prototype.$request.request_post_api(
                    'FirebaseMessaging::subscribeToNotifications',
                    url,
                    false
                )
            },

            unsubscribeFromNotifications(token) {
                const url = `${Constants.NOTIFICATIONS_UNSUBSCRIBE_API}/${token}?licence_key=${Constants.USER_LICENCE_KEY}`
                return Vue.prototype.$request.request_post_api(
                    'FirebaseMessaging::unsubscribeFromNotifications',
                    url,
                    false
                )
            },

            getLocalToken() {
                return Config.methods.getConfig('firebase:token')
            },

            async setLocalToken(token) {
                await Config.methods.setConfig('firebase:token', token)
                this.token = token
            }
        }

        MessagingPlugin.preinit()

        Vue.prototype.$firebase = {
            messaging: MessagingPlugin
        }
    }
}
