
import Vue from 'vue'
import Constants from 'Constants'
import Common from '@/assets/js/common.js'
import GroomyDB from '@/assets/js/dexie/GroomyDB'
import { ADDITIONAL_COLUMNS } from '@/assets/js/dexie/GroomyDB'
import Queue from '@/assets/js/utils/queue'
import OfflineUtils from '@/assets/js/utils/offline'
import { EventBus } from 'EventBus'

let instance = null

export class CacheManager {
	#db = null
	#queue = new Queue()
	#timerId = null
	#cancelFns = {}

	static inst() {
		if(!instance) {
			instance = new this
		}

		return instance
	}

	async init() {
		if(!this.#db) {
			this.#db = await GroomyDB.getInstance(false)
		}
	}

	async reset() {
		clearTimeout(this.#timerId)
		await this.#queue.flush()

		if (this.#db && await this.#db.isOpen()) {
			await this.#db.close()
		}

		this.#db = null

		instance = null
	}

	async onObjectsMutation(table_name, item_ids, columns) {
		await this.init()

		const acInfos = ADDITIONAL_COLUMNS[table_name]

		if(columns === undefined) {
			columns = acInfos.columns
		}

		const indexes = []
		item_ids.forEach(item_id => {
			columns.forEach(column => {

				let api=0

				if(acInfos.api!==undefined && acInfos.api.indexOf(column) >= 0){
					api=1
				}

				indexes.push({
					id: `${table_name}_${item_id}_${column}`,
					table_name: table_name,
					item_id,
					columns: [column],
					api: api,
					inserted: new Date().getTime()
				})
			})
		})

		const to_clean = {}
		indexes.forEach(index => {
			if(!to_clean[index.table_name]) {
				to_clean[index.table_name] = {}
			}

			if(!to_clean[index.table_name][index.item_id]) {
				to_clean[index.table_name][index.item_id] = {}
			}

			index.columns.forEach(col_name => {
				to_clean[index.table_name][index.item_id][col_name] = undefined
			})
		})

		// Problème lorsqu'on tente de vider le cache dans une transaction qui n'a pas la table _index_buffer
		// setTimeout permet de sortir du contexte de Dexie et la transaction parent n'est pas prise en compte
		return new Promise((resolve, reject) => {
			setTimeout(async () => {
				let promises = []

				// Supprimer les anciennes valeurs indexées
				Object.keys(to_clean).forEach(table_name => {
					Object.keys(to_clean[table_name]).forEach(item_id => {
						if(item_id !== 'null') {
							promises.push(
								this.#db.t(table_name).then(table => {
									return table.update(parseInt(item_id), to_clean[table_name][item_id])
								})
							)
						}
					})
				})

				promises.push(
					this.#db.t('_index_buffer')
					.then(table => {
						return table.bulkPut(indexes)
					})
				)

				return Promise.all(promises)
				.then(resolve)
				.catch(reject)
			})
		})
	}

	getLastSync(type) {
		return parseInt(window.localStorage.getItem(`sync:${Constants.USER_LICENCE_KEY}:last_${type}_sync`) || 0)
	}

	getSyncFromScratch() {
		const json = window.localStorage.getItem(`sync:${Constants.USER_LICENCE_KEY}:sync_from_scratch`)
		return json ? JSON.parse(json) : []
	}

	isSyncLock() {
		const lastSyncRequired=this.getLastSync('required')
		const lastSyncOptionnal=this.getLastSync('optionnal')

		//since = 0 
		if(lastSyncRequired <1000 || lastSyncOptionnal<1000){
			return true
		}

		//since = 1
		const syncFromScratch = this.getSyncFromScratch()
		if(syncFromScratch.length > 0){
			return true
		}

		return false
	}

	async cacheAdditionalColumns() {

		//check si l'on es dans une initialisation ou une update massive de table
		//Si c'est le cas on delaye l'indexation a 20s
		if(this.isSyncLock()) {
			//console.log('noIndex')
			EventBus.$emit('Indexation::stop')
			this.#timerId = setTimeout(() => {
				this.#queue.enqueue(() => {
					return this.cacheAdditionalColumns()
				})
			}, 20000)

			return
		}

		const index_buffer_table = await this.#db.t('_index_buffer')
		const index_buffer_length = await index_buffer_table.limit(5).toArray()
		const index_buffer_api_length = await index_buffer_table.where('api').equals(1).first()
		let index_api = window.navigator.onLine 

		let limit=100
		if(index_api) limit=2000

		let indexes

		if(!window.navigator.onLine){ // si offline
			indexes = await index_buffer_table.where('api').equals(0).limit(limit).toArray()
		}else if (index_buffer_api_length!== undefined) { //online api =1
			indexes = await index_buffer_table.orderBy('api').desc().limit(limit).toArray()
			index_api=true
		}else{//online sans api =1
			indexes = await index_buffer_table.limit(limit).toArray()
		}

		let obsoleteIndexIds = []

		// On ira revérifier dans 5 secondes si on a des nouveaux éléments à indexer

		if( indexes.length === 0 ) {
			EventBus.$emit('Indexation::stop')
			this.#timerId = setTimeout(() => {
				this.#queue.enqueue(() => {
					return this.cacheAdditionalColumns()
				})
			}, 5000)

			return
		}
		EventBus.$emit('Indexation::start')


		let formatted = {
			// nom_table: { id: [columns] }
		}

		// Formattage et regroupement des index pour favoriser la mise en cache des relations
		indexes.forEach(index => {
			if(!index.item_id || index.item_id === undefined || index.item_id === '') {
				obsoleteIndexIds.push(index.id)
				return
			}

			if(index.columns === undefined) {
				obsoleteIndexIds.push(index.id)
				return
			}

			if(!formatted[index.table_name]) {
				formatted[index.table_name] = {}
			}

			if(!formatted[index.table_name][index.item_id]) {
				formatted[index.table_name][index.item_id] = []
			}

			const acInfos = ADDITIONAL_COLUMNS[index.table_name]
			if(acInfos) {
				formatted[index.table_name][index.item_id] = formatted[index.table_name][index.item_id].concat(index.columns || acInfos.columns)
			}
		})

		//si formatted.length > MAX_LOCAL_INDEX ==> 500
		if(index_api){
			await this.cacheAdditionalColumnsApi(formatted)
			this.#queue.enqueue(() => {
				return this.cacheAdditionalColumns()
			})
			//return
			return
		}

		// Lancement de l'indexation des différentes tables sur plusieurs threads en meme temps
		// 1 transformer = 1 thread
		let promises = Object.keys(formatted).map(async tableName => {
			const acInfos = ADDITIONAL_COLUMNS[tableName]
			const transformerInfos = acInfos.transformer
			const transformer = await OfflineUtils.getTransformerInstance(transformerInfos[0], transformerInfos[1])
			await transformer.init()

			for(let itemId in formatted[tableName]) {
				// Prendre seulement les colonnes recquises sans doublons
				const columns = acInfos.columns.filter((col) => formatted[tableName][itemId].indexOf(col) !== -1)

				// Si les colonnes misent dans le buffer d'indexation ne sont plus indexées dans cette version
				if(columns.length === 0) {
					obsoleteIndexIds = obsoleteIndexIds.concat(
						formatted[tableName][itemId].map((colName) => (`${tableName}_${itemId}_${colName}`))
					)

					continue
				}

				// Lancer l'indexation de l'item
				await transformer.indexItemColumns(parseInt(itemId), columns)
			}
		})

		await Promise.all(promises).catch((err) => {
			console.error(err)
		})

		if(obsoleteIndexIds.length > 0) {
			await this.#db.t('_index_buffer').then(table => {
				return table.bulkDelete(obsoleteIndexIds)
			})
		}

		this.#queue.enqueue(() => {
			return this.cacheAdditionalColumns()
		})
	}

	async cacheAdditionalColumnsApi(formatted){
		const response = await this._getData({ indexCacheData : formatted})

		let deleteKeys = []
		Object.keys(response.retour).forEach(tableName => {
			const contentTable=response.retour[tableName]
			Object.keys(contentTable).forEach(tableId => {
				const contentTableId = contentTable[tableId]

				// Sauvegarder les colonnes indexées dans la table
				this.#db.t(tableName).then(table => {
					return table.update(parseInt(tableId), contentTableId)
				})

				// Supprimer les lignes du buffer qui ont été insérées avant la date d'indexation
				// pour réindexer les colonnes si elles ont changées depuis leur récupération
				Object.keys(contentTableId).forEach(colName => {
					return deleteKeys.push(`${tableName}_${tableId}_${colName}`)
				})
			})
		})

		this.#db.t('_index_buffer').then(indexBufferTable => {
			return indexBufferTable.bulkDelete(deleteKeys)
		})
	}

	_getData(data = []) {
		//const url = Constants.INDEX_DATA_API

		const url = `${Constants.INDEX_DATA_API}?licence_key=${Constants.USER_LICENCE_KEY}`

		return Vue.prototype.$request.request_post_api(
			"CacheManager::_getData",
			url,
			data,
			false
		)
	}
}

export default CacheManager
