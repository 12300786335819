import Transformer from './Transformer'
import ArticleTransformer from './ArticleTransformer'

/**
 * Retourne une liste de
 * @param {Dexie.Collection} horse_pension_article
 */
export default class HorsePensionArticleTransformer extends Transformer {

	async article(item) {
		return this.db().t('articles')
		.then(table => {
			return table.where({
				articles_id: parseInt(item.horsepensionarticle_article)
			})
		})
		.then(col => {
			return col.transform(new ArticleTransformer())
		})
	}

	fetch (col) {
		this.additionalColumns({
			'article': this.article
		})

		return col
	}

    async transform (horse_pension_article) {
        return {
			'horsepensionarticle_id': horse_pension_article.horsepensionarticle_id,
			'horsepensionarticle_article': horse_pension_article.article,
			'horsepensionarticle_ht': horse_pension_article.horsepensionarticle_ht,
			'horsepensionarticle_ttc': horse_pension_article.horsepensionarticle_ttc,
			'horsepensionarticle_facturationtype': horse_pension_article.horsepensionarticle_facturationtype
        }
    }
}