<template>
    <div :is="currentComponent" v-bind="props">
    </div>
</template>

<script>
import Tools from '@/mixins/Tools'
import Window from '@/mixins/Window'

export default {
    mixins: [Tools, Window],
    data: () => ({
        props: {},
        components: {
            xs: null,
            sm: null,
            md: null,
            lg: null,
            xl: null
        },
    }),
    computed: {
        responsiveComponents() {
            let cmps = this.deepClone(this.components)
            cmps.xs = cmps.xs || cmps.sm || cmps.md || cmps.lg || cmps.xl
            cmps.sm = cmps.sm || cmps.md || cmps.lg || cmps.xl
            cmps.md = cmps.md || cmps.lg || cmps.xl
            cmps.lg = cmps.lg || cmps.xl

            return cmps
        },
        currentComponent() {
            if (this.isXs) {
                return this.responsiveComponents.xs
            }
            else if (this.isSm) {
                return this.responsiveComponents.sm
            }
            else if (this.isMd) {
                return this.responsiveComponents.md
            }
            else if (this.isLg) {
                return this.responsiveComponents.lg
            }
            else {
                return this.responsiveComponents.xl
            }
        }
    },
    methods: {
        onWindowResize() {
            this.windowWidth = window.innerWidth
        }
    }
}
</script>
