import GroomyDB from '@/assets/js/dexie/GroomyDB'
import CollectionAddon from './collection'
import RelationshipsAddon from 'dexie-relationships'
import TransactionAddon from './transaction'
import TransformAddon from './transform'

GroomyDB.addons.push(CollectionAddon)
GroomyDB.addons.push(RelationshipsAddon)
GroomyDB.addons.push(TransactionAddon)
GroomyDB.addons.push(TransformAddon)
